import React from 'react'
import '../filters.css'
import { Grid, Chip, Checkbox } from "@material-ui/core";
import FormControlLabel from '@mui/material/FormControlLabel';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";


class Channels extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showChannels: false
        }

    }

    handleShowChannels = () => {
        this.setState({
            showChannels: !this.state.showChannels
        })
    }
    handleCheckBox = (prop1, prop2) => {
        this.props.dispatch({
            type: 'HANDLE_CHECKBOX',
            payload: {
                prop1,
                prop2
            }
        })
    }


    getSelectedChannels = () => {
        const channels = []
        Object.keys(this.props.channel).forEach((thisChannel) => {
            if (this.props.channel[thisChannel]) {
                channels.push(
                    <Chip size='small' label={thisChannel} variant="outlined" sx={{ border: '0.2px solid lightgray', borderRadius: '0px', marginRight: '2px' }} />
                )
            }
        })
        return channels
    }

    getChannels = () => {
        const channels = []
        // This needs to be removed later
        const allChannels = {...this.props.channel,'E-Comm':false}
        Object.keys(allChannels).forEach((thisChannel) => {
            channels.push(
                <FormControlLabel
                    control={
                        <Checkbox
                            disabled = {thisChannel === 'E-Comm'}
                            checked={this.props.channel[thisChannel]}
                            onChange={() => this.handleCheckBox('channel', thisChannel)}
                            style={{
                                color: (thisChannel === 'E-Comm'?'lightgray':"#368BC1")
                            }}
                            value={thisChannel}
                        />
                    }
                    label={thisChannel} />
            )
        })
        return (
            <div style={{
                'padding': '0px 10px', 'border': '0.5px solid lightgray', borderRadius: '5px', backgroundColor: 'white'
            }}>
                {channels}
            </div>
        )
    }
    render() {
        return (
            <React.Fragment>
                <Grid style={{ marginBottom: '10px', marginRight: '10px' }} item xs={5} md={5} lg={5}>
                    <div style={{ 'padding': '10px', 'border': '0.5px solid lightgray', borderRadius: '5px', backgroundColor: 'white' }}>
                        <span>
                            <span style={{ marginRight: '10px' }}>
                                Channel:
                            </span>
                            {this.getSelectedChannels()}
                        </span>
                        <ArrowRightIcon sx={{ float: 'right' }} onClick={() => this.handleShowChannels()} color='primary' />
                    </div>

                </Grid>
                <Grid style={{ marginBottom: '10px', marginRight: '10px', height: '100%' }} item xs={5} md={5} lg={5}>
                    <div >
                        {this.state.showChannels && this.getChannels()}

                    </div>
                </Grid>
            </React.Fragment>
        )
    }

}

const mapStateToProps = (store) => {
    const { mondaySmartReducer } = store
    return {
        channel: mondaySmartReducer.channel,
    };
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(
    mapStateToProps,
    null,
)(withRouter(Channels));