import React from "react";
import moment from "moment";
import "../filters.css";
import { Grid, Typography } from "@material-ui/core";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Chip from "@mui/material/Chip";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LoadingOverlay from "../../../../../commonComponents/loadingOverlay";
import {
  getComapredToYears,
  getLineComapredToYears,
  getYears,
  setFilterChanged
} from "../../../../../actions/mondaySmartActions";
import "./compared.css";

class Compared extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comparedToYear: moment().format("YYYY"),
      showCompareButtons: false,
      comparedTo: "LY",
      isApiLoading: false,
    };
  }

  async componentDidMount(){
    const years = await getYears()
    this.props.dispatch({
      type:'SET_YEARS',
      years:years.data.data.years
    })
  }

  handleSwitch = (prop) => {
    this.props.dispatch({
      type: "HANDLE_SWITCH",
      payload: {
        prop,
      },
    });
  };

  handleButtonClick = async (prop1, prop2) => {
    this.setState({
      isApiLoading: true,
    });
    let lastYear;
    if (prop2 === "LY") {
      lastYear = Number(this.props.endDateTimeline.split("-")[0]) - 1;
    } else if (prop2 === "LLY") {
      lastYear = Number(this.props.endDateTimeline.split("-")[0]) - 2;
    }
    let startFw = this.props.startFwWeekTimeline;
    let endFw = this.props.endFwWeekTimeline;
    if (prop2 === "LY" || prop2 === "LLY") {
      await this.props.getComapredToYears(lastYear, startFw, endFw);
    }
    this.props.dispatch({
      type: "HANDLE_BUTTON_CLICK",
      payload: {
        prop1,
        prop2,
      },
    });
    this.props.setFilterChanged(true)
    this.setState({
      isApiLoading: false,
    });
  };

  // handleChange = (prop,value)=>{
  //     this.setState({
  //         [prop]:value
  //     })
  // }

  handleChange = (prop1, prop2) => {
    let lastYear = prop2;
    let startFw = this.props.startFwWeekTimeline;
    let endFw = this.props.endFwWeekTimeline;
    this.props.getComapredToYears(lastYear, startFw, endFw);
    this.props.getLineComapredToYears(lastYear, null, null);
    this.props.setFilterChanged(true)
    this.props.dispatch({
      type: "HANDLE_BUTTON_CLICK",
      payload: {
        prop1,
        prop2,
      },
    });
  };

  getYearMenu = () => {
    const years = this.props.compare_years;
    const Years = [];
    for (let i = 0; i < years.length; i++) {
      Years.push(<MenuItem value={years[i]}>{years[i]}</MenuItem>);
    }
    return Years;
  };

  render() {
    var labels = {};
    if (this.props.page == "overView") {
      if (this.props.labels.overViewLabels) {
        labels = this.props.labels.overViewLabels;
      }
    }
    if (this.props.page == "deepDive") {
      if (this.props.labels.deepDiveLabels) {
        labels = this.props.labels.deepDiveLabels;
      }
    }
    const isLabels = Object.keys(labels).length;
    return (
      <div>
        <LoadingOverlay
          active={this.props.isApiLoading || this.state.isApiLoading}
        />
        <div className="compared-outer-container">
          <Typography>
            Compared To:{" "}
            <Chip
              size="small"
              label={this.props.comparedTo}
              variant="outlined"
              sx={{ border: "0.2px solid lightgray", borderRadius: "0px" }}
            />
          </Typography>
          <ModeEditIcon
            onClick={() => this.handleSwitch("showCompareButtons")}
            color="primary"
          />
        </div>
        <div id="border-gray"></div>
        {this.props.showCompareButtons && isLabels > 0 && (
          <div className="button-group-container">
            <ButtonGroup
              sx={{
                backgroundColor: "#e9eff7",
              }}
              variant="text"
              aria-label="outlined primary button group"
              size="small"
              fullWidth
              disableElevation
            >
              <Button
                sx={{
                  color: "black",
                  backgroundColor:
                    this.props.comparedTo == "LY" ? "lightgray" : "#e9eff7",
                }}
                onClick={() => this.handleButtonClick("comparedTo", "LY")}
              >
                {labels != undefined ? labels[0].compare_to[0].label : ""}
              </Button>
              <Button
                sx={{
                  color: "black",
                  backgroundColor:
                    this.props.comparedTo == "LLY" ? "lightgray" : "#e9eff7",
                }}
                onClick={() => this.handleButtonClick("comparedTo", "LLY")}
              >
                {labels != undefined ? labels[0].compare_to[1].label : ""}
              </Button>
              <Button sx={{ color: 'black' }} disabled onClick={() => this.handleButtonClick('comparedTo', 'Forecast')}>{'Forecast'}</Button>
              <Button sx={{ color: 'black' }} disabled onClick={() => this.handleButtonClick('comparedTo', 'Planned')}>{'Planned'}</Button>
              <ButtonGroup
                size="small"
                fullWidth
                sx={{ backgroundColor: "white" }}
              >
                <Button
                  sx={{
                    color: "black",
                    backgroundColor:
                      this.props.comparedTo == "Year" ? "lightgray" : "#e9eff7",
                  }}
                  onClick={() => this.handleButtonClick("comparedTo", "Year")}
                >
                  {" "}
                  Year
                </Button>
              </ButtonGroup>
            </ButtonGroup>
          </div>
        )}
        {this.props.comparedTo === "Year" && (
          <div
            style={{
              padding: "10px",
            }}
          >
            <Grid container>
              <Grid item sxs={7} m={7} lg={7}>
                <FormControl style={{ marginTop: "5px" }}>
                  <InputLabel id="year-id">Year</InputLabel>
                  <Select
                    onChange={(e) =>
                      this.handleChange("comparedToYear", e.target.value)
                    }
                    sx={{ width: 120, height: 35 }}
                    id="year-id"
                    value={this.props.comparedToYear}
                    label={"Year"}
                  >
                    {this.getYearMenu()}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    comparedToYear: store.mondaySmartReducer.comparedToYear,
    showCompareButtons: store.mondaySmartReducer.showCompareButtons,
    comparedTo: store.mondaySmartReducer.comparedTo,
    labels: store.mondaySmartReducer.labels,
    compare_years: store.mondaySmartReducer.compare_years,
    endDateTimeline: store.mondaySmartReducer.endDateTimeline,
    startFwWeekTimeline: store.mondaySmartReducer.startFwWeekTimeline,
    endFwWeekTimeline: store.mondaySmartReducer.endFwWeekTimeline,
    filterChanged: store.mondaySmartReducer.filterChanged,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  setFilterChanged: (value) => dispatch(setFilterChanged(value)),
  getComapredToYears: (year, startfw, endfw) =>
    dispatch(getComapredToYears(year, startfw, endfw)),
  getLineComapredToYears: (year, sw, ew) =>
    dispatch(getLineComapredToYears(year, sw, ew)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Compared));
