import { SET_TENANT_APPS, SET_APP_SETTINGS } from '../actions/types';

const initialState = {
  tenantApps: [],
  appSettings: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_TENANT_APPS:
      return {
        ...state,
        tenantApps: action.payload,
      };
    case SET_APP_SETTINGS:
      return {
        ...state,
        appSettings: { ...action.payload },
      };
    default:
      return state;
  }
}
