// Dashboard API URLs
export const GET_PLAN_DETAILS = "/assort/plan";
export const GET_CREATED_PLANS = "/assort/plan/search";
export const DELETE_PLAN = "/assort/plan/delete";
export const CREATE_PLAN = "/assort/plan";
export const GET_PLAN_LEVELS = "/assort/plan/level";
//1-2 API URLs
export const GET_CLUSTER_BUCKET_INFO = "assort/cluster/bucket/info";
export const GET_ATTRIBUTE_GRAPH_DATA = "assort/cluster/attributes/info";
export const GET_PERFORMANCE_GRAPH_DATA = "assort/cluster/performance/info";
export const GET_CLUSTER_BREAKDOWN_DATA= '/assort/cluster/grade-breakdown'
//2-1 API URLs
export const GET_L3_OPT = "assort/optimization/get-l3-optimization"
export const UPDATE_L3_OPT = "assort/optimization/update-l3-optimization"
