import {
  SET_PRODUCT_PO_MAPPING,
  SET_PRODUCT_ASN_MAPPING,
  SET_PRODUCT_DC_MAPPING,
  SET_PRODUCT_FC_MAPPING,
  SET_ALL_DCS,
  SET_ALL_FCS,
} from "../actions/types";

const initialState = {
  productPOMapping: [],
  productASNMapping: [],
  productDCMapping: [],
  productFCMapping: [],
  allDCs: [],
  allFCs: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PRODUCT_PO_MAPPING:
      return {
        ...state,
        productPOMapping: action.payload,
      };
    case SET_PRODUCT_ASN_MAPPING:
      return {
        ...state,
        productASNMapping: action.payload,
      };
    case SET_PRODUCT_DC_MAPPING:
      return {
        ...state,
        productDCMapping: action.payload,
      };
      case SET_PRODUCT_FC_MAPPING: 
      return {
        ...state, 
        productFCMapping: action.payload
      }
    case SET_ALL_DCS:
      return {
        ...state,
        allDCs: action.payload,
      };
    case SET_ALL_FCS:
      return {
        ...state,
        allFCs: action.payload,
      };
    default:
      return state;
  }
}
