import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import './style.scss';

const Modal = ({
  children,
  showClose = true,
  onClose,
  backdropClose = false,
}) => {
  const mount = document.getElementById('modal-root');
  const el = document.createElement('div');

  useEffect(() => {
    mount.appendChild(el);
    return () => mount.removeChild(el);
  }, [el, mount]);

  return createPortal(
    <div
      className="modal-container"
      onClick={backdropClose ? onClose : () => null}
    >
      <aside
        tag="aside"
        role="dialog"
        tabIndex="-1"
        aria-modal="true"
        className="modal"
        onClick={(e) => e.stopPropagation()}
      >
        {showClose && (
          <button
            id="btnModalClose"
            aria-label="Close Modal"
            aria-labelledby="close-modal"
            className="_modal-close"
            onClick={onClose}
          >
            <svg className="_modal-close-icon" viewBox="0 0 40 40">
              <path d="M 10,10 L 30,30 M 30,10 L 10,30" />
            </svg>
          </button>
        )}
        {children}
      </aside>
    </div>,
    el
  );
};

export default Modal;
