import React, { useEffect } from "react";
import { getTenantId, verifyTokenStatus } from "../actions/authActions";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import firebaseobj from "../commonComponents/auth/firebase";
const MetaDataComponent = (props) => {
  const { search } = useLocation();
  const testSearch = React.useMemo(() => new URLSearchParams(search), [search]);
  const checkUsrSession = () => {
    props.checkUserSession();
  };
  const fetchDomainDetails = () => {
    let loc = new URL(window.location.origin);
    props.fetchTenantInfo(loc.hostname);
  };

  useEffect(() => {
    const mobile = testSearch.get("mobile");
    if (mobile !== "true") {
      if (props.isTenantInfoFetched) {
        firebaseobj.auth().tenantId =
          props.tenantDetails["google_tenant_identity"];
        checkUsrSession();
      }
      // firebaseobj.auth().tenantId = "generic-dev-wfy2v"; //Temporary hardcoding
      firebaseobj.auth().tenantId = process.env.REACT_APP_TENANT_ID; //Temporary hardcoding
      
      checkUsrSession();
    }
  }, [props.isTenantInfoFetched]);
  return <>{props.children}</>;
};

const mapStateToProps = (state) => ({
  isTenantInfoFetched: state.authReducer.isTenantInfoFetched,
  tenantDetails: state.authReducer.tenantId,
  isMobile: state.authReducer.isMobile
});
const mapActionToProps = {
  fetchTenantInfo: getTenantId,
  checkUserSession: verifyTokenStatus,
};

export default connect(mapStateToProps, mapActionToProps)(MetaDataComponent);
