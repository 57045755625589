import { CHANGE_FILTER_DATA,SET_SELECTED_FILTERS } from '../actions/types';

export  const initialState = {
  filterInitialData:[],
  selectedFilters:{}
};

export default  function  (state = initialState, action) {
  switch (action.type) {
    case CHANGE_FILTER_DATA:
      return {
        ...state,
        filterInitialData:action.payload
      };
      case SET_SELECTED_FILTERS:
        return {
          ...state,
          selectedFilters:{...state.selectedFilters,...action.payload}
        };
    default:
      return state;
  }
}

