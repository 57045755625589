import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from 'react-redux'

const MobileRoute = ({history}) => {
    const dispatch = useDispatch()
    const { search } = useLocation();
    const getSearch = useMemo(() => new URLSearchParams(search), [search]);

    async function fetchMyAPI(auth) {
        const landing_page = "store-smart/dashboard";
        localStorage.setItem('token', auth.replace(/['"]+/g, ''))
        localStorage.setItem('name', '')
        dispatch({
            type: 'SET_CURRENT_USER',
            payload: {
                isAuthenticated: true,
                isTokenVerified: true,
                isMobile: true,
                user: {
                    token: localStorage.getItem("token"),
                    name: localStorage.getItem("name"),
                },
                landingPage: landing_page
            },
        })
        history.push(landing_page)
    }

    useEffect(() => {
        const auth = getSearch.get("auth");
        const mobile = getSearch.get("mobile");
        if (getSearch && mobile === "true") {
            fetchMyAPI(auth)
        }

    }, [getSearch]);

    return true;

}

export default MobileRoute;
