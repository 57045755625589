import { BASE_API } from "./api";

export const config = {
  baseUrl: BASE_API,
};

export const calendarConfig = {
  fstDayOfWk: 0, // Sunday
  fstMoOfYr: 0, // Jan
};
