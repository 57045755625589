import {
    SET_DC_STATUS,
} from "../actions/types";


const initialState = {
    dcStatus: [],
    dcStatusCount: 0,
};


export default function (state = initialState, action) {
    switch (action.type) {
        case SET_DC_STATUS:
          return {
            ...state,
            dcStatus: [...action.payload],
            dcStatusCount: action.count,
          };

          default:
            return state;
        }
      }