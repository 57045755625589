import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../../../Utils/axios";
import { GET_L3_OPT, UPDATE_L3_OPT } from "../../../constants-assortsmart/apiConstants";

export const planInitialService = createSlice({
  name: "planInitialService",
  initialState: {
    l3OptData: [],
    loader_2_1: false,
  },
  reducers: {
    setL3OptData: (state, action) => {
      state.l3OptData = action.payload;
    },
    set2_1_Loader: (state, action) => {
      state.loader_2_1 = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setL3OptData, set2_1_Loader } = planInitialService.actions;
export const getL3OptData =  (postBody) => (dispatch) => {
    return  axiosInstance({
      url: GET_L3_OPT,
      method: "POST",
      data: postBody,
    })}
export const updateL3OptData = (postBody) => (dispatch) => {
  return axiosInstance({
    url: UPDATE_L3_OPT,
    method: "PUT",
    data: postBody
  })
}

export default planInitialService.reducer;
