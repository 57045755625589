import { makeStyles } from "@material-ui/styles";
import { SnackbarProvider } from "notistack";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
const useStyles = makeStyles({
  error: {
    backgroundColor: "#F34636",
  },
  warning: {
    backgroundColor: "#F9982D",
  },
  info: {
    backgroundColor: "#2296F3",
  },
  success: {
    backgroundColor: "#52AF50",
  },
});
const SnackbarProviderComponent = (props) => {
  const classes = useStyles();
  return (
    <SnackbarProvider
      maxSnack={3}
      classes={{
        variantError: classes.error,
        variantInfo: classes.info,
        variantSuccess: classes.success,
        variantWarning: classes.warning,
      }}
      iconVariant={{
        error: <ErrorOutlineIcon style={{ marginRight: 5 }} />,
        info: <InfoOutlinedIcon style={{ marginRight: 5 }} />,
        warning: <ReportProblemOutlinedIcon style={{ marginRight: 5 }} />,
        success: <CheckCircleOutlineOutlinedIcon style={{ marginRight: 5 }} />,
      }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      {props.children}
    </SnackbarProvider>
  );
};
export default SnackbarProviderComponent;
