import { useEffect, useState } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import { makeStyles } from "@material-ui/core";
import { NUMBER_OF_EXTRA_YEARS_IN_DATE_PICKER } from "config/constants";
const useStyles = makeStyles({
  monthYearDropDown: {
    display: "inline-flex",
  },
});
const DateRangePickerComponent = (props) => {
  const classes = useStyles();
  const [focusedInput, setfocusedInput] = useState(null);
  const [yearArray, setyearArray] = useState([moment().year()]);
  const configureYear = () => {
    let yearArr = [moment().year()];
    for (let i = 0; i < NUMBER_OF_EXTRA_YEARS_IN_DATE_PICKER; i++) {
      if (props.disableType && props.disableType.includes("Future")) {
        yearArr.push(yearArr[i] - 1);
      } else {
        yearArr.push(yearArr[i] + 1);
      }
    }
    setyearArray(yearArr);
  };
  const isOutsideRange = (day) => {
    switch (props.disableType) {
      case "disableOnlyPast":
        //disables strictly past i.e current/today's date is not included
        return moment(day).isBefore();
      case "disablePast":
        //disables past i.e current/today's date is included
        return moment(day).isSame(moment(), "days") || moment(day).isBefore();
      case "disableOnlyFuture":
        //disables strictly future i.e current/today's date is not included
        return moment(day).isAfter();
      case "disableFuture":
        //disables future i.e current/today's date is included
        return moment(day).isSame(moment(), "days") || moment(day).isAfter();
      case "customRange":
        //we can pass custom range which evaluates if the date to be disabled or not
        //For example: disabling specific dates or only fridays etc..
        return props.customOutsideRange();
      default:
        //By default, it doesn't disable any date
        return false;
    }
  };
  const dateRangeCalculator = (startDate, endDate) => {
    let weekStart = moment(startDate).startOf("week");
    let weekEnd = moment(endDate).endOf("week");
    if (isOutsideRange(weekEnd)) {
      weekEnd = moment().subtract(1, "days");
    }
    if (!weekStart.isValid()) {
      weekStart = null;
    }
    if (!weekEnd.isValid()) {
      weekEnd = null;
    }
    props.onDatesChange(weekStart, weekEnd);
  };

  useEffect(() => {
    configureYear();
  }, []);
  return (
    <DateRangePicker
      showClearDates
      startDateId="startDate"
      endDateId="endDate"
      startDate={props.startDate}
      endDate={props.endDate}
      withPortal={!props.noPortal}
      small
      autoFocus
      preventScroll
      onDatesChange={({ startDate, endDate }) => {
        dateRangeCalculator(startDate, endDate);
      }}
      focusedInput={focusedInput}
      onFocusChange={(focusedInp) => {
        setfocusedInput(focusedInp);
      }}
      disabled={props.disabled}
      isOutsideRange={isOutsideRange}
      showDefaultInputIcon
      inputIconPosition="after"
      hideKeyboardShortcutsPanel
      renderMonthElement={function noRefCheck({
        month,
        onMonthSelect,
        onYearSelect,
      }) {
        return (
          <div className={classes.monthYearDropDown}>
            {yearArray.length ? (
              <>
                <div>
                  <select
                    value={month.month()}
                    onChange={(e) => onMonthSelect(month, e.target.value)}
                  >
                    {moment.months().map((label, value) => (
                      <option value={value}>{label}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <select
                    value={month.year()}
                    onChange={(e) => onYearSelect(month, e.target.value)}
                  >
                    {yearArray.map((item) => (
                      <option value={item}>{item}</option>
                    ))}
                  </select>
                </div>
              </>
            ) : (
              <div />
            )}
          </div>
        );
      }}
    />
  );
};

export default DateRangePickerComponent;
