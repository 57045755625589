import {
  SET_STYLE_TABLE_DATA,
  SET_STYLE_TABLE_COLS,
  UNIT_DEFN_LOADER,
  SET_SELECTED_STYLE_ID,
  SET_UNIT_DEFINITION_TYPE,
  SET_UNIT_DEFINITION_NAME,
  SET_UNIT_ATTRIBUTE_TYPE,
  SET_STYLE_ATTRIBUTES,
  SET_COPY_STYLE_ID,
  SET_PUD_AVAILABLE_DEFINITIONS,
  SET_SELECTED_AVAILABLE_DEFINITION,
  SET_PACK_QUANTITY,
  SET_PACKS_IN_CARTONS,
  SET_CARTONS_IN_BOXES,
  SET_CARTONS_IN_BOXES_DISABLED,
  SET_PACKS_IN_CARTONS_DISABLED,
  SET_PACKS_DISABLED,
  SET_ATTRIBUTE_DATA,
  SET_SHOW_STYLES_TABLE,
  RESET_UNIT_DEFN_DATA,
  SET_AVAILABLE_STYLES,
  LAST_EDITED_DETAILS,
  SET_UNIT_DEFN_FILTER_VALUES,
  SET_STYLES_PAGE_STATUS,
  CHANGE_DATA_STATUS,
  UNIT_NAME_VALIDITY_STATUS,
  SET_EDIT_NAME,
} from "../actions/types";

const initialState = {
  stylesData: [],
  stylesDataCount: 0,
  stylesTableCols: [],
  isLoading: false,
  selectedStyleId: "",
  unitDefnType: "",
  unitDefnName: "",
  attributeType: "single size - all colors",
  attributes_sizes: [],
  attributes_colors: [],
  copyStyleId: "",
  availableDefinitions: [],
  selectedAvailableDefn: "",
  packQuantity: 0,
  packsInCartons: 0,
  cartonsInBoxes: 0,
  isPacksDisabled: true,
  isPacksInCartonsDisabled: true,
  isCartonsInBoxesDisabled: true,
  attributeData: [],
  metrics: [],
  showStylesTable: false,
  availableStyles: [],
  lastEditedData: [],
  lastEditedType: "",
  filtersData: [],
  currentPageIndex: 0,
  currentPageSize: 10,
  dataChanged: true,
  dataType: "firstrender",
  isMetricCopied: false,
  isNameValid: false,
  editName: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_STYLE_TABLE_DATA:
      return {
        ...state,
        stylesData: action.payload,
        stylesDataCount: action.count,
      };
    case SET_STYLE_TABLE_COLS:
      return {
        ...state,
        stylesTableCols: action.cols,
      };
    case UNIT_DEFN_LOADER:
      return {
        ...state,
        isLoading: action.status,
      };
    case SET_SELECTED_STYLE_ID:
      return {
        ...state,
        selectedStyleId: action.payload,
      };
    case SET_UNIT_DEFINITION_TYPE:
      return {
        ...state,
        unitDefnType: action.payload,
      };
    case SET_UNIT_DEFINITION_NAME:
      return {
        ...state,
        unitDefnName: action.payload,
      };
    case SET_UNIT_ATTRIBUTE_TYPE:
      return {
        ...state,
        attributeType: action.payload,
      };
    case SET_STYLE_ATTRIBUTES:
      return {
        ...state,
        attributes_sizes: action.payload.sizes,
        attributes_colors: action.payload.colors,
        metrics: action.payload.metrics,
      };
    case SET_COPY_STYLE_ID:
      return {
        ...state,
        copyStyleId: action.payload,
      };
    case SET_PUD_AVAILABLE_DEFINITIONS:
      return {
        ...state,
        availableDefinitions: action.payload,
      };
    case SET_SELECTED_AVAILABLE_DEFINITION:
      return {
        ...state,
        selectedAvailableDefn: action.payload,
      };
    case SET_PACK_QUANTITY:
      return {
        ...state,
        packQuantity: action.payload,
      };
    case SET_PACKS_IN_CARTONS:
      return {
        ...state,
        packsInCartons: action.payload,
      };
    case SET_CARTONS_IN_BOXES:
      return {
        ...state,
        cartonsInBoxes: action.payload,
      };
    case SET_PACKS_DISABLED:
      return {
        ...state,
        isPacksDisabled: action.status,
      };
    case SET_PACKS_IN_CARTONS_DISABLED:
      return {
        ...state,
        isPacksInCartonsDisabled: action.status,
      };
    case SET_CARTONS_IN_BOXES_DISABLED:
      return {
        ...state,
        isCartonsInBoxesDisabled: action.status,
      };
    case SET_ATTRIBUTE_DATA:
      return {
        ...state,
        attributeData: [...action.payload],
      };
    case SET_SHOW_STYLES_TABLE:
      return {
        ...state,
        showStylesTable: action.status,
      };
    case SET_AVAILABLE_STYLES:
      return {
        ...state,
        availableStyles: action.payload,
      };

    case LAST_EDITED_DETAILS:
      return {
        ...state,
        lastEditedData: action.data,
        lastEditedType: action.attribtype,
        isMetricCopied: action.copyStatus,
      };
    case SET_UNIT_DEFN_FILTER_VALUES:
      return {
        ...state,
        filtersData: action.payload,
      };
    case SET_STYLES_PAGE_STATUS:
      return {
        ...state,
        currentPageIndex: action.pageIndex,
        currentPageSize: action.pageSize,
      };
    case CHANGE_DATA_STATUS:
      return {
        ...state,
        dataChanged: action.status,
        dataType: action.dataType,
      };
    case UNIT_NAME_VALIDITY_STATUS:
      return {
        ...state,
        isNameValid: action.status,
      };
    case SET_EDIT_NAME:
      return {
        ...state,
        editName: action.name,
      };
    case RESET_UNIT_DEFN_DATA:
      return {
        ...state,
        unitDefnName: "",
        unitDefnType: "",
        selectedStyleId: "",
        packQuantity: 0,
        packsInCartons: 0,
        cartonsInBoxes: 0,
        isPacksDisabled: true,
        isPacksInCartonsDisabled: true,
        isCartonsInBoxesDisabled: true,
        attributeType: "single size - all colors",
        copyStyleId: "",
        availableDefinitions: [],
        selectedAvailableDefn: "",
        lastEditedData: [],
        lastEditedType: "",
        isMetricCopied: false,
        isNameValid: false,
        editName: "",
      };
    default:
      return state;
  }
}
