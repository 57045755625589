import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  loginUser,
  forgotPassword,
  setUser,
  checkUserProvider,
} from "../../../actions/authActions";
import "../index.scss";
import backgroundImage from "../../../assets/Login-Image.png";
import logo from "../../../assets/header_logo.png";
import { firebaseobj } from "../firebase";
import { bindActionCreators } from "redux";
import LoadingOverlay from "../../../Utils/Loader/loader";
import * as firebaseui from "firebaseui";
import { VALID_EMAIL } from "../regex";
import { Grid, Box, OutlinedInput, InputAdornment, IconButton, Link } from "@material-ui/core";
import { Divider, Typography, ThemeProvider, createTheme } from "@mui/material";
import LoginForm from "./login";
import FormControl, { useFormControl } from '@mui/material/FormControl';
import FirebaseLogin from "./firebaseUI";
import Copyright from "./copyright";
import DomainComponent from "./domain";
import Button from "@material-ui/core/Button";
import MarketingBanner from "./marketingBanner";
import mobile_logo from "../../../assets/IA_Full_Logo.png";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';


class Login extends Component {
  constructor(props) {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {},
      isEmailUser: false,
      uiconfigs: [],
      showPassword: false,
      FirebaseUIConfig: {
        signInOptions: [],
        credentialHelper: "none",
        signInFlow: "popup",
        callbacks: {
          signInSuccessWithAuthResult: (authResult, redirectUrl) => {
            return false;
          },
          signInFailure: (error) => { },
        },
      },
      inputError: {},
    };
    this.theme = createTheme({
      typography: {
        fontFamily: [
          'Poppins'
        ].join(','),
        h1: {
          fontWeight: 600,
          fontSize: "1.75rem",
          lineHeight: 2.625,
          letterSpacing: 0,
        },
        h2: {
          fontWeight: 600,
          fontSize: "1.5rem",
          lineHeight: 2.25,
          letterSpacing: 0,
        },
        h3: {
          fontWeight: 600,
          fontSize: "1.25rem",
          lineHeight: 1.875,
          letterSpacing: 0,
        },
        h4: {
          fontWeight: 500,
          fontSize: "1rem",
          lineHeight: 1.5,
          letterSpacing: 0,
        },
        h5: {
          fontWeight: 500,
          fontSize: "0.937rem",
          lineHeight: 1.4375,
          letterSpacing: 0,
        },
        h6: {
          fontWeight: 500,
          fontSize: "0.875rem",
          lineHeight: 1.3125,
          letterSpacing: 0,
        }
      }
    });
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    // let loc = new URL(window.location.origin);
    //To configure Tenant UI info. Currently For POC, all the three providers have been included
    //Here make an api call to get the tenantID
    //TO do API call using axios and get the sign in options
    //If there is no tenant ID coming from the backend, we won't display any signin options to the user
    // if (!this.props.authReducer.isAuthenticated && this.props.tenantId) {
    if (!this.props.authReducer.isAuthenticated) {
      this.setFirebaseUI(this.props.tenantId);
    }
    this.unregisterAuthObserver = firebaseobj
      .auth()
      .onAuthStateChanged((user) => {
        if (
          user &&
          this.props.authReducer.isTokenVerified &&
          this.props.authReducer.isAuthenticated
        ) {
          this.props.history.push(this.props.landingPage);
        } else {
          if (
            this.state.uiconfigs.length > 0 &&
            (this.state.uiconfigs[0] || this.state.uiconfigs[1])
          ) {
            let firebaseUIInstance = firebaseui.auth.AuthUI.getInstance();
            if (!firebaseUIInstance) {
              firebaseUIInstance = new firebaseui.auth.AuthUI(
                firebaseobj.auth()
              );
            } else {
              firebaseUIInstance.reset();
            }
            firebaseUIInstance.start(
              "#firebaseui_container",
              this.state.FirebaseUIConfig
            );
          }
        }
      });
  }

  isMobile = () => {
    return window.screen.availWidth < 950
}

  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  componentWillUnmount() {
    //Unregistering the auth oberserver that is called inside component did mount
    //This will clean the memory for the observer
    this.unregisterAuthObserver();
  }

  setFirebaseUI(tenantId) {
    let signInBooleanArray = [true, false, true]; //This enables the respective sign in option => 1. Google 2. SAML 3. Email/Password
    // let signInOptions = tenantId.sign_in_options;
    // for (let i = 0; i < signInOptions.length; i++) {
    //   signInBooleanArray[signInOptions[i] - 1] = true;
    // }
    let FirebaseUIobj = this.state.FirebaseUIConfig
    if (signInBooleanArray[0]) {
      FirebaseUIobj["signInOptions"].push({
        provider: firebaseobj.auth.GoogleAuthProvider.PROVIDER_ID,
      });
    }

    if (signInBooleanArray[1]) {
      FirebaseUIobj["signInOptions"].push({
        provider: tenantId.tenant_name,
        providerName: "Microsoft SSO",
        buttonColor: "#fff",
        iconUrl:
          "https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/microsoft.svg",
      });
    }
    this.setState({
      uiconfigs: signInBooleanArray,
      FirebaseUIConfig: FirebaseUIobj,
    });
  }

  onChange(e) {
    if (e.target.name == "email") {
      this.setState({ isEmailUser: false });
    }
    this.setState({ [e.target.name]: e.target.value });
  }

  ResetPassword = async () => {
    await this.checkUserProvider();
    if (this.verifyUserData() && this.state.isEmailUser) {
      this.props.forgotPassword(this.state.email);
      this.clearError();
    } else {
      this.setState({ inputError: { email: "Please input a valid email" } });
    }
  };

  componentDidUpdate() {
    if (
      this.props.authReducer.isTokenVerified &&
      this.props.authReducer.isAuthenticated
    ) {
      this.props.history.push(this.props.landingPage);
    }
    if (
      !this.props.authReducer.isAuthenticated &&
      // this.props.authReducer.tenantId &&
      !this.state.FirebaseUIConfig
    ) {
      this.setFirebaseUI(this.props.tenantId);
    }
    if (this.props.errors) {
      this.setState({ errors: this.props.errors });
    }
  }

  verifyUserData = () => {
    let error = {};
    if (!this.state.email || !VALID_EMAIL.test(this.state.email)) {
      error.email = "Please input valid email id";
    }
    if (!this.state.password) {
      error.password = "Please enter password";
    }
    if (Object.keys(error).length) {
      this.setState({ inputError: error });
      return false;
    } else {
      return true;
    }
  };
  clearError = () => {
    if (Object.keys(this.state.inputError).length) {
      this.setState({ inputError: {} });
    }
  };
  onSubmit = (e) => {
    e.preventDefault();
    if (this.verifyUserData()) {
      this.clearError();
      const userData = {
        email: this.state.email,
        password: this.state.password,
      };
      this.props.loginUser(userData);
    }
  };

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.onSubmit(event)
    }
  }

  checkUserProvider = async (e) => {
    if (VALID_EMAIL.test(this.state.email)) {
      try {
        const providers = await this.props.checkUserProvider(this.state.email);
        if (providers[0] == "password") {
          this.setState({ isEmailUser: true });
        } else {
          this.setState({ isEmailUser: false });
        }
      } catch (error) {
        this.setState({ isEmailUser: false });
      }
    }
  };
  render() {
    var currentTime = new Date();
    var year = currentTime.getFullYear();
    return (
      <>
        <LoadingOverlay
          loader={this.props.overlayLoaderState}
          text={this.props.loaderText}
          spinner
        >
          <ThemeProvider theme={this.theme}>
            <Grid container className="login-desktop">
              <Grid item xs={12}>
                <div className="signin-form__header">
                  <img src={logo} alt="logo" className="header__logo" />
                  <Typography
                    component="h1"
                    variant="h2"
                    className="brand__title"
                  >
                    StoreSmart
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div id="signInForm" className="h-md-100 signin-form-container">
                  <div className="signin-text-div"></div>
                  <div className="login-options">
                    <div className="login__wrapper">
                      <Typography
                        id="signInText"
                        className="sign-in-text"
                        variant="h2"
                      >
                        Sign in to your account.
                      </Typography>
                      <LoginForm
                        email={this.state.email}
                        password={this.state.password}
                        onChange={this.onChange}
                        ResetPassword={this.ResetPassword}
                        onSubmit={this.onSubmit}
                        error={this.state.inputError}
                      />
                      <>
                        <Divider variant="middle">or continue with</Divider>
                        <Box>
                        { !this.isMobile() && <div
                            id="firebaseSignInOptionsDiv"
                            className={
                              this.state.uiconfigs[2]
                                ? "firebase-sign-in"
                                : "firebase-without-emailLogin"
                            }
                          >
                            <FirebaseLogin
                              FirebaseUIConfig={this.state.FirebaseUIConfig}
                              firebaseobj={firebaseobj}
                            />
                          </div>}
                        </Box>
                      </>
                      {/* <Typography
                        variant="body"
                        component="p"
                        className="contact"
                        textAlign="center"
                      >
                        Need any help?{" "}
                        <Link href={this.props.helpDeskUrl} underline="hover">
                          {"Contact now"}
                        </Link>
                      </Typography> */}
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <MarketingBanner />
              </Grid>
            </Grid>
          </ThemeProvider>
          <Grid container className="login-mobile">

            <Grid item xs={12}>
              <Box
                sx={{
                  width: "100%",
                  height: "13%",
                  backgroundColor: '#033162',
                }}
              >
                <Grid container>
                  <Grid className="logo_grid" xs={6}><img alt="mobile_logo" src={mobile_logo} /></Grid>
                  <Grid className="title_grid" xs={6}>StoreSmart</Grid>
                </Grid>
              </Box>
              <Grid container>
                <Grid className="login-title-grid" xs={12}>Sign in to your account</Grid>
                <form style={{ width: "100%" }}>
                  <Grid className="login-form-grid" xs={11}>
                    <p>Email</p>
                    <Box component="form" noValidate autoComplete="off">
                      <FormControl sx={{ width: '100%', height: '41px' }}>
                        <OutlinedInput
                          onKeyDown={(e) => this.handleKeyPress(e)}
                          placeholder="Enter your email"
                          name="email"
                          type="email"
                          id="loginInputEmail"
                          className={
                            this.state.inputError.email && this.state.inputError.email.length
                              ? "error-border"
                              : ""
                          }
                          aria-describedby="emailHelp"
                          required=""
                          value={this.state.email}
                          onChange={this.onChange}
                        />
                        <p className="error-text">{this.state.inputError.email}</p>
                      </FormControl>
                    </Box>
                    <br />
                    <p>Password</p>
                    <Box component="form" noValidate autoComplete="off">
                      <FormControl sx={{ width: '100%', height: '41px' }}>
                        <OutlinedInput placeholder="Enter your password"
                          onKeyDown={(e) => this.handleKeyPress(e)}
                          variant="outlined"
                          name="password"
                          type={this.state.showPassword ? 'text' : 'password'}
                          id="loginPassword"
                          className={
                            this.state.inputError.password && this.state.inputError.password.length
                              ? "error-border"
                              : ""
                          }
                          required=""
                          value={this.state.password}
                          onChange={this.onChange}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                edge="end"
                                onClick={this.handleClickShowPassword}
                                onMouseDown={this.handleMouseDownPassword}
                              >
                                {this.state.showPassword ? <VisibilityOffOutlinedIcon sx={{ fontSize: "1.2rem" }} /> : <VisibilityOutlinedIcon sx={{ fontSize: "1.2rem" }} />}

                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        <p className="error-text">{this.state.inputError.password}</p>
                      </FormControl>
                    </Box>
                    {/* <p className="forgot-password-grid">
                      <Link href="#">Forgot Password?</Link>
                    </p> */}

                    <Button className="login-button" id="btnLogin" variant="contained" onClick={(e) => this.onSubmit(e)}>Sign in</Button>

                    <p className="continue-lable">- or continue with -</p>
                  </Grid>
                </form>

                {this.isMobile() && <div
                  id="firebaseSignInOptionsDiv"
                  className={
                    this.state.uiconfigs[2]
                      ? "firebase-sign-in"
                      : "firebase-without-emailLogin"
                  }
                >
                  <FirebaseLogin
                    FirebaseUIConfig={this.state.FirebaseUIConfig}
                    firebaseobj={firebaseobj}
                  />
                </div>}
                {/* <p className="need-help-lable">Need any help? <Link>Contact now</Link></p> */}

              </Grid>

            </Grid>
          </Grid>
        </LoadingOverlay>
      </>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  // errors: PropTypes.object.isRequired,
  // success: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  isTenantInfoFetched: state.authReducer.isTenantInfoFetched,
  isUsrSessionChecked: state.authReducer.isUsrSessionChecked,
  overlayLoaderState: state.loaderReducer.overlayLoaderState,
  loaderText: state.loaderReducer.loaderText,
  errors: state.errors,
  showAlert: state.authReducer.showAlert,
  authErrMsgProperties: state.authReducer.alertProperties,
  // success: state.success,
  landingPage: state.authReducer.landingPage,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { loginUser, setUser, forgotPassword, checkUserProvider },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
