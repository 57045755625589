import { combineReducers } from "redux";
import authReducer from "./authReducer";
import loaderReducer from "./loaderReducer";
import layoutReducer from "./layoutReducer";
import adminReducer from "./adminReducer";
import filterReducer from "./filterReducer";
import tenantReducer from "./tenantReducer";
import notificationReducer from "./notificationReducer";
import workflowReducer from "./workflowReducer";
import snackbarReducer from "./snackbarReducer";
import ticketingReducer from "./ticketingReducer";
import filterElementsReducer from "./filterElementsReducer";
import productMappingReducer from "./productMappingReducer";
import UnitDefnReducer from "./unitDefnReducer";
import productGroupReducer from "./productGroupReducer";
import productStatusReducer from "./productStatusReducer";
import tableReducer from "./tableReducer";
import storeGroupReducer from "./storeGroupReducer";
import eventConfigurationReducer from './eventConfigReducer'
import dcStatusReducer from "./dcStatusReducer";
import { assortsmartReducer } from "../modules/assortsmart/services-assortsmart/CombinedService";
import mondaySmartReducer from "./mondaySmartReducer";
import storeSmartReducer from "./storeSmartReducer";


export default combineReducers({
  adminReducer,
  authReducer,
  layoutReducer,
  loaderReducer,
  filterReducer,
  tenantReducer,
  notificationReducer,
  workflowReducer,
  snackbarReducer,
  ticketingReducer,
  filterElementsReducer,
  productMappingReducer,
  UnitDefnReducer,
  eventConfigurationReducer,
  productGroupReducer,
  productStatusReducer,
  tableReducer,
  storeGroupReducer,
  dcStatusReducer,
  assortsmartReducer,
  mondaySmartReducer,
  storeSmartReducer
});
