import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { calendarConfig } from '../../config';
import { getFiscalWeekArray, getFiscalWeekAndYear } from './calendarHelperFuncs';
import moment from 'moment';
import _ from 'lodash';

const { fstMoOfYr } = calendarConfig;
const days = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
const nrfWksPatternForAYr = [4, 4, 5, 4, 4, 5, 4, 4, 5, 4, 4, 5];
const moJanIdx = 0;
const _4Wks = 4;
const _5Wks = 5;
const nrfCalYrs = {};

function generateNrfCalYrs(yr, nrfCalYrs) {
  const noOfDaysInAWk = days.length;
  const noOfMosInAYr = nrfWksPatternForAYr.length;
  const stDt = moment().year(yr).week(1).startOf('week');

  const doesJanHaveFiveWks = moment().year(yr).weeksInYear() === 53;

  doesJanHaveFiveWks && (nrfWksPatternForAYr[moJanIdx] = _5Wks);

  // Inserting Dates by forward calculating from the start date
  let nxtDt = moment(stDt);
  for (let moIdx = fstMoOfYr; moIdx < noOfMosInAYr + fstMoOfYr; moIdx++) {
    !nrfCalYrs[yr] && (nrfCalYrs[yr] = {});
    moIdx > 11 && !nrfCalYrs[yr + 1] && (nrfCalYrs[yr + 1] = {});
    const momentMoIdx = moment().month(moIdx).month();
    const moDts = [];
    for (let nrfWks = 0; nrfWks < nrfWksPatternForAYr[momentMoIdx]; nrfWks++) {
      for (let dayIdx = 0; dayIdx < noOfDaysInAWk; dayIdx++) {
        if (dayIdx === 0) {
          moDts[nrfWks] = [];
        }
        moDts[nrfWks].push(moment(nxtDt));
        nxtDt = nxtDt.add(1, 'days');
      }
    }
    moIdx < 12 ? (nrfCalYrs[yr][momentMoIdx] = moDts) : (nrfCalYrs[yr + 1][momentMoIdx] = moDts);
  }

  // Setting no of weeks for Jan back to default
  doesJanHaveFiveWks && (nrfWksPatternForAYr[moJanIdx] = _4Wks);
  return nrfCalYrs;
}

const Calendar =  function (props) {
  //reporting screen: for Monday to Sunday selection --- <  startDay = 0,endDay = 6;  >---
  //other screens: for Thursday to Wednesday selection --- <  startDay = 3,endDay = 2;  >---
  let startDay =
    props.calendarRange && props.calendarRange.day_start ? props.calendarRange.day_start : 0;
  let endDay = props.calendarRange && props.calendarRange.day_end ? props.calendarRange.day_end : 6;
  let fiscalCalendarDetails = props.fiscalCalendarDetails
//   let fiscalCalendarDetails = {
//     "2015": {
//         "6": {
//             "fy": "2015",
//             "fw": "2"
//         },
//         "7": {
//             "fy": "2015",
//             "fw": "3"
//         },
//         "8": {
//             "fy": "2015",
//             "fw": "4"
//         },
//         "9": {
//             "fy": "2015",
//             "fw": "5"
//         },
//         "10": {
//             "fy": "2015",
//             "fw": "6"
//         },
//         "11": {
//             "fy": "2015",
//             "fw": "7"
//         },
//         "12": {
//             "fy": "2015",
//             "fw": "8"
//         },
//         "13": {
//             "fy": "2015",
//             "fw": "9"
//         },
//         "14": {
//             "fy": "2015",
//             "fw": "10"
//         },
//         "15": {
//             "fy": "2015",
//             "fw": "11"
//         },
//         "16": {
//             "fy": "2015",
//             "fw": "12"
//         },
//         "17": {
//             "fy": "2015",
//             "fw": "13"
//         },
//         "18": {
//             "fy": "2015",
//             "fw": "14"
//         },
//         "19": {
//             "fy": "2015",
//             "fw": "15"
//         },
//         "20": {
//             "fy": "2015",
//             "fw": "16"
//         },
//         "21": {
//             "fy": "2015",
//             "fw": "17"
//         },
//         "22": {
//             "fy": "2015",
//             "fw": "18"
//         },
//         "23": {
//             "fy": "2015",
//             "fw": "19"
//         },
//         "24": {
//             "fy": "2015",
//             "fw": "20"
//         },
//         "25": {
//             "fy": "2015",
//             "fw": "21"
//         },
//         "26": {
//             "fy": "2015",
//             "fw": "22"
//         },
//         "27": {
//             "fy": "2015",
//             "fw": "23"
//         },
//         "28": {
//             "fy": "2015",
//             "fw": "24"
//         },
//         "29": {
//             "fy": "2015",
//             "fw": "25"
//         },
//         "30": {
//             "fy": "2015",
//             "fw": "26"
//         },
//         "31": {
//             "fy": "2015",
//             "fw": "27"
//         },
//         "32": {
//             "fy": "2015",
//             "fw": "28"
//         },
//         "33": {
//             "fy": "2015",
//             "fw": "29"
//         },
//         "34": {
//             "fy": "2015",
//             "fw": "30"
//         },
//         "35": {
//             "fy": "2015",
//             "fw": "31"
//         },
//         "36": {
//             "fy": "2015",
//             "fw": "32"
//         },
//         "37": {
//             "fy": "2015",
//             "fw": "33"
//         },
//         "38": {
//             "fy": "2015",
//             "fw": "34"
//         },
//         "39": {
//             "fy": "2015",
//             "fw": "35"
//         },
//         "40": {
//             "fy": "2015",
//             "fw": "36"
//         },
//         "41": {
//             "fy": "2015",
//             "fw": "37"
//         },
//         "42": {
//             "fy": "2015",
//             "fw": "38"
//         },
//         "43": {
//             "fy": "2015",
//             "fw": "39"
//         },
//         "44": {
//             "fy": "2015",
//             "fw": "40"
//         },
//         "45": {
//             "fy": "2015",
//             "fw": "41"
//         },
//         "46": {
//             "fy": "2015",
//             "fw": "42"
//         },
//         "47": {
//             "fy": "2015",
//             "fw": "43"
//         },
//         "48": {
//             "fy": "2015",
//             "fw": "44"
//         },
//         "49": {
//             "fy": "2015",
//             "fw": "45"
//         },
//         "50": {
//             "fy": "2015",
//             "fw": "46"
//         },
//         "51": {
//             "fy": "2015",
//             "fw": "47"
//         },
//         "52": {
//             "fy": "2015",
//             "fw": "48"
//         },
//         "53": {
//             "fy": "2015",
//             "fw": "49"
//         }
//     },
//     "2016": {
//         "1": {
//             "fy": "2015",
//             "fw": "50"
//         },
//         "2": {
//             "fy": "2015",
//             "fw": "51"
//         },
//         "3": {
//             "fy": "2015",
//             "fw": "52"
//         },
//         "4": {
//             "fy": "2016",
//             "fw": "1"
//         },
//         "5": {
//             "fy": "2016",
//             "fw": "2"
//         },
//         "6": {
//             "fy": "2016",
//             "fw": "3"
//         },
//         "7": {
//             "fy": "2016",
//             "fw": "4"
//         },
//         "8": {
//             "fy": "2016",
//             "fw": "5"
//         },
//         "9": {
//             "fy": "2016",
//             "fw": "6"
//         },
//         "10": {
//             "fy": "2016",
//             "fw": "7"
//         },
//         "11": {
//             "fy": "2016",
//             "fw": "8"
//         },
//         "12": {
//             "fy": "2016",
//             "fw": "9"
//         },
//         "13": {
//             "fy": "2016",
//             "fw": "10"
//         },
//         "14": {
//             "fy": "2016",
//             "fw": "11"
//         },
//         "15": {
//             "fy": "2016",
//             "fw": "12"
//         },
//         "16": {
//             "fy": "2016",
//             "fw": "13"
//         },
//         "17": {
//             "fy": "2016",
//             "fw": "14"
//         },
//         "18": {
//             "fy": "2016",
//             "fw": "15"
//         },
//         "19": {
//             "fy": "2016",
//             "fw": "16"
//         },
//         "20": {
//             "fy": "2016",
//             "fw": "17"
//         },
//         "21": {
//             "fy": "2016",
//             "fw": "18"
//         },
//         "22": {
//             "fy": "2016",
//             "fw": "19"
//         },
//         "23": {
//             "fy": "2016",
//             "fw": "20"
//         },
//         "24": {
//             "fy": "2016",
//             "fw": "21"
//         },
//         "25": {
//             "fy": "2016",
//             "fw": "22"
//         },
//         "26": {
//             "fy": "2016",
//             "fw": "23"
//         },
//         "27": {
//             "fy": "2016",
//             "fw": "24"
//         },
//         "28": {
//             "fy": "2016",
//             "fw": "25"
//         },
//         "29": {
//             "fy": "2016",
//             "fw": "26"
//         },
//         "30": {
//             "fy": "2016",
//             "fw": "27"
//         },
//         "31": {
//             "fy": "2016",
//             "fw": "28"
//         },
//         "32": {
//             "fy": "2016",
//             "fw": "29"
//         },
//         "33": {
//             "fy": "2016",
//             "fw": "30"
//         },
//         "34": {
//             "fy": "2016",
//             "fw": "31"
//         },
//         "35": {
//             "fy": "2016",
//             "fw": "32"
//         },
//         "36": {
//             "fy": "2016",
//             "fw": "33"
//         },
//         "37": {
//             "fy": "2016",
//             "fw": "34"
//         },
//         "38": {
//             "fy": "2016",
//             "fw": "35"
//         },
//         "39": {
//             "fy": "2016",
//             "fw": "36"
//         },
//         "40": {
//             "fy": "2016",
//             "fw": "37"
//         },
//         "41": {
//             "fy": "2016",
//             "fw": "38"
//         },
//         "42": {
//             "fy": "2016",
//             "fw": "39"
//         },
//         "43": {
//             "fy": "2016",
//             "fw": "40"
//         },
//         "44": {
//             "fy": "2016",
//             "fw": "41"
//         },
//         "45": {
//             "fy": "2016",
//             "fw": "42"
//         },
//         "46": {
//             "fy": "2016",
//             "fw": "43"
//         },
//         "47": {
//             "fy": "2016",
//             "fw": "44"
//         },
//         "48": {
//             "fy": "2016",
//             "fw": "45"
//         },
//         "49": {
//             "fy": "2016",
//             "fw": "46"
//         },
//         "50": {
//             "fy": "2016",
//             "fw": "47"
//         },
//         "51": {
//             "fy": "2016",
//             "fw": "48"
//         },
//         "52": {
//             "fy": "2016",
//             "fw": "49"
//         }
//     },
//     "2017": {
//         "1": {
//             "fy": "2016",
//             "fw": "50"
//         },
//         "2": {
//             "fy": "2016",
//             "fw": "51"
//         },
//         "3": {
//             "fy": "2016",
//             "fw": "52"
//         },
//         "4": {
//             "fy": "2017",
//             "fw": "1"
//         },
//         "5": {
//             "fy": "2017",
//             "fw": "2"
//         },
//         "6": {
//             "fy": "2017",
//             "fw": "3"
//         },
//         "7": {
//             "fy": "2017",
//             "fw": "4"
//         },
//         "8": {
//             "fy": "2017",
//             "fw": "5"
//         },
//         "9": {
//             "fy": "2017",
//             "fw": "6"
//         },
//         "10": {
//             "fy": "2017",
//             "fw": "7"
//         },
//         "11": {
//             "fy": "2017",
//             "fw": "8"
//         },
//         "12": {
//             "fy": "2017",
//             "fw": "9"
//         },
//         "13": {
//             "fy": "2017",
//             "fw": "10"
//         },
//         "14": {
//             "fy": "2017",
//             "fw": "11"
//         },
//         "15": {
//             "fy": "2017",
//             "fw": "12"
//         },
//         "16": {
//             "fy": "2017",
//             "fw": "13"
//         },
//         "17": {
//             "fy": "2017",
//             "fw": "14"
//         },
//         "18": {
//             "fy": "2017",
//             "fw": "15"
//         },
//         "19": {
//             "fy": "2017",
//             "fw": "16"
//         },
//         "20": {
//             "fy": "2017",
//             "fw": "17"
//         },
//         "21": {
//             "fy": "2017",
//             "fw": "18"
//         },
//         "22": {
//             "fy": "2017",
//             "fw": "19"
//         },
//         "23": {
//             "fy": "2017",
//             "fw": "20"
//         },
//         "24": {
//             "fy": "2017",
//             "fw": "21"
//         },
//         "25": {
//             "fy": "2017",
//             "fw": "22"
//         },
//         "26": {
//             "fy": "2017",
//             "fw": "23"
//         },
//         "27": {
//             "fy": "2017",
//             "fw": "24"
//         },
//         "28": {
//             "fy": "2017",
//             "fw": "25"
//         },
//         "29": {
//             "fy": "2017",
//             "fw": "26"
//         },
//         "30": {
//             "fy": "2017",
//             "fw": "27"
//         },
//         "31": {
//             "fy": "2017",
//             "fw": "28"
//         },
//         "32": {
//             "fy": "2017",
//             "fw": "29"
//         },
//         "33": {
//             "fy": "2017",
//             "fw": "30"
//         },
//         "34": {
//             "fy": "2017",
//             "fw": "31"
//         },
//         "35": {
//             "fy": "2017",
//             "fw": "32"
//         },
//         "36": {
//             "fy": "2017",
//             "fw": "33"
//         },
//         "37": {
//             "fy": "2017",
//             "fw": "34"
//         },
//         "38": {
//             "fy": "2017",
//             "fw": "35"
//         },
//         "39": {
//             "fy": "2017",
//             "fw": "36"
//         },
//         "40": {
//             "fy": "2017",
//             "fw": "37"
//         },
//         "41": {
//             "fy": "2017",
//             "fw": "38"
//         },
//         "42": {
//             "fy": "2017",
//             "fw": "39"
//         },
//         "43": {
//             "fy": "2017",
//             "fw": "40"
//         },
//         "44": {
//             "fy": "2017",
//             "fw": "41"
//         },
//         "45": {
//             "fy": "2017",
//             "fw": "42"
//         },
//         "46": {
//             "fy": "2017",
//             "fw": "43"
//         },
//         "47": {
//             "fy": "2017",
//             "fw": "44"
//         },
//         "48": {
//             "fy": "2017",
//             "fw": "45"
//         },
//         "49": {
//             "fy": "2017",
//             "fw": "46"
//         },
//         "50": {
//             "fy": "2017",
//             "fw": "47"
//         },
//         "51": {
//             "fy": "2017",
//             "fw": "48"
//         },
//         "52": {
//             "fy": "2017",
//             "fw": "49"
//         }
//     },
//     "2018": {
//         "1": {
//             "fy": "2017",
//             "fw": "50"
//         },
//         "2": {
//             "fy": "2017",
//             "fw": "51"
//         },
//         "3": {
//             "fy": "2017",
//             "fw": "52"
//         },
//         "4": {
//             "fy": "2017",
//             "fw": "53"
//         },
//         "5": {
//             "fy": "2018",
//             "fw": "1"
//         },
//         "6": {
//             "fy": "2018",
//             "fw": "2"
//         },
//         "7": {
//             "fy": "2018",
//             "fw": "3"
//         },
//         "8": {
//             "fy": "2018",
//             "fw": "4"
//         },
//         "9": {
//             "fy": "2018",
//             "fw": "5"
//         },
//         "10": {
//             "fy": "2018",
//             "fw": "6"
//         },
//         "11": {
//             "fy": "2018",
//             "fw": "7"
//         },
//         "12": {
//             "fy": "2018",
//             "fw": "8"
//         },
//         "13": {
//             "fy": "2018",
//             "fw": "9"
//         },
//         "14": {
//             "fy": "2018",
//             "fw": "10"
//         },
//         "15": {
//             "fy": "2018",
//             "fw": "11"
//         },
//         "16": {
//             "fy": "2018",
//             "fw": "12"
//         },
//         "17": {
//             "fy": "2018",
//             "fw": "13"
//         },
//         "18": {
//             "fy": "2018",
//             "fw": "14"
//         },
//         "19": {
//             "fy": "2018",
//             "fw": "15"
//         },
//         "20": {
//             "fy": "2018",
//             "fw": "16"
//         },
//         "21": {
//             "fy": "2018",
//             "fw": "17"
//         },
//         "22": {
//             "fy": "2018",
//             "fw": "18"
//         },
//         "23": {
//             "fy": "2018",
//             "fw": "19"
//         },
//         "24": {
//             "fy": "2018",
//             "fw": "20"
//         },
//         "25": {
//             "fy": "2018",
//             "fw": "21"
//         },
//         "26": {
//             "fy": "2018",
//             "fw": "22"
//         },
//         "27": {
//             "fy": "2018",
//             "fw": "23"
//         },
//         "28": {
//             "fy": "2018",
//             "fw": "24"
//         },
//         "29": {
//             "fy": "2018",
//             "fw": "25"
//         },
//         "30": {
//             "fy": "2018",
//             "fw": "26"
//         },
//         "31": {
//             "fy": "2018",
//             "fw": "27"
//         },
//         "32": {
//             "fy": "2018",
//             "fw": "28"
//         },
//         "33": {
//             "fy": "2018",
//             "fw": "29"
//         },
//         "34": {
//             "fy": "2018",
//             "fw": "30"
//         },
//         "35": {
//             "fy": "2018",
//             "fw": "31"
//         },
//         "36": {
//             "fy": "2018",
//             "fw": "32"
//         },
//         "37": {
//             "fy": "2018",
//             "fw": "33"
//         },
//         "38": {
//             "fy": "2018",
//             "fw": "34"
//         },
//         "39": {
//             "fy": "2018",
//             "fw": "35"
//         },
//         "40": {
//             "fy": "2018",
//             "fw": "36"
//         },
//         "41": {
//             "fy": "2018",
//             "fw": "37"
//         },
//         "42": {
//             "fy": "2018",
//             "fw": "38"
//         },
//         "43": {
//             "fy": "2018",
//             "fw": "39"
//         },
//         "44": {
//             "fy": "2018",
//             "fw": "40"
//         },
//         "45": {
//             "fy": "2018",
//             "fw": "41"
//         },
//         "46": {
//             "fy": "2018",
//             "fw": "42"
//         },
//         "47": {
//             "fy": "2018",
//             "fw": "43"
//         },
//         "48": {
//             "fy": "2018",
//             "fw": "44"
//         },
//         "49": {
//             "fy": "2018",
//             "fw": "45"
//         },
//         "50": {
//             "fy": "2018",
//             "fw": "46"
//         },
//         "51": {
//             "fy": "2018",
//             "fw": "47"
//         },
//         "52": {
//             "fy": "2018",
//             "fw": "48"
//         }
//     },
//     "2019": {
//         "1": {
//             "fy": "2018",
//             "fw": "49"
//         },
//         "2": {
//             "fy": "2018",
//             "fw": "50"
//         },
//         "3": {
//             "fy": "2018",
//             "fw": "51"
//         },
//         "4": {
//             "fy": "2018",
//             "fw": "52"
//         },
//         "5": {
//             "fy": "2019",
//             "fw": "1"
//         },
//         "6": {
//             "fy": "2019",
//             "fw": "2"
//         },
//         "7": {
//             "fy": "2019",
//             "fw": "3"
//         },
//         "8": {
//             "fy": "2019",
//             "fw": "4"
//         },
//         "9": {
//             "fy": "2019",
//             "fw": "5"
//         },
//         "10": {
//             "fy": "2019",
//             "fw": "6"
//         },
//         "11": {
//             "fy": "2019",
//             "fw": "7"
//         },
//         "12": {
//             "fy": "2019",
//             "fw": "8"
//         },
//         "13": {
//             "fy": "2019",
//             "fw": "9"
//         },
//         "14": {
//             "fy": "2019",
//             "fw": "10"
//         },
//         "15": {
//             "fy": "2019",
//             "fw": "11"
//         },
//         "16": {
//             "fy": "2019",
//             "fw": "12"
//         },
//         "17": {
//             "fy": "2019",
//             "fw": "13"
//         },
//         "18": {
//             "fy": "2019",
//             "fw": "14"
//         },
//         "19": {
//             "fy": "2019",
//             "fw": "15"
//         },
//         "20": {
//             "fy": "2019",
//             "fw": "16"
//         },
//         "21": {
//             "fy": "2019",
//             "fw": "17"
//         },
//         "22": {
//             "fy": "2019",
//             "fw": "18"
//         },
//         "23": {
//             "fy": "2019",
//             "fw": "19"
//         },
//         "24": {
//             "fy": "2019",
//             "fw": "20"
//         },
//         "25": {
//             "fy": "2019",
//             "fw": "21"
//         },
//         "26": {
//             "fy": "2019",
//             "fw": "22"
//         },
//         "27": {
//             "fy": "2019",
//             "fw": "23"
//         },
//         "28": {
//             "fy": "2019",
//             "fw": "24"
//         },
//         "29": {
//             "fy": "2019",
//             "fw": "25"
//         },
//         "30": {
//             "fy": "2019",
//             "fw": "26"
//         },
//         "31": {
//             "fy": "2019",
//             "fw": "27"
//         },
//         "32": {
//             "fy": "2019",
//             "fw": "28"
//         },
//         "33": {
//             "fy": "2019",
//             "fw": "29"
//         },
//         "34": {
//             "fy": "2019",
//             "fw": "30"
//         },
//         "35": {
//             "fy": "2019",
//             "fw": "31"
//         },
//         "36": {
//             "fy": "2019",
//             "fw": "32"
//         },
//         "37": {
//             "fy": "2019",
//             "fw": "33"
//         },
//         "38": {
//             "fy": "2019",
//             "fw": "34"
//         },
//         "39": {
//             "fy": "2019",
//             "fw": "35"
//         },
//         "40": {
//             "fy": "2019",
//             "fw": "36"
//         },
//         "41": {
//             "fy": "2019",
//             "fw": "37"
//         },
//         "42": {
//             "fy": "2019",
//             "fw": "38"
//         },
//         "43": {
//             "fy": "2019",
//             "fw": "39"
//         },
//         "44": {
//             "fy": "2019",
//             "fw": "40"
//         },
//         "45": {
//             "fy": "2019",
//             "fw": "41"
//         },
//         "46": {
//             "fy": "2019",
//             "fw": "42"
//         },
//         "47": {
//             "fy": "2019",
//             "fw": "43"
//         },
//         "48": {
//             "fy": "2019",
//             "fw": "44"
//         },
//         "49": {
//             "fy": "2019",
//             "fw": "45"
//         },
//         "50": {
//             "fy": "2019",
//             "fw": "46"
//         },
//         "51": {
//             "fy": "2019",
//             "fw": "47"
//         },
//         "52": {
//             "fy": "2019",
//             "fw": "48"
//         }
//     },
//     "2020": {
//         "1": {
//             "fy": "2019",
//             "fw": "49"
//         },
//         "2": {
//             "fy": "2019",
//             "fw": "50"
//         },
//         "3": {
//             "fy": "2019",
//             "fw": "51"
//         },
//         "4": {
//             "fy": "2019",
//             "fw": "52"
//         },
//         "5": {
//             "fy": "2020",
//             "fw": "1"
//         },
//         "6": {
//             "fy": "2020",
//             "fw": "2"
//         },
//         "7": {
//             "fy": "2020",
//             "fw": "3"
//         },
//         "8": {
//             "fy": "2020",
//             "fw": "4"
//         },
//         "9": {
//             "fy": "2020",
//             "fw": "5"
//         },
//         "10": {
//             "fy": "2020",
//             "fw": "6"
//         },
//         "11": {
//             "fy": "2020",
//             "fw": "7"
//         },
//         "12": {
//             "fy": "2020",
//             "fw": "8"
//         },
//         "13": {
//             "fy": "2020",
//             "fw": "9"
//         },
//         "14": {
//             "fy": "2020",
//             "fw": "10"
//         },
//         "15": {
//             "fy": "2020",
//             "fw": "11"
//         },
//         "16": {
//             "fy": "2020",
//             "fw": "12"
//         },
//         "17": {
//             "fy": "2020",
//             "fw": "13"
//         },
//         "18": {
//             "fy": "2020",
//             "fw": "14"
//         },
//         "19": {
//             "fy": "2020",
//             "fw": "15"
//         },
//         "20": {
//             "fy": "2020",
//             "fw": "16"
//         },
//         "21": {
//             "fy": "2020",
//             "fw": "17"
//         },
//         "22": {
//             "fy": "2020",
//             "fw": "18"
//         },
//         "23": {
//             "fy": "2020",
//             "fw": "19"
//         },
//         "24": {
//             "fy": "2020",
//             "fw": "20"
//         },
//         "25": {
//             "fy": "2020",
//             "fw": "21"
//         },
//         "26": {
//             "fy": "2020",
//             "fw": "22"
//         },
//         "27": {
//             "fy": "2020",
//             "fw": "23"
//         },
//         "28": {
//             "fy": "2020",
//             "fw": "24"
//         },
//         "29": {
//             "fy": "2020",
//             "fw": "25"
//         },
//         "30": {
//             "fy": "2020",
//             "fw": "26"
//         },
//         "31": {
//             "fy": "2020",
//             "fw": "27"
//         },
//         "32": {
//             "fy": "2020",
//             "fw": "28"
//         },
//         "33": {
//             "fy": "2020",
//             "fw": "29"
//         },
//         "34": {
//             "fy": "2020",
//             "fw": "30"
//         },
//         "35": {
//             "fy": "2020",
//             "fw": "31"
//         },
//         "36": {
//             "fy": "2020",
//             "fw": "32"
//         },
//         "37": {
//             "fy": "2020",
//             "fw": "33"
//         },
//         "38": {
//             "fy": "2020",
//             "fw": "34"
//         },
//         "39": {
//             "fy": "2020",
//             "fw": "35"
//         },
//         "40": {
//             "fy": "2020",
//             "fw": "36"
//         },
//         "41": {
//             "fy": "2020",
//             "fw": "37"
//         },
//         "42": {
//             "fy": "2020",
//             "fw": "38"
//         },
//         "43": {
//             "fy": "2020",
//             "fw": "39"
//         },
//         "44": {
//             "fy": "2020",
//             "fw": "40"
//         },
//         "45": {
//             "fy": "2020",
//             "fw": "41"
//         },
//         "46": {
//             "fy": "2020",
//             "fw": "42"
//         },
//         "47": {
//             "fy": "2020",
//             "fw": "43"
//         },
//         "48": {
//             "fy": "2020",
//             "fw": "44"
//         },
//         "49": {
//             "fy": "2020",
//             "fw": "45"
//         },
//         "50": {
//             "fy": "2020",
//             "fw": "46"
//         },
//         "51": {
//             "fy": "2020",
//             "fw": "47"
//         },
//         "52": {
//             "fy": "2020",
//             "fw": "48"
//         },
//         "53": {
//             "fy": "2020",
//             "fw": "49"
//         }
//     },
//     "2021": {
//         "1": {
//             "fy": "2020",
//             "fw": "50"
//         },
//         "2": {
//             "fy": "2020",
//             "fw": "51"
//         },
//         "3": {
//             "fy": "2020",
//             "fw": "52"
//         },
//         "4": {
//             "fy": "2021",
//             "fw": "1"
//         },
//         "5": {
//             "fy": "2021",
//             "fw": "2"
//         },
//         "6": {
//             "fy": "2021",
//             "fw": "3"
//         },
//         "7": {
//             "fy": "2021",
//             "fw": "4"
//         },
//         "8": {
//             "fy": "2021",
//             "fw": "5"
//         },
//         "9": {
//             "fy": "2021",
//             "fw": "6"
//         },
//         "10": {
//             "fy": "2021",
//             "fw": "7"
//         },
//         "11": {
//             "fy": "2021",
//             "fw": "8"
//         },
//         "12": {
//             "fy": "2021",
//             "fw": "9"
//         },
//         "13": {
//             "fy": "2021",
//             "fw": "10"
//         },
//         "14": {
//             "fy": "2021",
//             "fw": "11"
//         },
//         "15": {
//             "fy": "2021",
//             "fw": "12"
//         },
//         "16": {
//             "fy": "2021",
//             "fw": "13"
//         },
//         "17": {
//             "fy": "2021",
//             "fw": "14"
//         },
//         "18": {
//             "fy": "2021",
//             "fw": "15"
//         },
//         "19": {
//             "fy": "2021",
//             "fw": "16"
//         },
//         "20": {
//             "fy": "2021",
//             "fw": "17"
//         },
//         "21": {
//             "fy": "2021",
//             "fw": "18"
//         },
//         "22": {
//             "fy": "2021",
//             "fw": "19"
//         },
//         "23": {
//             "fy": "2021",
//             "fw": "20"
//         },
//         "24": {
//             "fy": "2021",
//             "fw": "21"
//         },
//         "25": {
//             "fy": "2021",
//             "fw": "22"
//         },
//         "26": {
//             "fy": "2021",
//             "fw": "23"
//         },
//         "27": {
//             "fy": "2021",
//             "fw": "24"
//         },
//         "28": {
//             "fy": "2021",
//             "fw": "25"
//         },
//         "29": {
//             "fy": "2021",
//             "fw": "26"
//         },
//         "30": {
//             "fy": "2021",
//             "fw": "27"
//         },
//         "31": {
//             "fy": "2021",
//             "fw": "28"
//         },
//         "32": {
//             "fy": "2021",
//             "fw": "29"
//         },
//         "33": {
//             "fy": "2021",
//             "fw": "30"
//         },
//         "34": {
//             "fy": "2021",
//             "fw": "31"
//         },
//         "35": {
//             "fy": "2021",
//             "fw": "32"
//         },
//         "36": {
//             "fy": "2021",
//             "fw": "33"
//         },
//         "37": {
//             "fy": "2021",
//             "fw": "34"
//         },
//         "38": {
//             "fy": "2021",
//             "fw": "35"
//         },
//         "39": {
//             "fy": "2021",
//             "fw": "36"
//         },
//         "40": {
//             "fy": "2021",
//             "fw": "37"
//         },
//         "41": {
//             "fy": "2021",
//             "fw": "38"
//         },
//         "42": {
//             "fy": "2021",
//             "fw": "39"
//         },
//         "43": {
//             "fy": "2021",
//             "fw": "40"
//         },
//         "44": {
//             "fy": "2021",
//             "fw": "41"
//         },
//         "45": {
//             "fy": "2021",
//             "fw": "42"
//         },
//         "46": {
//             "fy": "2021",
//             "fw": "43"
//         },
//         "47": {
//             "fy": "2021",
//             "fw": "44"
//         },
//         "48": {
//             "fy": "2021",
//             "fw": "45"
//         },
//         "49": {
//             "fy": "2021",
//             "fw": "46"
//         },
//         "50": {
//             "fy": "2021",
//             "fw": "47"
//         },
//         "51": {
//             "fy": "2021",
//             "fw": "48"
//         },
//         "52": {
//             "fy": "2021",
//             "fw": "49"
//         }
//     },
//     "2022": {
//         "1": {
//             "fy": "2021",
//             "fw": "50"
//         },
//         "2": {
//             "fy": "2021",
//             "fw": "51"
//         },
//         "3": {
//             "fy": "2021",
//             "fw": "52"
//         },
//         "4": {
//             "fy": "2022",
//             "fw": "1"
//         },
//         "5": {
//             "fy": "2022",
//             "fw": "2"
//         },
//         "6": {
//             "fy": "2022",
//             "fw": "3"
//         },
//         "7": {
//             "fy": "2022",
//             "fw": "4"
//         },
//         "8": {
//             "fy": "2022",
//             "fw": "5"
//         },
//         "9": {
//             "fy": "2022",
//             "fw": "6"
//         },
//         "10": {
//             "fy": "2022",
//             "fw": "7"
//         },
//         "11": {
//             "fy": "2022",
//             "fw": "8"
//         },
//         "12": {
//             "fy": "2022",
//             "fw": "9"
//         },
//         "13": {
//             "fy": "2022",
//             "fw": "10"
//         },
//         "14": {
//             "fy": "2022",
//             "fw": "11"
//         },
//         "15": {
//             "fy": "2022",
//             "fw": "12"
//         },
//         "16": {
//             "fy": "2022",
//             "fw": "13"
//         },
//         "17": {
//             "fy": "2022",
//             "fw": "14"
//         },
//         "18": {
//             "fy": "2022",
//             "fw": "15"
//         },
//         "19": {
//             "fy": "2022",
//             "fw": "16"
//         },
//         "20": {
//             "fy": "2022",
//             "fw": "17"
//         },
//         "21": {
//             "fy": "2022",
//             "fw": "18"
//         },
//         "22": {
//             "fy": "2022",
//             "fw": "19"
//         },
//         "23": {
//             "fy": "2022",
//             "fw": "20"
//         },
//         "24": {
//             "fy": "2022",
//             "fw": "21"
//         },
//         "25": {
//             "fy": "2022",
//             "fw": "22"
//         },
//         "26": {
//             "fy": "2022",
//             "fw": "23"
//         },
//         "27": {
//             "fy": "2022",
//             "fw": "24"
//         },
//         "28": {
//             "fy": "2022",
//             "fw": "25"
//         },
//         "29": {
//             "fy": "2022",
//             "fw": "26"
//         },
//         "30": {
//             "fy": "2022",
//             "fw": "27"
//         },
//         "31": {
//             "fy": "2022",
//             "fw": "28"
//         },
//         "32": {
//             "fy": "2022",
//             "fw": "29"
//         },
//         "33": {
//             "fy": "2022",
//             "fw": "30"
//         },
//         "34": {
//             "fy": "2022",
//             "fw": "31"
//         },
//         "35": {
//             "fy": "2022",
//             "fw": "32"
//         },
//         "36": {
//             "fy": "2022",
//             "fw": "33"
//         },
//         "37": {
//             "fy": "2022",
//             "fw": "34"
//         },
//         "38": {
//             "fy": "2022",
//             "fw": "35"
//         },
//         "39": {
//             "fy": "2022",
//             "fw": "36"
//         },
//         "40": {
//             "fy": "2022",
//             "fw": "37"
//         },
//         "41": {
//             "fy": "2022",
//             "fw": "38"
//         },
//         "42": {
//             "fy": "2022",
//             "fw": "39"
//         },
//         "43": {
//             "fy": "2022",
//             "fw": "40"
//         },
//         "44": {
//             "fy": "2022",
//             "fw": "41"
//         },
//         "45": {
//             "fy": "2022",
//             "fw": "42"
//         },
//         "46": {
//             "fy": "2022",
//             "fw": "43"
//         },
//         "47": {
//             "fy": "2022",
//             "fw": "44"
//         },
//         "48": {
//             "fy": "2022",
//             "fw": "45"
//         },
//         "49": {
//             "fy": "2022",
//             "fw": "46"
//         },
//         "50": {
//             "fy": "2022",
//             "fw": "47"
//         },
//         "51": {
//             "fy": "2022",
//             "fw": "48"
//         },
//         "52": {
//             "fy": "2022",
//             "fw": "49"
//         }
//     },
//     "2023": {
//         "1": {
//             "fy": "2022",
//             "fw": "50"
//         },
//         "2": {
//             "fy": "2022",
//             "fw": "51"
//         },
//         "3": {
//             "fy": "2022",
//             "fw": "52"
//         },
//         "4": {
//             "fy": "2023",
//             "fw": "1"
//         },
//         "5": {
//             "fy": "2023",
//             "fw": "2"
//         },
//         "6": {
//             "fy": "2023",
//             "fw": "3"
//         },
//         "7": {
//             "fy": "2023",
//             "fw": "4"
//         },
//         "8": {
//             "fy": "2023",
//             "fw": "5"
//         },
//         "9": {
//             "fy": "2023",
//             "fw": "6"
//         },
//         "10": {
//             "fy": "2023",
//             "fw": "7"
//         },
//         "11": {
//             "fy": "2023",
//             "fw": "8"
//         },
//         "12": {
//             "fy": "2023",
//             "fw": "9"
//         },
//         "13": {
//             "fy": "2023",
//             "fw": "10"
//         },
//         "14": {
//             "fy": "2023",
//             "fw": "11"
//         },
//         "15": {
//             "fy": "2023",
//             "fw": "12"
//         },
//         "16": {
//             "fy": "2023",
//             "fw": "13"
//         },
//         "17": {
//             "fy": "2023",
//             "fw": "14"
//         },
//         "18": {
//             "fy": "2023",
//             "fw": "15"
//         },
//         "19": {
//             "fy": "2023",
//             "fw": "16"
//         },
//         "20": {
//             "fy": "2023",
//             "fw": "17"
//         },
//         "21": {
//             "fy": "2023",
//             "fw": "18"
//         },
//         "22": {
//             "fy": "2023",
//             "fw": "19"
//         },
//         "23": {
//             "fy": "2023",
//             "fw": "20"
//         },
//         "24": {
//             "fy": "2023",
//             "fw": "21"
//         },
//         "25": {
//             "fy": "2023",
//             "fw": "22"
//         },
//         "26": {
//             "fy": "2023",
//             "fw": "23"
//         },
//         "27": {
//             "fy": "2023",
//             "fw": "24"
//         },
//         "28": {
//             "fy": "2023",
//             "fw": "25"
//         },
//         "29": {
//             "fy": "2023",
//             "fw": "26"
//         },
//         "30": {
//             "fy": "2023",
//             "fw": "27"
//         },
//         "31": {
//             "fy": "2023",
//             "fw": "28"
//         },
//         "32": {
//             "fy": "2023",
//             "fw": "29"
//         },
//         "33": {
//             "fy": "2023",
//             "fw": "30"
//         },
//         "34": {
//             "fy": "2023",
//             "fw": "31"
//         },
//         "35": {
//             "fy": "2023",
//             "fw": "32"
//         },
//         "36": {
//             "fy": "2023",
//             "fw": "33"
//         },
//         "37": {
//             "fy": "2023",
//             "fw": "34"
//         },
//         "38": {
//             "fy": "2023",
//             "fw": "35"
//         },
//         "39": {
//             "fy": "2023",
//             "fw": "36"
//         },
//         "40": {
//             "fy": "2023",
//             "fw": "37"
//         },
//         "41": {
//             "fy": "2023",
//             "fw": "38"
//         },
//         "42": {
//             "fy": "2023",
//             "fw": "39"
//         },
//         "43": {
//             "fy": "2023",
//             "fw": "40"
//         },
//         "44": {
//             "fy": "2023",
//             "fw": "41"
//         },
//         "45": {
//             "fy": "2023",
//             "fw": "42"
//         },
//         "46": {
//             "fy": "2023",
//             "fw": "43"
//         },
//         "47": {
//             "fy": "2023",
//             "fw": "44"
//         },
//         "48": {
//             "fy": "2023",
//             "fw": "45"
//         },
//         "49": {
//             "fy": "2023",
//             "fw": "46"
//         },
//         "50": {
//             "fy": "2023",
//             "fw": "47"
//         },
//         "51": {
//             "fy": "2023",
//             "fw": "48"
//         },
//         "52": {
//             "fy": "2023",
//             "fw": "49"
//         }
//     },
//     "2024": {
//         "1": {
//             "fy": "2023",
//             "fw": "50"
//         },
//         "2": {
//             "fy": "2023",
//             "fw": "51"
//         },
//         "3": {
//             "fy": "2023",
//             "fw": "52"
//         },
//         "4": {
//             "fy": "2023",
//             "fw": "53"
//         }
//     }
// }

let daterangeOrWeek = 'date_range'
  const getCalendarCellStyle = (dt, stDt, endDt) => {
    let className = 'calendar-date_default';
    // let preStartDateLimit = moment().subtract(60, 'days');
    // if (
    //   props.pickJustDate &&
    // //   props.disableFutureWeeks &&
    // //   props.disableOldWeeks &&
    //   (moment().isSameOrBefore(dt.format('YYYY-MM-DD'), 'day') ||
    //     preStartDateLimit.isAfter(dt.format('YYYY-MM-DD'), 'day'))
    // ) {
    //   className = 'calendar-date_disable';
    // }
    // if (
    //   startDay == 0 &&
    //   endDay == 6 &&
    //   !props.pickJustDate 
    //   // &&
    // //   props.disableOldWeeks &&
    // //  moment().isAfter(dt.format('YYYY-MM-DD'), 'week')
    // ) {
    //   className = 'calendar-date_disable';
    // } else if (
    //   startDay == 3 &&
    //   endDay == 2 &&
    //   !props.pickJustDate &&
    // //   props.disableOldWeeks &&
    //   (moment().isAfter(dt.format('YYYY-MM-DD'), 'week') ||
    //     (moment().isSame(dt.format('YYYY-MM-DD'), 'week') &&
    //       moment(dt.format('YYYY-MM-DD')).weekday() < 3))
    // ) {
    //   className = 'calendar-date_disable';
    // }
    // if (
    //   !props.pickJustDate &&
    //   //props.disableFutureWeeks &&
    //   moment().isSameOrBefore(dt.format('YYYY-MM-DD'), 'week')
    //   // (moment().isBefore(dt.format('YYYY-MM-DD'), 'week') ||
    //   //   (moment().isSame(dt.format('YYYY-MM-DD'), 'week') &&
    //   //     moment(dt.format('YYYY-MM-DD')).weekday() > endDay))
    // ) {
    //   className = 'calendar-date_disable';
    // }
    if (props.pickJustDate) {
      if (props.startDate && !props.endDate) {
        dt.isSame(props.startDate, 'day') && (className = 'selected-date');
        dt.isAfter(props.startDate, 'day') &&
          dt.isSameOrBefore(props.mouseOveredDt, 'day') &&
          (className = 'calendar-date_hovered');
      } else if (props.startDate && props.endDate) {
        (dt.isSame(props.startDate, 'day') || dt.isSame(props.endDate, 'day')) &&
          (className = 'selected-date');
        dt.isAfter(props.startDate, 'day') &&
          dt.isBefore(props.endDate, 'day') &&
          (className = 'calendar-date_hovered');
        (dt.isBefore(props.startDate, 'day') || dt.isAfter(props.endDate, 'day')) &&
          dt.isSame(props.mouseOveredDt, 'day') &&
          (className = 'calendar-date_default-hover');
      } else {
        dt.isSame(props.mouseOveredDt, 'day') && (className = 'calendar-date_default-hover');
      }
    } else {
      if (props.startDate && !props.endDate) {
        dt.isSame(props.startDate) && (className = 'selected-date');
        dt.isAfter(props.startDate) &&
          dt.isSameOrBefore(props.mouseOveredDt) &&
          (className = 'calendar-date_hovered');
      } else if (props.startDate && props.endDate) {
        (dt.isSame(props.startDate) || dt.isSame(props.endDate)) && (className = 'selected-date');
        dt.isAfter(props.startDate) &&
          dt.isBefore(props.endDate) &&
          (className = 'calendar-date_hovered');
        props.mouseOveredDt &&
          (dt.isBefore(props.startDate) || dt.isAfter(props.endDate)) &&
          props.mouseOveredDt.isBetween(
            moment(stDt).subtract(1, 'days'),
            moment(endDt).add(1, 'days')
          ) &&
          (className = 'calendar-date_default-hover');
      } else {
        props.mouseOveredDt &&
          props.mouseOveredDt.isBetween(
            moment(stDt).subtract(1, 'days'),
            moment(endDt).add(1, 'days')
          ) &&
          (className = 'calendar-date_default-hover');
      }
    }
    return className;
  };
  return (
    <div>
      <div style={{textAlign:'center'}} className="t-a-c mt-2">
        {props.month.format('MMM')} {props.month.format('YYYY')}
      </div>
      <table className="m-3">
        <tbody>
          <tr>
            <th></th>
            {props.nrfCalDays &&
              props.nrfCalDays[0] &&
              _.map(props.nrfCalDays[0], (day, idx) => (
                <th className="calendar-cell-size calendar-day" key={idx}>
                  {day.format('dd')}
                </th>
              ))}
          </tr>
          {props.nrfCalDays &&
            _.map(props.nrfCalDays, (week, idx) => (
              <tr key={idx}>
                <th className="calendar-cell-size calendar-date">
                  {/* {props.daterangeOrWeek === 'date_range'
                    ? getFiscalWeekAndYear(
                        props.nrfCalDays[idx][0].year(),
                        props.nrfCalDays[idx][0].week(),
                        props.fiscalCalendarDetails
                      )
                    : props.nrfCalDays[idx][0].week()} */}
                  {getFiscalWeekAndYear(
                    props.nrfCalDays[idx][0].year(),
                    props.nrfCalDays[idx][0].week(),
                    fiscalCalendarDetails,
                    true
                  )}
                </th>
                {week.map((dt, idx) => {
                  return (
                    <td
                      key={idx}
                      onClick={() => {
                        if (!props.pickJustDate) {
                          if (!props.startDate) {
                            props.onClick(week[startDay]);
                          } else if (props.totalNumOfWeeksToBeSelected) {
                            let sDate = week[startDay];
                            let eDate = moment(week[startDay])
                              .add(props.totalNumOfWeeksToBeSelected, 'weeks')
                              .day(startDay);
                            let data = getFiscalWeekArray(
                              sDate,
                              eDate,
                              daterangeOrWeek,
                              fiscalCalendarDetails
                            );
                            props.onClick(sDate, data.weekNumList, data.yearList, eDate);
                          } else {
                            if (dt.isBefore(props.startDate)) {
                              let data = props.endDate
                                ? getFiscalWeekArray(
                                    week[startDay],
                                    props.endDate,
                                    daterangeOrWeek,
                                    fiscalCalendarDetails
                                  )
                                : {};
                              props.onClick(week[startDay], data.weekNumList, data.yearList);
                            } else if (
                              (startDay !== 0 &&
                                //do not allow selection from same week in case of Thu to Wed selection
                                moment(week[endDay]).week() !== moment(props.startDate).week()) ||
                              startDay === 0
                            ) {
                              let data = getFiscalWeekArray(
                                props.startDate,
                                week[endDay],
                                daterangeOrWeek,
                                fiscalCalendarDetails
                              );
                              props.onClick(week[endDay], data.weekNumList, data.yearList);
                            }
                          }
                        } else {
                          props.onClick(dt);
                        }
                      }}
                      onMouseOver={() => {
                        if (!props.pickJustDate) {
                          if (props.startDate) {
                            if (dt.isSameOrAfter(props.startDate)) {
                              props.onMouseOver(week[endDay]);
                            } else {
                              props.onMouseOver(week[startDay]);
                            }
                          } else {
                            props.onMouseOver(week[startDay]);
                          }
                        } else {
                          props.onMouseOver(dt);
                        }
                      }}
                      className={`calendar-cell-size ${getCalendarCellStyle(
                        dt,
                        week[0],
                        week[6]
                      )} calendar-date`}>
                      {dt.date()}
                    </td>
                  );
                })}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

class MultipleCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mouseOveredDt: null,
    };
  }
  render() {
    return (
      <div style={{ display: 'flex' }}>
        {this.props.months.map((month, idx) => {
          return (
            <Calendar
              key={idx}
              month={month}
              onClick={this.props.onClick}
              onMouseOver={(dt) => {
                this.setState({
                  mouseOveredDt: dt,
                });
              }}
              startDate={this.props.startDate}
              endDate={this.props.endDate}
              mouseOveredDt={this.state.mouseOveredDt}
              disableOldWeeks={this.props.disableOldWeeks}
              disableFutureWeeks={this.props.disableFutureWeeks}
              nrfCalDays={
                this.props.nrfCalYrs[month.year()] &&
                this.props.nrfCalYrs[month.year()][month.month()]
              }
              fiscalCalendarDetails={this.props.fiscalCalendarDetails}
              pathname={this.props.pathname}
              calendarRange={this.props.calendarRange}
            //   daterangeOrWeek={this.props.daterangeOrWeek}
              pickJustDate={this.props.pickJustDate}
              totalNumOfWeeksToBeSelected={this.props.totalNumOfWeeksToBeSelected}
            />
          );
        })}
      </div>
    );
  }
}

class RetailCalendar extends Component {
  constructor(props) {
    super(props);
    const curDt = moment();
    const dispMosCnt = props.displayMonth ? props.displayMonth : 2; //min:1, max:12
    const width = { width: `${344 * dispMosCnt}px` };
    let dispMos = [];
    generateNrfCalYrs(curDt.year(), nrfCalYrs);
    if (curDt.month() === 11) generateNrfCalYrs(curDt.year() + 1, nrfCalYrs);
    for (let i = 0; i < dispMosCnt; i++) {
      dispMos.push(moment(curDt).add(i, 'month'));
    }
    let fyear = curDt.year();
    if (curDt.month() === 11) {
      fyear = curDt.year() + 1;
    }
    if (curDt.month() === 0) {
      fyear = curDt.year() - 1;
    }

    this.state = {
      dispMos,
      curDt,
      financialYear: moment().year(),
      width,
    };
  }

  onPrevMoClick = () => {
    const fstMo = this.state.dispMos[0];
    if (fstMo.month() === fstMoOfYr) {
      this.setState(
        {
          financialYear: fstMo.year(),
        },
        () => this.onPrevYrClick()
      );
    }
    const sftdMosBkByOneMo = this.state.dispMos.map((month) => {
      return month.subtract(1, 'month');
    });
    this.setState({ dispMos: sftdMosBkByOneMo });
  };
  onNxtMoClick = () => {
    const { dispMos } = this.state;
    const lstMo = dispMos[dispMos.length - 1];
    const lstMoOfYr = moment()
      .month(fstMoOfYr - 1)
      .month();
    if (lstMo.month() === lstMoOfYr) {
      this.onNxtYrClick();
    }
    const sftdMosFwdByOneMo = this.state.dispMos.map((month) => {
      return month.add(1, 'month');
    });
    this.setState({ dispMos: sftdMosFwdByOneMo });
  };
  onPrevYrClick = () => {
    const { financialYear: yr } = this.state;
    const prevYr = yr - 1;
    const nxtYr = yr + 1;
    generateNrfCalYrs(prevYr, nrfCalYrs);
    this.setState({ financialYear: prevYr });
    if (nrfCalYrs.hasOwnProperty(nxtYr)) delete nrfCalYrs[nxtYr];
  };
  onNxtYrClick = () => {
    const { financialYear: yr } = this.state;
    // const prevYr = yr - 1;
    const nxtYr = yr + 1;
    generateNrfCalYrs(nxtYr, nrfCalYrs);
    this.setState({ financialYear: nxtYr });
    // if (nrfCalYrs.hasOwnProperty(prevYr)) {
    //   delete nrfCalYrs[prevYr];
    // }
  };
  render() {
    return (
      <div className="m-3" style={{ ...this.state.width }}>
        <div className="arrow left-arrow">
          <i
            onClick={this.onPrevMoClick}
            className="dr-calendar-month-arrow fa fa-angle-left"
            aria-hidden="true"></i>
        </div>
        <div className="arrow right-arrow">
          <i
            onClick={this.onNxtMoClick}
            className="dr-calendar-month-arrow fa fa-angle-right"
            aria-hidden="true"></i>
        </div>

        <MultipleCalendar
          months={this.state.dispMos}
          startDate={this.props.startDate}
          endDate={this.props.endDate}
          nrfCalYrs={nrfCalYrs}
          onClick={(dt, ...args) => {
            if (dt.month() === 11) this.onNxtYrClick();
            this.props.onClick(dt, ...args);
          }}
         fiscalCalendarDetails={this.props.fiscalCalendarDetails}
          pathname={this.props.location && this.props.location.pathname}
          calendarRange={this.props.calendarRange}
        //   daterangeOrWeek={this.props.daterangeOrWeek}
          disableOldWeeks={this.props.disableOldWeeks}
          disableFutureWeeks={this.props.disableFutureWeeks}
          pickJustDate={this.props.pickJustDate}
          totalNumOfWeeksToBeSelected={this.props.totalNumOfWeeksToBeSelected}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
//   fiscalCalendarDetails: state.fiscalCalendar.fiscalCalDetails,
//   daterangeOrWeek: state.filtersReducer.daterangeOrWeek,
});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(RetailCalendar));
