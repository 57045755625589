import _ from 'lodash';
import moment from 'moment';

export const getFiscalWeekAndYear = (year, weekNum, fiscalCalendarDetails, isOnlyWeek) => {
  let fiscalWeeks = fiscalCalendarDetails && fiscalCalendarDetails[year];
  if (_.isEmpty(fiscalWeeks)) return null;

  return isOnlyWeek
    ? fiscalWeeks[weekNum] && fiscalWeeks[weekNum].fw
    : {
        week: fiscalWeeks[weekNum] && fiscalWeeks[weekNum].fw,
        year: fiscalWeeks[weekNum] && fiscalWeeks[weekNum].fy,
      };
};

export const getFiscalWeekArray = (startDate, endDate, daterangeOrWeek, fiscalCalendarDetails) => {
  let newSDate = _.cloneDeep(startDate);
  let newEDate = _.cloneDeep(startDate);
  let weekNumList = [];
  let yearList = [];

  while (moment(newEDate).isBefore(endDate, 'day')) {
    let fiscalData = getFiscalWeekAndYear(
      moment(newSDate).year(),
      moment(newSDate).week(),
      fiscalCalendarDetails
    );
    // let year = moment(newSDate).year();
    if (fiscalData.week === null) break;
    weekNumList.push(parseInt(fiscalData.week));
    yearList.push(parseInt(fiscalData.year));
    newEDate = moment(newSDate).add(6, 'd');
    newSDate = moment(newSDate).add(7, 'd');
  }
  return { weekNumList, yearList };
};

export const pastWeekFutureWeekHandler = (
  startDate,
  endDate,
  daterangeOrWeek,
) => {
  let calPastWeeks = [];
  let calFutureWeeks = [];
  let crrntDate = moment().day('Sunday').format('YYYY-MM-DD');
  let fiscalCalDetails = {
      "2015": {
          "6": {
              "fy": "2015",
              "fw": "2"
          },
          "7": {
              "fy": "2015",
              "fw": "3"
          },
          "8": {
              "fy": "2015",
              "fw": "4"
          },
          "9": {
              "fy": "2015",
              "fw": "5"
          },
          "10": {
              "fy": "2015",
              "fw": "6"
          },
          "11": {
              "fy": "2015",
              "fw": "7"
          },
          "12": {
              "fy": "2015",
              "fw": "8"
          },
          "13": {
              "fy": "2015",
              "fw": "9"
          },
          "14": {
              "fy": "2015",
              "fw": "10"
          },
          "15": {
              "fy": "2015",
              "fw": "11"
          },
          "16": {
              "fy": "2015",
              "fw": "12"
          },
          "17": {
              "fy": "2015",
              "fw": "13"
          },
          "18": {
              "fy": "2015",
              "fw": "14"
          },
          "19": {
              "fy": "2015",
              "fw": "15"
          },
          "20": {
              "fy": "2015",
              "fw": "16"
          },
          "21": {
              "fy": "2015",
              "fw": "17"
          },
          "22": {
              "fy": "2015",
              "fw": "18"
          },
          "23": {
              "fy": "2015",
              "fw": "19"
          },
          "24": {
              "fy": "2015",
              "fw": "20"
          },
          "25": {
              "fy": "2015",
              "fw": "21"
          },
          "26": {
              "fy": "2015",
              "fw": "22"
          },
          "27": {
              "fy": "2015",
              "fw": "23"
          },
          "28": {
              "fy": "2015",
              "fw": "24"
          },
          "29": {
              "fy": "2015",
              "fw": "25"
          },
          "30": {
              "fy": "2015",
              "fw": "26"
          },
          "31": {
              "fy": "2015",
              "fw": "27"
          },
          "32": {
              "fy": "2015",
              "fw": "28"
          },
          "33": {
              "fy": "2015",
              "fw": "29"
          },
          "34": {
              "fy": "2015",
              "fw": "30"
          },
          "35": {
              "fy": "2015",
              "fw": "31"
          },
          "36": {
              "fy": "2015",
              "fw": "32"
          },
          "37": {
              "fy": "2015",
              "fw": "33"
          },
          "38": {
              "fy": "2015",
              "fw": "34"
          },
          "39": {
              "fy": "2015",
              "fw": "35"
          },
          "40": {
              "fy": "2015",
              "fw": "36"
          },
          "41": {
              "fy": "2015",
              "fw": "37"
          },
          "42": {
              "fy": "2015",
              "fw": "38"
          },
          "43": {
              "fy": "2015",
              "fw": "39"
          },
          "44": {
              "fy": "2015",
              "fw": "40"
          },
          "45": {
              "fy": "2015",
              "fw": "41"
          },
          "46": {
              "fy": "2015",
              "fw": "42"
          },
          "47": {
              "fy": "2015",
              "fw": "43"
          },
          "48": {
              "fy": "2015",
              "fw": "44"
          },
          "49": {
              "fy": "2015",
              "fw": "45"
          },
          "50": {
              "fy": "2015",
              "fw": "46"
          },
          "51": {
              "fy": "2015",
              "fw": "47"
          },
          "52": {
              "fy": "2015",
              "fw": "48"
          },
          "53": {
              "fy": "2015",
              "fw": "49"
          }
      },
      "2016": {
          "1": {
              "fy": "2015",
              "fw": "50"
          },
          "2": {
              "fy": "2015",
              "fw": "51"
          },
          "3": {
              "fy": "2015",
              "fw": "52"
          },
          "4": {
              "fy": "2016",
              "fw": "1"
          },
          "5": {
              "fy": "2016",
              "fw": "2"
          },
          "6": {
              "fy": "2016",
              "fw": "3"
          },
          "7": {
              "fy": "2016",
              "fw": "4"
          },
          "8": {
              "fy": "2016",
              "fw": "5"
          },
          "9": {
              "fy": "2016",
              "fw": "6"
          },
          "10": {
              "fy": "2016",
              "fw": "7"
          },
          "11": {
              "fy": "2016",
              "fw": "8"
          },
          "12": {
              "fy": "2016",
              "fw": "9"
          },
          "13": {
              "fy": "2016",
              "fw": "10"
          },
          "14": {
              "fy": "2016",
              "fw": "11"
          },
          "15": {
              "fy": "2016",
              "fw": "12"
          },
          "16": {
              "fy": "2016",
              "fw": "13"
          },
          "17": {
              "fy": "2016",
              "fw": "14"
          },
          "18": {
              "fy": "2016",
              "fw": "15"
          },
          "19": {
              "fy": "2016",
              "fw": "16"
          },
          "20": {
              "fy": "2016",
              "fw": "17"
          },
          "21": {
              "fy": "2016",
              "fw": "18"
          },
          "22": {
              "fy": "2016",
              "fw": "19"
          },
          "23": {
              "fy": "2016",
              "fw": "20"
          },
          "24": {
              "fy": "2016",
              "fw": "21"
          },
          "25": {
              "fy": "2016",
              "fw": "22"
          },
          "26": {
              "fy": "2016",
              "fw": "23"
          },
          "27": {
              "fy": "2016",
              "fw": "24"
          },
          "28": {
              "fy": "2016",
              "fw": "25"
          },
          "29": {
              "fy": "2016",
              "fw": "26"
          },
          "30": {
              "fy": "2016",
              "fw": "27"
          },
          "31": {
              "fy": "2016",
              "fw": "28"
          },
          "32": {
              "fy": "2016",
              "fw": "29"
          },
          "33": {
              "fy": "2016",
              "fw": "30"
          },
          "34": {
              "fy": "2016",
              "fw": "31"
          },
          "35": {
              "fy": "2016",
              "fw": "32"
          },
          "36": {
              "fy": "2016",
              "fw": "33"
          },
          "37": {
              "fy": "2016",
              "fw": "34"
          },
          "38": {
              "fy": "2016",
              "fw": "35"
          },
          "39": {
              "fy": "2016",
              "fw": "36"
          },
          "40": {
              "fy": "2016",
              "fw": "37"
          },
          "41": {
              "fy": "2016",
              "fw": "38"
          },
          "42": {
              "fy": "2016",
              "fw": "39"
          },
          "43": {
              "fy": "2016",
              "fw": "40"
          },
          "44": {
              "fy": "2016",
              "fw": "41"
          },
          "45": {
              "fy": "2016",
              "fw": "42"
          },
          "46": {
              "fy": "2016",
              "fw": "43"
          },
          "47": {
              "fy": "2016",
              "fw": "44"
          },
          "48": {
              "fy": "2016",
              "fw": "45"
          },
          "49": {
              "fy": "2016",
              "fw": "46"
          },
          "50": {
              "fy": "2016",
              "fw": "47"
          },
          "51": {
              "fy": "2016",
              "fw": "48"
          },
          "52": {
              "fy": "2016",
              "fw": "49"
          }
      },
      "2017": {
          "1": {
              "fy": "2016",
              "fw": "50"
          },
          "2": {
              "fy": "2016",
              "fw": "51"
          },
          "3": {
              "fy": "2016",
              "fw": "52"
          },
          "4": {
              "fy": "2017",
              "fw": "1"
          },
          "5": {
              "fy": "2017",
              "fw": "2"
          },
          "6": {
              "fy": "2017",
              "fw": "3"
          },
          "7": {
              "fy": "2017",
              "fw": "4"
          },
          "8": {
              "fy": "2017",
              "fw": "5"
          },
          "9": {
              "fy": "2017",
              "fw": "6"
          },
          "10": {
              "fy": "2017",
              "fw": "7"
          },
          "11": {
              "fy": "2017",
              "fw": "8"
          },
          "12": {
              "fy": "2017",
              "fw": "9"
          },
          "13": {
              "fy": "2017",
              "fw": "10"
          },
          "14": {
              "fy": "2017",
              "fw": "11"
          },
          "15": {
              "fy": "2017",
              "fw": "12"
          },
          "16": {
              "fy": "2017",
              "fw": "13"
          },
          "17": {
              "fy": "2017",
              "fw": "14"
          },
          "18": {
              "fy": "2017",
              "fw": "15"
          },
          "19": {
              "fy": "2017",
              "fw": "16"
          },
          "20": {
              "fy": "2017",
              "fw": "17"
          },
          "21": {
              "fy": "2017",
              "fw": "18"
          },
          "22": {
              "fy": "2017",
              "fw": "19"
          },
          "23": {
              "fy": "2017",
              "fw": "20"
          },
          "24": {
              "fy": "2017",
              "fw": "21"
          },
          "25": {
              "fy": "2017",
              "fw": "22"
          },
          "26": {
              "fy": "2017",
              "fw": "23"
          },
          "27": {
              "fy": "2017",
              "fw": "24"
          },
          "28": {
              "fy": "2017",
              "fw": "25"
          },
          "29": {
              "fy": "2017",
              "fw": "26"
          },
          "30": {
              "fy": "2017",
              "fw": "27"
          },
          "31": {
              "fy": "2017",
              "fw": "28"
          },
          "32": {
              "fy": "2017",
              "fw": "29"
          },
          "33": {
              "fy": "2017",
              "fw": "30"
          },
          "34": {
              "fy": "2017",
              "fw": "31"
          },
          "35": {
              "fy": "2017",
              "fw": "32"
          },
          "36": {
              "fy": "2017",
              "fw": "33"
          },
          "37": {
              "fy": "2017",
              "fw": "34"
          },
          "38": {
              "fy": "2017",
              "fw": "35"
          },
          "39": {
              "fy": "2017",
              "fw": "36"
          },
          "40": {
              "fy": "2017",
              "fw": "37"
          },
          "41": {
              "fy": "2017",
              "fw": "38"
          },
          "42": {
              "fy": "2017",
              "fw": "39"
          },
          "43": {
              "fy": "2017",
              "fw": "40"
          },
          "44": {
              "fy": "2017",
              "fw": "41"
          },
          "45": {
              "fy": "2017",
              "fw": "42"
          },
          "46": {
              "fy": "2017",
              "fw": "43"
          },
          "47": {
              "fy": "2017",
              "fw": "44"
          },
          "48": {
              "fy": "2017",
              "fw": "45"
          },
          "49": {
              "fy": "2017",
              "fw": "46"
          },
          "50": {
              "fy": "2017",
              "fw": "47"
          },
          "51": {
              "fy": "2017",
              "fw": "48"
          },
          "52": {
              "fy": "2017",
              "fw": "49"
          }
      },
      "2018": {
          "1": {
              "fy": "2017",
              "fw": "50"
          },
          "2": {
              "fy": "2017",
              "fw": "51"
          },
          "3": {
              "fy": "2017",
              "fw": "52"
          },
          "4": {
              "fy": "2017",
              "fw": "53"
          },
          "5": {
              "fy": "2018",
              "fw": "1"
          },
          "6": {
              "fy": "2018",
              "fw": "2"
          },
          "7": {
              "fy": "2018",
              "fw": "3"
          },
          "8": {
              "fy": "2018",
              "fw": "4"
          },
          "9": {
              "fy": "2018",
              "fw": "5"
          },
          "10": {
              "fy": "2018",
              "fw": "6"
          },
          "11": {
              "fy": "2018",
              "fw": "7"
          },
          "12": {
              "fy": "2018",
              "fw": "8"
          },
          "13": {
              "fy": "2018",
              "fw": "9"
          },
          "14": {
              "fy": "2018",
              "fw": "10"
          },
          "15": {
              "fy": "2018",
              "fw": "11"
          },
          "16": {
              "fy": "2018",
              "fw": "12"
          },
          "17": {
              "fy": "2018",
              "fw": "13"
          },
          "18": {
              "fy": "2018",
              "fw": "14"
          },
          "19": {
              "fy": "2018",
              "fw": "15"
          },
          "20": {
              "fy": "2018",
              "fw": "16"
          },
          "21": {
              "fy": "2018",
              "fw": "17"
          },
          "22": {
              "fy": "2018",
              "fw": "18"
          },
          "23": {
              "fy": "2018",
              "fw": "19"
          },
          "24": {
              "fy": "2018",
              "fw": "20"
          },
          "25": {
              "fy": "2018",
              "fw": "21"
          },
          "26": {
              "fy": "2018",
              "fw": "22"
          },
          "27": {
              "fy": "2018",
              "fw": "23"
          },
          "28": {
              "fy": "2018",
              "fw": "24"
          },
          "29": {
              "fy": "2018",
              "fw": "25"
          },
          "30": {
              "fy": "2018",
              "fw": "26"
          },
          "31": {
              "fy": "2018",
              "fw": "27"
          },
          "32": {
              "fy": "2018",
              "fw": "28"
          },
          "33": {
              "fy": "2018",
              "fw": "29"
          },
          "34": {
              "fy": "2018",
              "fw": "30"
          },
          "35": {
              "fy": "2018",
              "fw": "31"
          },
          "36": {
              "fy": "2018",
              "fw": "32"
          },
          "37": {
              "fy": "2018",
              "fw": "33"
          },
          "38": {
              "fy": "2018",
              "fw": "34"
          },
          "39": {
              "fy": "2018",
              "fw": "35"
          },
          "40": {
              "fy": "2018",
              "fw": "36"
          },
          "41": {
              "fy": "2018",
              "fw": "37"
          },
          "42": {
              "fy": "2018",
              "fw": "38"
          },
          "43": {
              "fy": "2018",
              "fw": "39"
          },
          "44": {
              "fy": "2018",
              "fw": "40"
          },
          "45": {
              "fy": "2018",
              "fw": "41"
          },
          "46": {
              "fy": "2018",
              "fw": "42"
          },
          "47": {
              "fy": "2018",
              "fw": "43"
          },
          "48": {
              "fy": "2018",
              "fw": "44"
          },
          "49": {
              "fy": "2018",
              "fw": "45"
          },
          "50": {
              "fy": "2018",
              "fw": "46"
          },
          "51": {
              "fy": "2018",
              "fw": "47"
          },
          "52": {
              "fy": "2018",
              "fw": "48"
          }
      },
      "2019": {
          "1": {
              "fy": "2018",
              "fw": "49"
          },
          "2": {
              "fy": "2018",
              "fw": "50"
          },
          "3": {
              "fy": "2018",
              "fw": "51"
          },
          "4": {
              "fy": "2018",
              "fw": "52"
          },
          "5": {
              "fy": "2019",
              "fw": "1"
          },
          "6": {
              "fy": "2019",
              "fw": "2"
          },
          "7": {
              "fy": "2019",
              "fw": "3"
          },
          "8": {
              "fy": "2019",
              "fw": "4"
          },
          "9": {
              "fy": "2019",
              "fw": "5"
          },
          "10": {
              "fy": "2019",
              "fw": "6"
          },
          "11": {
              "fy": "2019",
              "fw": "7"
          },
          "12": {
              "fy": "2019",
              "fw": "8"
          },
          "13": {
              "fy": "2019",
              "fw": "9"
          },
          "14": {
              "fy": "2019",
              "fw": "10"
          },
          "15": {
              "fy": "2019",
              "fw": "11"
          },
          "16": {
              "fy": "2019",
              "fw": "12"
          },
          "17": {
              "fy": "2019",
              "fw": "13"
          },
          "18": {
              "fy": "2019",
              "fw": "14"
          },
          "19": {
              "fy": "2019",
              "fw": "15"
          },
          "20": {
              "fy": "2019",
              "fw": "16"
          },
          "21": {
              "fy": "2019",
              "fw": "17"
          },
          "22": {
              "fy": "2019",
              "fw": "18"
          },
          "23": {
              "fy": "2019",
              "fw": "19"
          },
          "24": {
              "fy": "2019",
              "fw": "20"
          },
          "25": {
              "fy": "2019",
              "fw": "21"
          },
          "26": {
              "fy": "2019",
              "fw": "22"
          },
          "27": {
              "fy": "2019",
              "fw": "23"
          },
          "28": {
              "fy": "2019",
              "fw": "24"
          },
          "29": {
              "fy": "2019",
              "fw": "25"
          },
          "30": {
              "fy": "2019",
              "fw": "26"
          },
          "31": {
              "fy": "2019",
              "fw": "27"
          },
          "32": {
              "fy": "2019",
              "fw": "28"
          },
          "33": {
              "fy": "2019",
              "fw": "29"
          },
          "34": {
              "fy": "2019",
              "fw": "30"
          },
          "35": {
              "fy": "2019",
              "fw": "31"
          },
          "36": {
              "fy": "2019",
              "fw": "32"
          },
          "37": {
              "fy": "2019",
              "fw": "33"
          },
          "38": {
              "fy": "2019",
              "fw": "34"
          },
          "39": {
              "fy": "2019",
              "fw": "35"
          },
          "40": {
              "fy": "2019",
              "fw": "36"
          },
          "41": {
              "fy": "2019",
              "fw": "37"
          },
          "42": {
              "fy": "2019",
              "fw": "38"
          },
          "43": {
              "fy": "2019",
              "fw": "39"
          },
          "44": {
              "fy": "2019",
              "fw": "40"
          },
          "45": {
              "fy": "2019",
              "fw": "41"
          },
          "46": {
              "fy": "2019",
              "fw": "42"
          },
          "47": {
              "fy": "2019",
              "fw": "43"
          },
          "48": {
              "fy": "2019",
              "fw": "44"
          },
          "49": {
              "fy": "2019",
              "fw": "45"
          },
          "50": {
              "fy": "2019",
              "fw": "46"
          },
          "51": {
              "fy": "2019",
              "fw": "47"
          },
          "52": {
              "fy": "2019",
              "fw": "48"
          }
      },
      "2020": {
          "1": {
              "fy": "2019",
              "fw": "49"
          },
          "2": {
              "fy": "2019",
              "fw": "50"
          },
          "3": {
              "fy": "2019",
              "fw": "51"
          },
          "4": {
              "fy": "2019",
              "fw": "52"
          },
          "5": {
              "fy": "2020",
              "fw": "1"
          },
          "6": {
              "fy": "2020",
              "fw": "2"
          },
          "7": {
              "fy": "2020",
              "fw": "3"
          },
          "8": {
              "fy": "2020",
              "fw": "4"
          },
          "9": {
              "fy": "2020",
              "fw": "5"
          },
          "10": {
              "fy": "2020",
              "fw": "6"
          },
          "11": {
              "fy": "2020",
              "fw": "7"
          },
          "12": {
              "fy": "2020",
              "fw": "8"
          },
          "13": {
              "fy": "2020",
              "fw": "9"
          },
          "14": {
              "fy": "2020",
              "fw": "10"
          },
          "15": {
              "fy": "2020",
              "fw": "11"
          },
          "16": {
              "fy": "2020",
              "fw": "12"
          },
          "17": {
              "fy": "2020",
              "fw": "13"
          },
          "18": {
              "fy": "2020",
              "fw": "14"
          },
          "19": {
              "fy": "2020",
              "fw": "15"
          },
          "20": {
              "fy": "2020",
              "fw": "16"
          },
          "21": {
              "fy": "2020",
              "fw": "17"
          },
          "22": {
              "fy": "2020",
              "fw": "18"
          },
          "23": {
              "fy": "2020",
              "fw": "19"
          },
          "24": {
              "fy": "2020",
              "fw": "20"
          },
          "25": {
              "fy": "2020",
              "fw": "21"
          },
          "26": {
              "fy": "2020",
              "fw": "22"
          },
          "27": {
              "fy": "2020",
              "fw": "23"
          },
          "28": {
              "fy": "2020",
              "fw": "24"
          },
          "29": {
              "fy": "2020",
              "fw": "25"
          },
          "30": {
              "fy": "2020",
              "fw": "26"
          },
          "31": {
              "fy": "2020",
              "fw": "27"
          },
          "32": {
              "fy": "2020",
              "fw": "28"
          },
          "33": {
              "fy": "2020",
              "fw": "29"
          },
          "34": {
              "fy": "2020",
              "fw": "30"
          },
          "35": {
              "fy": "2020",
              "fw": "31"
          },
          "36": {
              "fy": "2020",
              "fw": "32"
          },
          "37": {
              "fy": "2020",
              "fw": "33"
          },
          "38": {
              "fy": "2020",
              "fw": "34"
          },
          "39": {
              "fy": "2020",
              "fw": "35"
          },
          "40": {
              "fy": "2020",
              "fw": "36"
          },
          "41": {
              "fy": "2020",
              "fw": "37"
          },
          "42": {
              "fy": "2020",
              "fw": "38"
          },
          "43": {
              "fy": "2020",
              "fw": "39"
          },
          "44": {
              "fy": "2020",
              "fw": "40"
          },
          "45": {
              "fy": "2020",
              "fw": "41"
          },
          "46": {
              "fy": "2020",
              "fw": "42"
          },
          "47": {
              "fy": "2020",
              "fw": "43"
          },
          "48": {
              "fy": "2020",
              "fw": "44"
          },
          "49": {
              "fy": "2020",
              "fw": "45"
          },
          "50": {
              "fy": "2020",
              "fw": "46"
          },
          "51": {
              "fy": "2020",
              "fw": "47"
          },
          "52": {
              "fy": "2020",
              "fw": "48"
          },
          "53": {
              "fy": "2020",
              "fw": "49"
          }
      },
      "2021": {
          "1": {
              "fy": "2020",
              "fw": "50"
          },
          "2": {
              "fy": "2020",
              "fw": "51"
          },
          "3": {
              "fy": "2020",
              "fw": "52"
          },
          "4": {
              "fy": "2021",
              "fw": "1"
          },
          "5": {
              "fy": "2021",
              "fw": "2"
          },
          "6": {
              "fy": "2021",
              "fw": "3"
          },
          "7": {
              "fy": "2021",
              "fw": "4"
          },
          "8": {
              "fy": "2021",
              "fw": "5"
          },
          "9": {
              "fy": "2021",
              "fw": "6"
          },
          "10": {
              "fy": "2021",
              "fw": "7"
          },
          "11": {
              "fy": "2021",
              "fw": "8"
          },
          "12": {
              "fy": "2021",
              "fw": "9"
          },
          "13": {
              "fy": "2021",
              "fw": "10"
          },
          "14": {
              "fy": "2021",
              "fw": "11"
          },
          "15": {
              "fy": "2021",
              "fw": "12"
          },
          "16": {
              "fy": "2021",
              "fw": "13"
          },
          "17": {
              "fy": "2021",
              "fw": "14"
          },
          "18": {
              "fy": "2021",
              "fw": "15"
          },
          "19": {
              "fy": "2021",
              "fw": "16"
          },
          "20": {
              "fy": "2021",
              "fw": "17"
          },
          "21": {
              "fy": "2021",
              "fw": "18"
          },
          "22": {
              "fy": "2021",
              "fw": "19"
          },
          "23": {
              "fy": "2021",
              "fw": "20"
          },
          "24": {
              "fy": "2021",
              "fw": "21"
          },
          "25": {
              "fy": "2021",
              "fw": "22"
          },
          "26": {
              "fy": "2021",
              "fw": "23"
          },
          "27": {
              "fy": "2021",
              "fw": "24"
          },
          "28": {
              "fy": "2021",
              "fw": "25"
          },
          "29": {
              "fy": "2021",
              "fw": "26"
          },
          "30": {
              "fy": "2021",
              "fw": "27"
          },
          "31": {
              "fy": "2021",
              "fw": "28"
          },
          "32": {
              "fy": "2021",
              "fw": "29"
          },
          "33": {
              "fy": "2021",
              "fw": "30"
          },
          "34": {
              "fy": "2021",
              "fw": "31"
          },
          "35": {
              "fy": "2021",
              "fw": "32"
          },
          "36": {
              "fy": "2021",
              "fw": "33"
          },
          "37": {
              "fy": "2021",
              "fw": "34"
          },
          "38": {
              "fy": "2021",
              "fw": "35"
          },
          "39": {
              "fy": "2021",
              "fw": "36"
          },
          "40": {
              "fy": "2021",
              "fw": "37"
          },
          "41": {
              "fy": "2021",
              "fw": "38"
          },
          "42": {
              "fy": "2021",
              "fw": "39"
          },
          "43": {
              "fy": "2021",
              "fw": "40"
          },
          "44": {
              "fy": "2021",
              "fw": "41"
          },
          "45": {
              "fy": "2021",
              "fw": "42"
          },
          "46": {
              "fy": "2021",
              "fw": "43"
          },
          "47": {
              "fy": "2021",
              "fw": "44"
          },
          "48": {
              "fy": "2021",
              "fw": "45"
          },
          "49": {
              "fy": "2021",
              "fw": "46"
          },
          "50": {
              "fy": "2021",
              "fw": "47"
          },
          "51": {
              "fy": "2021",
              "fw": "48"
          },
          "52": {
              "fy": "2021",
              "fw": "49"
          }
      },
      "2022": {
          "1": {
              "fy": "2021",
              "fw": "50"
          },
          "2": {
              "fy": "2021",
              "fw": "51"
          },
          "3": {
              "fy": "2021",
              "fw": "52"
          },
          "4": {
              "fy": "2022",
              "fw": "1"
          },
          "5": {
              "fy": "2022",
              "fw": "2"
          },
          "6": {
              "fy": "2022",
              "fw": "3"
          },
          "7": {
              "fy": "2022",
              "fw": "4"
          },
          "8": {
              "fy": "2022",
              "fw": "5"
          },
          "9": {
              "fy": "2022",
              "fw": "6"
          },
          "10": {
              "fy": "2022",
              "fw": "7"
          },
          "11": {
              "fy": "2022",
              "fw": "8"
          },
          "12": {
              "fy": "2022",
              "fw": "9"
          },
          "13": {
              "fy": "2022",
              "fw": "10"
          },
          "14": {
              "fy": "2022",
              "fw": "11"
          },
          "15": {
              "fy": "2022",
              "fw": "12"
          },
          "16": {
              "fy": "2022",
              "fw": "13"
          },
          "17": {
              "fy": "2022",
              "fw": "14"
          },
          "18": {
              "fy": "2022",
              "fw": "15"
          },
          "19": {
              "fy": "2022",
              "fw": "16"
          },
          "20": {
              "fy": "2022",
              "fw": "17"
          },
          "21": {
              "fy": "2022",
              "fw": "18"
          },
          "22": {
              "fy": "2022",
              "fw": "19"
          },
          "23": {
              "fy": "2022",
              "fw": "20"
          },
          "24": {
              "fy": "2022",
              "fw": "21"
          },
          "25": {
              "fy": "2022",
              "fw": "22"
          },
          "26": {
              "fy": "2022",
              "fw": "23"
          },
          "27": {
              "fy": "2022",
              "fw": "24"
          },
          "28": {
              "fy": "2022",
              "fw": "25"
          },
          "29": {
              "fy": "2022",
              "fw": "26"
          },
          "30": {
              "fy": "2022",
              "fw": "27"
          },
          "31": {
              "fy": "2022",
              "fw": "28"
          },
          "32": {
              "fy": "2022",
              "fw": "29"
          },
          "33": {
              "fy": "2022",
              "fw": "30"
          },
          "34": {
              "fy": "2022",
              "fw": "31"
          },
          "35": {
              "fy": "2022",
              "fw": "32"
          },
          "36": {
              "fy": "2022",
              "fw": "33"
          },
          "37": {
              "fy": "2022",
              "fw": "34"
          },
          "38": {
              "fy": "2022",
              "fw": "35"
          },
          "39": {
              "fy": "2022",
              "fw": "36"
          },
          "40": {
              "fy": "2022",
              "fw": "37"
          },
          "41": {
              "fy": "2022",
              "fw": "38"
          },
          "42": {
              "fy": "2022",
              "fw": "39"
          },
          "43": {
              "fy": "2022",
              "fw": "40"
          },
          "44": {
              "fy": "2022",
              "fw": "41"
          },
          "45": {
              "fy": "2022",
              "fw": "42"
          },
          "46": {
              "fy": "2022",
              "fw": "43"
          },
          "47": {
              "fy": "2022",
              "fw": "44"
          },
          "48": {
              "fy": "2022",
              "fw": "45"
          },
          "49": {
              "fy": "2022",
              "fw": "46"
          },
          "50": {
              "fy": "2022",
              "fw": "47"
          },
          "51": {
              "fy": "2022",
              "fw": "48"
          },
          "52": {
              "fy": "2022",
              "fw": "49"
          }
      },
      "2023": {
          "1": {
              "fy": "2022",
              "fw": "50"
          },
          "2": {
              "fy": "2022",
              "fw": "51"
          },
          "3": {
              "fy": "2022",
              "fw": "52"
          },
          "4": {
              "fy": "2023",
              "fw": "1"
          },
          "5": {
              "fy": "2023",
              "fw": "2"
          },
          "6": {
              "fy": "2023",
              "fw": "3"
          },
          "7": {
              "fy": "2023",
              "fw": "4"
          },
          "8": {
              "fy": "2023",
              "fw": "5"
          },
          "9": {
              "fy": "2023",
              "fw": "6"
          },
          "10": {
              "fy": "2023",
              "fw": "7"
          },
          "11": {
              "fy": "2023",
              "fw": "8"
          },
          "12": {
              "fy": "2023",
              "fw": "9"
          },
          "13": {
              "fy": "2023",
              "fw": "10"
          },
          "14": {
              "fy": "2023",
              "fw": "11"
          },
          "15": {
              "fy": "2023",
              "fw": "12"
          },
          "16": {
              "fy": "2023",
              "fw": "13"
          },
          "17": {
              "fy": "2023",
              "fw": "14"
          },
          "18": {
              "fy": "2023",
              "fw": "15"
          },
          "19": {
              "fy": "2023",
              "fw": "16"
          },
          "20": {
              "fy": "2023",
              "fw": "17"
          },
          "21": {
              "fy": "2023",
              "fw": "18"
          },
          "22": {
              "fy": "2023",
              "fw": "19"
          },
          "23": {
              "fy": "2023",
              "fw": "20"
          },
          "24": {
              "fy": "2023",
              "fw": "21"
          },
          "25": {
              "fy": "2023",
              "fw": "22"
          },
          "26": {
              "fy": "2023",
              "fw": "23"
          },
          "27": {
              "fy": "2023",
              "fw": "24"
          },
          "28": {
              "fy": "2023",
              "fw": "25"
          },
          "29": {
              "fy": "2023",
              "fw": "26"
          },
          "30": {
              "fy": "2023",
              "fw": "27"
          },
          "31": {
              "fy": "2023",
              "fw": "28"
          },
          "32": {
              "fy": "2023",
              "fw": "29"
          },
          "33": {
              "fy": "2023",
              "fw": "30"
          },
          "34": {
              "fy": "2023",
              "fw": "31"
          },
          "35": {
              "fy": "2023",
              "fw": "32"
          },
          "36": {
              "fy": "2023",
              "fw": "33"
          },
          "37": {
              "fy": "2023",
              "fw": "34"
          },
          "38": {
              "fy": "2023",
              "fw": "35"
          },
          "39": {
              "fy": "2023",
              "fw": "36"
          },
          "40": {
              "fy": "2023",
              "fw": "37"
          },
          "41": {
              "fy": "2023",
              "fw": "38"
          },
          "42": {
              "fy": "2023",
              "fw": "39"
          },
          "43": {
              "fy": "2023",
              "fw": "40"
          },
          "44": {
              "fy": "2023",
              "fw": "41"
          },
          "45": {
              "fy": "2023",
              "fw": "42"
          },
          "46": {
              "fy": "2023",
              "fw": "43"
          },
          "47": {
              "fy": "2023",
              "fw": "44"
          },
          "48": {
              "fy": "2023",
              "fw": "45"
          },
          "49": {
              "fy": "2023",
              "fw": "46"
          },
          "50": {
              "fy": "2023",
              "fw": "47"
          },
          "51": {
              "fy": "2023",
              "fw": "48"
          },
          "52": {
              "fy": "2023",
              "fw": "49"
          }
      },
      "2024": {
          "1": {
              "fy": "2023",
              "fw": "50"
          },
          "2": {
              "fy": "2023",
              "fw": "51"
          },
          "3": {
              "fy": "2023",
              "fw": "52"
          },
          "4": {
              "fy": "2023",
              "fw": "53"
          }
      }
  }

  for (let i = startDate; i <= endDate; i = moment(i).add(1, 'week').format('YYYY-MM-DD')) {
    if (moment(i).isBefore(crrntDate, 'week')) {
      //current week belongs to future week so use isBefore
      let fiscalData = getFiscalWeekAndYear(moment(i).year(), moment(i).week(), fiscalCalDetails);
      calPastWeeks.push({
        weekNum: parseInt(fiscalData.week), //moment(i).week(),
        yearNum: parseInt(fiscalData.year), //moment(i).year(),
      });
    } else {
      let fiscalData = getFiscalWeekAndYear(moment(i).year(), moment(i).week(), fiscalCalDetails);

      calFutureWeeks.push({
        weekNum: parseInt(fiscalData.week), //moment(i).week(),
        yearNum: parseInt(fiscalData.year), //moment(i).year(),
      });
    }
  }
  return {
    calPastWeeks,
    calFutureWeeks,
  };
};

export const editPlanDateHandler = (startDate, endDate, daterangeOrWeek, fiscalCalDetails) => {
  let sDate = _.cloneDeep(startDate);
  let eDate = _.cloneDeep(endDate);
  let crrntDate = moment().format('YYYY-MM-DD');

  if (moment(sDate).isBefore(crrntDate, 'week')) {
    let startDay = moment(sDate).day();
    sDate = moment().day(startDay).format('YYYY-MM-DD');
  }
  let { calPastWeeks, calFutureWeeks } = pastWeekFutureWeekHandler(
    _.cloneDeep(startDate),
    _.cloneDeep(endDate),
    daterangeOrWeek,
    fiscalCalDetails
  );
  let { weekNumList, yearList } = getFiscalWeekArray(
    sDate,
    eDate,
    daterangeOrWeek,
    fiscalCalDetails
  );
  let { weekNumList: originalWeekList, yearList: originalYearList } = getFiscalWeekArray(
    startDate,
    endDate,
    daterangeOrWeek,
    fiscalCalDetails
  );

  return {
    startDate: sDate,
    endDate: eDate,
    originalSDate: _.cloneDeep(startDate),
    originalEDate: _.cloneDeep(endDate),
    originalWeekList,
    originalYearList,
    calPastWeeks,
    calFutureWeeks,
    weekNumList,
    yearList,
  };
};
