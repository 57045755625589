import axios from "axios";
import { ENDPOINT } from "../config/api/index";

export const getFilters = (type) => {
  return axios({
    url: `${ENDPOINT}/v2/core/filter-configuration/screen/${type}%20hierarchy`,
    method: "GET",
  });
};

export const setFilters = (type, filters) => (dispatch) => {
  dispatch({
    type: "SET_CASCADED_FILTERS",
    filters,
    item: type,
  });
};
export const resetFilters = (type, filters) => (dispatch) => {
  dispatch({
    type: "RESET_CASCADED_FILTERS",
    filters,
    item: type,
  });
};
export const getDropdowns = (type, postBody) => () => {
  if (postBody.attribute_name == "store_code") {
    return axios({
      url: `${ENDPOINT}/v2/core/main-filter/store`,
      method: "POST",
      data: {
        ...postBody,
        meta: {
          range: [],
          sort: [],
          search: [],
          limit: {
            limit: null,
            page: null,
          },
        },
        headers: ["string"],
      },
    });
  } else {
    return axios({
      url: `${ENDPOINT}/v2/core/attribute-filter/${type}`,
      method: "POST",
      data: postBody,
    });
  }
};

export const setDropdowns = (menus, item) => (dispatch) => {
  dispatch({
    type: "SET_DROPDOWNS",
    item,
    payload: menus,
  });
};
export const setDropdownsDownFiletrs = (menus, item) => (dispatch) => {
  dispatch({
    type: "SET_DROPDOWNS_DOWN_FILTERS",
    item,
    payload: menus,
  });
};
export const getChannels = (key) => (dispatch) => {
  return axios({
    url: `${ENDPOINT}/v2/monday_smart/dimension-attribute?dimension=${key}`,
    method: "GET",
  }).then((data) => {
    return data;
  });
};

export const setChannels = (channels) => (dispatch) => {
  dispatch({
    type: "SET_CHANNELS",
    channels,
  });
};

export const getLabels = (screen) => {
  return axios({
    url: `${ENDPOINT}/v2/monday_smart/filter-configuration-screen/${screen}`,
    method: "GET",
  });
};

export const setLabels = (labels) => (dispatch) => {
  dispatch({
    type: "SET_LABELS",
    labels,
  });
};

export const getYears = () => {
  return axios({
    url: `${ENDPOINT}/v2/monday_smart/timeline/years-list`,
    method: "GET",
  });
};

export const setYears = (years) => (dispatch) => {
  dispatch({
    type: "SET_YEARS",
    years,
  });
};

export const getStartEndTime = (selectedTimeline) => async (dispatch) => {
  return axios({
    url: `${ENDPOINT}/v2/monday_smart/timeline-attribute?value=${selectedTimeline}`,
    method: "GET",
  }).then((data) => {
    dispatch({
      type: "SET_YTD_START_DATE",
      payload: data.data.data.date_fw_details.start_date,
    });
    dispatch({
      type: "SET_YTD_END_DATE",
      payload: data.data.data.date_fw_details.end_date,
    });
    dispatch({
      type: "SET_YTD_START_DATE_FW",
      payload: data.data.data.date_fw_details.start_fw,
    });
    dispatch({
      type: "SET_YTD_END_DATE_FW",
      payload: data.data.data.date_fw_details.end_fw,
    });
    return data;
  });
};

export const getTimelineDates = (selectedTimeline) => async (dispatch) => {
  return axios({
    url: `${ENDPOINT}/v2/monday_smart/timeline-attribute?value=${selectedTimeline}`,
    method: "GET",
  }).then((data) => {
    dispatch({
      type: "SET_SELECTED_TIMELINE_START_DATE",
      payload: data.data.data.date_fw_details.start_date,
    });
    dispatch({
      type: "SET_SELECTED_TIMELINE_END_DATE",
      payload: data.data.data.date_fw_details.end_date,
    });
    dispatch({
      type: "SET_SELECTED_TIMELINE_START_FISCAL_WEEK",
      payload: data.data.data.date_fw_details.start_fw,
    });
    dispatch({
      type: "SET_SELECTED_TIMELINE_END_FISCAL_WEEK",
      payload: data.data.data.date_fw_details.end_fw,
    });
    return data;
  });
};

export const getTimelineDatesCustom = (
  selectedStartDate,
  selectedEndDate
) => async (dispatch) => {
  return axios({
    url: `${ENDPOINT}/v2/monday_smart/timeline/date-range?start_date=${selectedStartDate}&end_date=${selectedEndDate}`,
    method: "GET",
  }).then((data) => {
    dispatch({
      type: "SET_SELECTED_TIMELINE_START_DATE",
      payload: data.data.data.date_fw_details.start_date,
    });
    dispatch({
      type: "SET_SELECTED_TIMELINE_END_DATE",
      payload: data.data.data.date_fw_details.end_date,
    });
    dispatch({
      type: "SET_SELECTED_TIMELINE_START_FISCAL_WEEK",
      payload: data.data.data.date_fw_details.start_fw,
    });
    dispatch({
      type: "SET_SELECTED_TIMELINE_END_FISCAL_WEEK",
      payload: data.data.data.date_fw_details.end_fw,
    });
    return data;
  });
};

export const getComapredToYears = (
  selectedYear,
  selectedStartFw,
  selectedEndFw
) => async (dispatch) => {
  return axios({
    url: `${ENDPOINT}/v2/monday_smart/timeline/comparison-data`,
    method: "Post",
    data: {
      year: selectedYear,
      start_fw: selectedStartFw,
      end_fw: selectedEndFw,
      complete_year: false,
    },
  }).then((data) => {
    dispatch({
      type: "SET_SELECTED_COMPARED_START_DATE",
      payload: data.data.data.date_fw_details.start_date,
    });
    dispatch({
      type: "SET_SELECTED_COMPARED_END_DATE",
      payload: data.data.data.date_fw_details.end_date,
    });
    dispatch({
      type: "SET_SELECTED_COMPARED_START_FISCAL_WEEK",
      payload: data.data.data.date_fw_details.start_fw,
    });
    dispatch({
      type: "SET_SELECTED_COMPARED_END_FISCAL_WEEK",
      payload: data.data.data.date_fw_details.end_fw,
    });
    return data;
  });
};

export const getCustomCompare = (start_fw, end_fw) => async (dispatch) => {
  const postBody = {
    start_fw,
    end_fw,
  };
  return axios({
    url: `${ENDPOINT}/v2/monday_smart/timeline/comparison-year-data`,
    method: "POST",
    data: postBody,
  }).then((data) => {
    return data;
  });
};

export const getLineComapredToYears = (
  selectedYear,
  selectedStartFw,
  selectedEndFw
) => async (dispatch) => {
  const postBody = {
    year: selectedYear,
    start_fw: null,
    end_fw: null,
    complete_year: true,
  };
  return axios({
    url: `${ENDPOINT}/v2/monday_smart/timeline/comparison-data`,
    method: "POST",
    data: postBody,
  }).then((data) => {
    dispatch({
      type: "SET_SELECTED_COMPARED_LINE_START_DATE",
      payload: data.data.data.date_fw_details.start_date,
    });
    dispatch({
      type: "SET_SELECTED_COMPARED_LINE_END_DATE",
      payload: data.data.data.date_fw_details.end_date,
    });
    dispatch({
      type: "SET_SELECTED_COMPARED_LINE_START_FISCAL_WEEK",
      payload: data.data.data.date_fw_details.start_fw,
    });
    dispatch({
      type: "SET_SELECTED_COMPARED_LINE_END_FISCAL_WEEK",
      payload: data.data.data.date_fw_details.end_fw,
    });
    return data;
  });
};

export const getHeatMapData = (filterBody, level, label) => async (
  dispatch
) => {
  return axios({
    url: `${ENDPOINT}/v2/monday_smart/kpi-data`,
    method: "Post",
    data: filterBody,
  }).then((data) => {
    const dH = data.data.data;

    const heatMapDivisions = [];
    const heatMapRegions = [];
    const heatMapKpis = [];
    const newKpis = [];
    const keys = Object.keys(data.data.data);
    // const dHMatrixObj = {}
    keys.forEach((thisKey, index) => {
      if (index === 0) {
        const Kpis = Object.keys(dH[thisKey].timeline);
        Kpis.forEach((thisKpi) => {
          if (!(thisKpi === level || thisKpi === "region")) {
            heatMapKpis.push(thisKpi);
            newKpis.push(label[thisKpi]);
          }
        });
      }
      if (!heatMapDivisions.includes(dH[thisKey][level])) {
        heatMapDivisions.push(dH[thisKey][level]);
      }
      if (!heatMapRegions.includes(dH[thisKey].region)) {
        heatMapRegions.push(dH[thisKey].region);
      }
    });
    const indexOfSales = newKpis.indexOf("Revenue");
    newKpis[indexOfSales] = newKpis[0];
    newKpis[0] = "Revenue";
    const allHD = {};
    var regionSalesData = {};

    heatMapKpis.forEach((kp) => {
      const dHMatrixObj = {};
      const regionSalesMatrix = {};
      keys.forEach((thisKey, index) => {
        regionSalesMatrix[dH[thisKey].region] = {
          ...regionSalesMatrix[dH[thisKey].region],
          [dH[thisKey][level]]:
            dH[thisKey].timeline[kp] != null ? dH[thisKey].timeline[kp] : 0,
        };

        dHMatrixObj[dH[thisKey].region] = {
          ...dHMatrixObj[dH[thisKey].region],
          [dH[thisKey][level]]:
            dH[thisKey].timeline[kp] != null
              ? dH[thisKey].timeline[kp] - dH[thisKey].compare_timeline[kp]
              : 0,
        };
        allHD[kp] = { ...allHD[kp], ...dHMatrixObj };
        regionSalesData[kp] = { ...regionSalesData[kp], ...regionSalesMatrix };
      });
    });
    var sortedRegions = {};
    var sortedDivision = {};
    dH.forEach((thisObj) => {
      sortedDivision[thisObj["l0_name"]] = 0;
    });
    dH.forEach((thisObj) => {
      sortedDivision[thisObj["l0_name"]] += thisObj.timeline.sales;
    });

    const d = heatMapDivisions.sort(function (a, b) {
      return sortedDivision[b] - sortedDivision[a];
    });

    if (Object.keys(regionSalesData).length) {
      Object.keys(regionSalesData["sales"]).forEach((thisRegion) => {
        var sum = 0;
        Object.keys(regionSalesData["sales"][thisRegion]).forEach((val) => {
          sum += regionSalesData["sales"][thisRegion][val];
        });
        sortedRegions[thisRegion] = sum;
      });
    }

    const s = heatMapRegions.sort(function (a, b) {
      return sortedRegions[a] - sortedRegions[b];
    });

    const div = level === "l0_name" ? "heatMapDivisions" : "heatMapDepartments";
    dispatch({
      type: "ALL_HD_DATA",
      payload: {
        allHD,
        regionSalesData,
        heatMapLevel: level,
        heatMapData: data.data.data,
        heatMapRegions: s,
        [div]: d,
        heatMapKpis: newKpis,
      },
    });
  });
};

export const getKpiData = (
  filterBody,
  allKpi,
  top_kpi,
  allKpiLabelMapper,
  invKpiValue
) => async (dispatch) => {
  return axios({
    url: `${ENDPOINT}/v2/monday_smart/kpi-data`,
    method: "Post",
    data: filterBody,
  }).then((data) => {
    var thisKpiData = {};
    var events = {};
    top_kpi.forEach((kpi) => {
      thisKpiData[kpi] = {};
      thisKpiData[kpi]["tData"] = {};
      thisKpiData[kpi]["cData"] = {};

      data.data.data.forEach((thisObj) => {
        if (thisObj.timeline.week_start_date) {
          thisKpiData[kpi]["tData"][thisObj.timeline.week_start_date] = [];
          thisKpiData[kpi]["cData"][thisObj.timeline.week_start_date] = [];
          events[thisObj.timeline.week_start_date] = [];
        }
      });

      data.data.data.forEach((thisObj) => {
        if (thisObj.timeline.week_start_date) {
          if (
            events[thisObj.timeline.week_start_date].length == 0 &&
            thisObj.timeline.events.length > 0
          ) {
            events[thisObj.timeline.week_start_date] = [
              ...events[thisObj.timeline.week_start_date],
              ...thisObj.timeline.events,
            ];
          }
          thisKpiData[kpi]["tData"][thisObj.timeline.week_start_date].push(
            thisObj.timeline[kpi]
          );
          thisKpiData[kpi]["cData"][thisObj.timeline.week_start_date].push(
            thisObj.compare_timeline[kpi]
          );
        }
      });
    });
    const plotLines = [];
    const times = Object.keys(events);
    times.forEach((thisWeek) => {
      if (events[thisWeek].length > 0) {
        plotLines.push({
          label: {
            text: events[thisWeek][0].name,
            style: {
              color: "gray",
            },
          },
          color: "gray", // Red
          dashStyle: "dash",
          width: 1,
          value: thisWeek,
          zIndex: 1,
        });
      }
    });
    dispatch({
      type: "SET_EVENTS",
      events: plotLines,
    });

    dispatch({
      type: "SET_LINE_DATA",
      lineData: thisKpiData,
    });

    dispatch({
      type: "SET_KPI_DATA",
      data: data.data.data,
    });
    dispatch({
      type: "SET_LINECHART_LOADER",
      value: false,
    });
  });
};

export const getTilesValue = (
  filterBody,
  allKpi,
  top_kpi,
  allKpiLabelMapper,
  invKpiValue
) => async (dispatch) => {
  return axios({
    url: `${ENDPOINT}/v2/monday_smart/kpi-data`,
    method: "Post",
    data: filterBody,
  }).then((data) => {
    const kpiCardDataTimeline = {};
    const kpiCardDataCompare = {};
    const Kpi_Values = {};
    allKpi.forEach((kpi) => {
      kpiCardDataTimeline[allKpiLabelMapper[kpi]] =
        data.data.data[0].timeline[kpi];
      kpiCardDataCompare[allKpiLabelMapper[kpi]] =
        data.data.data[0].compare_timeline[kpi];
      Kpi_Values[allKpiLabelMapper[kpi]] = {
        name: allKpiLabelMapper[kpi],
        sumTimeline: data.data.data[0].timeline[kpi],
        sumCompare: data.data.data[0].compare_timeline[kpi],
      };
    });
    kpiCardDataTimeline[allKpiLabelMapper["inv_eop"]] = invKpiValue["timeline"];
    kpiCardDataCompare[allKpiLabelMapper["inv_eop"]] =
      invKpiValue["compare_timeline"];
    Kpi_Values[allKpiLabelMapper["inv_eop"]] = {
      name: allKpiLabelMapper["inv_eop"],
      sumTimeline: invKpiValue["timeline"],
      sumCompare: invKpiValue["compare_timeline"],
    };
    dispatch({
      type: "SET_KPI_LIST_VALUES_TIMELINE",
      valueKpiCard: kpiCardDataTimeline,
    });

    dispatch({
      type: "SET_KPI_LIST_VALUES_COMPARE",
      valueKpiCard: kpiCardDataCompare,
    });
    dispatch({
      type: "SET_KPI_VALUES",
      kpi_Values: Kpi_Values,
    });
  });
};

export const getKpiDataBarGraphRegion = (filterBody) => async (dispatch) => {
  return axios({
    url: `${ENDPOINT}/v2/monday_smart/kpi-data`,
    method: "Post",
    data: filterBody,
  }).then((data) => {
    dispatch({
      type: "SET_BAR_GRAPH_DATA_REGION",
      payload: data.data.data,
    });
  });
};

export const getKpiDataBarGraphCategory = (filterBody) => async (dispatch) => {
  return axios({
    url: `${ENDPOINT}/v2/monday_smart/kpi-data`,
    method: "Post",
    data: filterBody,
  }).then((data) => {
    dispatch({
      type: "SET_BAR_GRAPH_DATA_CATEGORY",
      payload: data.data.data,
    });
  });
};

export const getKpiDataBarGraphDeepDive = (filterBody) => async (dispatch) => {
  return axios({
    url: `${ENDPOINT}/v2/monday_smart/kpi-data`,
    method: "Post",
    data: filterBody,
  }).then((data) => {
    dispatch({
      type: "SET_BAR_GRAPH_DATA_DEEP_DIVE",
      payload: data.data.data,
    });
    return data;
  });
};

export const getKpiDataScatterGraphDeepDive = (filterBody) => async (
  dispatch
) => {
  return axios({
    url: `${ENDPOINT}/v2/monday_smart/kpi-data`,
    method: "Post",
    data: filterBody,
  }).then((data) => {
    dispatch({
      type: "SET_SCATTER_GRAPH_DATA_DEEP_DIVE",
      payload: data.data.data,
    });
    return data;
  });
};

export const getKpiList = (kpisToShow) => async (dispatch) => {
  return axios({
    url: `${ENDPOINT}/v2/monday_smart/kpi-list`,
    method: "GET",
  }).then((data) => {
    let allKpiLabelMapper = {};
    const Kpi_items = {};
    const d = data.data.data;
    // const kt = Object.keys(dt)
    // const d = {}
    // kt.forEach((cat)=>{
    //   if(cat == 'Base_KPIs'){
    //     d['Base KPI'] = dt[cat]
    //   }
    //   if(cat == 'Promo_KPIs'){
    //     d['Promo KPI'] = dt[cat]
    //   }
    //   if(cat == 'Discount_KPIs'){
    //     d['Discount KPI'] = dt[cat]
    //   }
    //   if(cat == 'Discount_KPIs'){
    //     d['Discount KPI'] = dt[cat]
    //   }
    // })
    const categories = Object.keys(d);

    var kL = [];
    var kLOrder = {};
    const dkeys = Object.keys(d);
    var newKeys = [];

    for (let i = 0; i < dkeys.length; i++) {
      // Over 3 categories
      const keys = Object.keys(d[dkeys[i]]);
      keys.forEach((thisKpi) => {
        newKeys = [...newKeys, d[dkeys[i]][thisKpi].display_name];
        allKpiLabelMapper[thisKpi] = d[dkeys[i]][thisKpi].display_name;
        allKpiLabelMapper[d[dkeys[i]][thisKpi].display_name] = thisKpi;
      });
    }

    for (let i = 0; i < dkeys.length; i++) {
      // Over 3 categories
      const keys = Object.keys(d[dkeys[i]]);
      const newKeys = keys.map((ele) => {
        return allKpiLabelMapper[ele];
      });

      kLOrder = { ...kLOrder, ...d[dkeys[i]] };
      Kpi_items[dkeys[i]] = newKeys;
      kL = [...kL, ...keys];
    }
    const newKlOrder = {};
    const k = Object.keys(kLOrder);
    k.forEach((kk) => {
      newKlOrder[allKpiLabelMapper[kk]] = kLOrder[kk];
    });

    const keysSorted = Object.keys(newKlOrder).sort(function (a, b) {
      return newKlOrder[a].sort_order - newKlOrder[b].sort_order;
    });

    dispatch({
      type: "SET_KPI_LIST_DATA",
      kpiListData: data,
    });
    dispatch({
      type: "SET_KPI_CATEGORIES",
      kpiCategories: categories,
    });
    dispatch({
      type: "SET_KPI_ITEMS",
      kpiItems: Kpi_items,
    });
    dispatch({
      type: "SET_KPI_LIST",
      kpiList: kL,
    });
    dispatch({
      type: "SET_KPI_COMPONENT_LIST",
      kpiCompList: kL.map((ele) => allKpiLabelMapper[ele]),
    });
    dispatch({
      type: "SET_KPI_LABEL_MAPPER",
      allKpiLabelMapper: allKpiLabelMapper,
    });
    dispatch({
      type: "SET_SELECTED_KPI_LIST",
      list: kL,
    });
    return data;
  });
};

export const getMapData = (filterBody) => async (dispatch) => {
  return axios({
    url: `${ENDPOINT}/v2/monday_smart/kpi-data`,
    method: "Post",
    data: filterBody,
  }).then((data) => {
    dispatch({
      type: "SET_MAP_DATA_DEEPDIVE",
      storeData: data.data.data,
    });
    return data;
  });
};
export const getKpiDataInventoryStoreCard = (filterBody) => async (
  dispatch
) => {
  return axios({
    url: `${ENDPOINT}/v2/monday_smart/kpi-data`,
    method: "Post",
    data: filterBody,
  });
};

export const getInventoryBarLineData = (filterBody) => async (dispatch) => {
  return axios({
    url: `${ENDPOINT}/v2/monday_smart/kpi-data`,
    method: "Post",
    data: filterBody,
  });
};

export const getInventoryHistoryAreaData = (filterBody) => async (dispatch) => {
  return axios({
    url: `${ENDPOINT}/v2/monday_smart/kpi-data`,
    method: "Post",
    data: filterBody,
  });
};

export const getDCInventoryBarData = (filterBody) => async (dispatch) => {
  return axios({
    url: `${ENDPOINT}/v2/monday_smart/kpi-data`,
    method: "Post",
    data: filterBody,
  });
};

export const getDCHistoryAreaData = (filterBody) => async (dispatch) => {
  return axios({
    url: `${ENDPOINT}/v2/monday_smart/kpi-data`,
    method: "Post",
    data: filterBody,
  });
};

export const getKpiDataDCStoreCard = (filterBody) => async (dispatch) => {
  return axios({
    url: `${ENDPOINT}/v2/monday_smart/kpi-data`,
    method: "Post",
    data: filterBody,
  });
};

export const getInvKpiValue = (filterBody) => async (dispatch) => {
  return axios({
    url: `${ENDPOINT}/v2/monday_smart/kpi-data`,
    method: "Post",
    data: filterBody,
  }).then((data) => {
    var invVal = {
      timeline: 0,
      compare_timeline: 0,
    };
    if (data.data.data.length > 0) {
      invVal = {
        timeline: data.data.data[0].timeline.inv_eop,
        compare_timeline: data.data.data[0].compare_timeline.inv_eop,
      };
    }
    dispatch({
      type: "SET_INV_KPI_VAL",
      value: invVal,
    });
    return invVal;
  });
};

export const setFilterChanged = (value) => (dispatch) => {
  dispatch({
    type: "SET_HAS_FILTER_CHANGED",
    filterChanged:value

  });
};

export const setCallHeatMapApply = (value) => (dispatch) => {
  dispatch({
    type: "SET_CALL_HEATMAP_APPLY",
    callApplyHeatmap:value
  });
};
