import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../../Utils/axios";
import {
  GET_PLAN_DETAILS,
  GET_CREATED_PLANS,
  DELETE_PLAN,
  CREATE_PLAN,
  GET_PLAN_LEVELS,
} from "modules/assortsmart/constants-assortsmart/apiConstants";
export const dashboardService = createSlice({
  name: "dashboardService",
  initialState: {
    planDetails: [],
    dashboard_loader: false,
    plansTableData: [],
    plansTotalCount: 0,
    plansTableCols: [],
  },
  reducers: {
    setPlanDetails: (state, action) => {
      state.planDetails = action.payload;
    },
    setLoader: (state, action) => {
      state.dashboard_loader = action.payload;
    },
    setPlansData: (state, action) => {
      state.plansTableData = action.payload.data;
      state.plansTotalCount = action.payload.count;
    },
    setPlansTableCols: (state, action) => {
      state.plansTableCols = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setPlanDetails,
  setLoader,
  setPlansData,
  setPlansTableCols,
} = dashboardService.actions;
export const getPlanDetails = (planCode) => (dispatch) => {
  return axiosInstance({
    url: `${GET_PLAN_DETAILS}/` + planCode,
    method: "GET",
  });
};
//API to fetch Plans Table Data in Dashboard
export const fetchTableData = (body, page = 0, limit = 10) => (dispatch) => {
  return axiosInstance({
    url: `${GET_CREATED_PLANS}?page=${page + 1}&limit=${limit}`,
    method: "POST",
    data: body,
  });
};
export const deletePlans = (body) => (dispatch) => {
  return axiosInstance({
    url: `${DELETE_PLAN}`,
    method: "POST",
    data: body,
  });
};
//API to create a new Plan
export const createPlanAPI = (body) => (dispatch) => {
  return axiosInstance({
    url: CREATE_PLAN,
    method: "POST",
    data: body,
  });
};

//API to retrieve all the hierarchy levels required for filters
export const getPlanLevels = () => (dispatch) => {
  return axiosInstance({
    url: GET_PLAN_LEVELS,
    method: "GET",
  });
};
export default dashboardService.reducer;
