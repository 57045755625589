import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
} from "@material-ui/core";
import React, { useState } from "react";
import "./style.scss";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CustomCheckBox from "../customCheckbox";

const ConfirmPrompt = ({
  showModal = true,
  setConfirm = () => null,
  confirmCallback = () => null,
  message = "Are you sure you want to leave this page without saving changes ?",
  title = "Leave Page",
  isSaveAll = false,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const allowTransition = (e) => {
    e.stopPropagation();
    setConfirm(false);

    confirmCallback(true, isChecked);
  };

  const blockTransition = (e) => {
    e.stopPropagation();
    setConfirm(false);
    confirmCallback(false);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={showModal}
      onClose={blockTransition}
      aria-labelledby="routePromptTitle"
      id={"routePrompt"}
    >
      <DialogTitle id="routePromptTitle">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        {isSaveAll && (
          <CustomCheckBox
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
            key="userPreference"
            label="Do not show again"
            value="Do not show again"
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          id="routePromptNo"
          onClick={blockTransition}
          color="primary"
        >
          No
        </Button>
        <Button
          id="routePromptYes"
          onClick={allowTransition}
          color="primary"
          autoFocus
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmPrompt;
