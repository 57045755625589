import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../../../Utils/axios";
import {
  GET_CLUSTER_BREAKDOWN_DATA,
  GET_ATTRIBUTE_GRAPH_DATA,
  GET_PERFORMANCE_GRAPH_DATA,
} from "../../../constants-assortsmart/apiConstants";

export const finalizeClusterService = createSlice({
  name: "finalizeClusterService",
  initialState: {
    clusterBreakdownData: [],
    loader_1_2: false,
    attributeLoader: false,
    performanceLoader: false,
    attributeGraphData: {},
    performanceGraphData: {},
  },
  reducers: {
    setClusterBreakdownData: (state, action) => {
      state.l3OptData = action.payload;
    },
    set1_2_Loader: (state, action) => {
      state.loader_1_2 = action.payload;
    },
    setLoader: (state, action) => {
      state.attributeLoader = action.payload;
    },
    setPerformanceLoader: (state, action) => {
      state.performanceLoader = action.payload;
    },
    setAttributeGraphData: (state, action) => {
      state.attributeGraphData = action.payload?.data;
    },
    setPerformanceGraphData: (state, action) => {
      state.performanceGraphData = action.payload?.data;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoader,
  setClusterBreakdownData,
  set1_2_Loader,
  setAttributeGraphData,
  setPerformanceGraphData,
  setPerformanceLoader,
} = finalizeClusterService.actions;

export const getClusterBreakdownData = (postBody) => (dispatch) => {
  return axiosInstance({
    url: GET_CLUSTER_BREAKDOWN_DATA,
    method: "POST",
    data: postBody,
  });
};

export const getAttributeGraphData = (postBody) => (dispatch) => {
  return axiosInstance({
    url: GET_ATTRIBUTE_GRAPH_DATA,
    method: "POST",
    data: postBody,
  });
};

export const getPerformanceGraphData = (postBody) => (dispatch) => {
  return axiosInstance({
    url: GET_PERFORMANCE_GRAPH_DATA,
    method: "POST",
    data: postBody,
  });
};

export default finalizeClusterService.reducer;
