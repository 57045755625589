import React from "react";
import "./filters.css";
import { Grid } from "@material-ui/core";
import Button from "@mui/material/Button";
// import DatePicker from '@mui/lab/DatePicker';
import DatePicker from "../../../../commonComponents/dateRangePicker";
// import DateRangePicker from '../../../../commonComponents/RetailCalendarDatePicker';
import TimeLine from "./filter-components/timeline";
import Compared from "./filter-components/compared";
import Channel from "./filter-components/channels";
import CascadedSelectFilters from "./filter-components/cascadedFilters";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import {
  setChannels,
  getKpiList,
  getChannels,
  getLineComapredToYears,
  getFilters,
  setFilters,
  resetFilters,
  getStartEndTime,
  getDropdowns,
  setDropdowns,
  getKpiData,
  getTimelineDates,
  getComapredToYears,
  getKpiDataBarGraphRegion,
  getKpiDataBarGraphCategory,
  getHeatMapData,
  getCustomCompare,
  getMapData,
  getInvKpiValue,
  getTilesValue,
  setFilterChanged,
  setCallHeatMapApply,
} from "../../../../actions/mondaySmartActions";
import LoadingOverlay from "../../../../commonComponents/loadingOverlay";
import { invert } from "lodash";

class Filter extends React.Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   isApiLoading: true,
    // };
  }

  getChannelDetails = async () => {
    const channels = await this.props.getChannels("channel");
    this.props.setChannels(channels);
  };

  getChartDetails = async () => {
    // this.setState({
    //   isApiLoading: true,
    // });

    // const dataT = await this.props.getTimelineDates("lw");
    // const dataYtd = await this.props.getStartEndTime("ytd");

    let [dataT, dataYtd] = await Promise.all([
      this.props.getTimelineDates("lw"),
      this.props.getStartEndTime("ytd"),
    ]);

    const ytdStartDate = dataYtd.data.data.date_fw_details.start_date;
    const ytdEndDate = dataYtd.data.data.date_fw_details.end_date;
    const ytdStartDateFw = dataYtd.data.data.date_fw_details.start_fw;
    const ytdEndDateFw = dataYtd.data.data.date_fw_details.end_fw;

    let lastYear =
      Number(dataT.data.data.date_fw_details.end_date.split("-")[0]) - 1;
    let startFw = dataT.data.data.date_fw_details.start_fw;
    let endFw = dataT.data.data.date_fw_details.end_fw;
    const cD = await this.props.getComapredToYears(lastYear, startFw, endFw);
    const lastComparedYear = Number(ytdEndDate.split("-")[0]) - 1;
    const dataC = await this.props.getLineComapredToYears(
      lastComparedYear,
      null,
      null
    );
    const product_hierarchy = {};
    const store_hierarchy = {};

    const filterBody = {
      component: "linechart",
      filters: {
        timeline: {
          start_week_id: ytdStartDateFw,
          end_week_id: ytdEndDateFw,
          selected_startweek_id: this.props.startFwWeekTimeline,
          selected_endweek_id: this.props.endFwWeekTimeline,
          // "start_week_id": ytdStartDateFw,
          // "end_week_id": ytdEndDateFw
        },
        compare_timeline: {
          start_week_id: dataC.data.data.date_fw_details.start_fw,
          end_week_id: dataC.data.data.date_fw_details.end_fw,
        },
        channel: [...Object.keys(this.props.channel)],
        product_hierarchy,
        store_hierarchy,
      },
      aggregation_levels: {
        product_hierarchy: [],
        store_hierarchy: [],
        timeline: "week_id",
      },
      kpis: [...this.props.kpiList],
    };

    const invReqBody = {
      component: "invKpiValue",
      filters: {
        timeline: {
          start_week_id: ytdStartDateFw,
          end_week_id: ytdEndDateFw,
          selected_startweek_id: ytdStartDateFw,
          selected_endweek_id: ytdStartDateFw,
        },
        compare_timeline: {
          // start_week_id: dataC.data.data.date_fw_details.start_fw,
          // end_week_id: dataC.data.data.date_fw_details.end_fw,
          start_week_id: cD.data.data.date_fw_details.start_fw,
          end_week_id: cD.data.data.date_fw_details.end_fw,
        },
        channel: [...Object.keys(this.props.channel)],
        product_hierarchy,
        store_hierarchy,
      },
      aggregation_levels: {},
      flags: {
        is_map_view: false,
        is_eop: true,
      },
      kpis: ["inv_eop"],
    };

    let invKpiValue;

    if (this.props.realPage !== "inventory") {
      invKpiValue = await this.props.getInvKpiValue(invReqBody);
      this.props.dispatch({
        type: "SET_LINECHART_LOADER",
        value: true,
      });
      this.props.getKpiData(
        filterBody,
        this.props.kpiList,
        this.props.top_kpi_list_overview,
        this.props.allKpiLabelMapper,
        invKpiValue
      );
    }
    const filterBodyTiles = {
      filters: {
        timeline: {
          start_week_id: this.props.startFwWeekTimeline,
          end_week_id: this.props.endFwWeekTimeline,
          // "selected_startweek_id":ytdStartDateFw,
          // "selected_endweek_id":ytdStartDateFw
          // "start_week_id": ytdStartDateFw,
          // "end_week_id": ytdEndDateFw
        },
        compare_timeline: {
          // "start_week_id": dataC.data.data.date_fw_details.start_fw,
          // "end_week_id": dataC.data.data.date_fw_details.end_fw,
          start_week_id: cD.data.data.date_fw_details.start_fw,
          end_week_id: cD.data.data.date_fw_details.end_fw,
        },
        channel: [...Object.keys(this.props.channel)],
        product_hierarchy,
        store_hierarchy,
      },
      aggregation_levels: {},
      kpis: [...this.props.kpiList],
      component: "tilesValues",
    };

    const filterBody2 = {
      component: "heat-map",
      filters: {
        timeline: {
          start_week_id: this.props.startFwWeekTimeline,
          end_week_id: this.props.endFwWeekTimeline,
          // "selected_startweek_id":ytdStartDateFw,
          // "selected_endweek_id":ytdStartDateFw
          // "start_week_id": ytdStartDateFw,
          // "end_week_id": ytdEndDateFw
        },
        compare_timeline: {
          // "start_week_id": dataC.data.data.date_fw_details.start_fw,
          // "end_week_id": dataC.data.data.date_fw_details.end_fw,
          start_week_id: cD.data.data.date_fw_details.start_fw,
          end_week_id: cD.data.data.date_fw_details.end_fw,
        },
        channel: [...Object.keys(this.props.channel)],
        product_hierarchy,
        store_hierarchy,
      },
      aggregation_levels: {
        product_hierarchy: ["l0_name"],
        store_hierarchy: ["region"],
        timeline: "",
      },
      kpis: [...this.props.metrics_list_overview],
    };

    const filterReqBar = {
      filters: {
        timeline: {
          start_week_id: this.props.startFwWeekTimeline,
          end_week_id: this.props.endFwWeekTimeline,
        },
        compare_timeline: {
          start_week_id: this.props.startFwWeekCompared,
          end_week_id: this.props.endFwWeekCompared,
        },
        channel: [...Object.keys(this.props.channel)],
        product_hierarchy,
        store_hierarchy,
      },
    };

    const filterBodyRegion = {
      ...filterReqBar,
      aggregation_levels: {
        product_hierarchy: [],
        store_hierarchy: ["region"],
        timeline: "",
      },
      kpis: [...this.props.metrics_list_deepdive],
      component: "bar-chart-region",
    };

    const filterBodyCategory = {
      ...filterReqBar,
      aggregation_levels: {
        product_hierarchy: ["l0_name"],
        store_hierarchy: [],
        timeline: "",
      },
      kpis: [...this.props.metrics_list_deepdive],
      component: "bar-chart-category",
    };

    if (this.props.realPage === "overView") {
      await Promise.all([
        this.props.getTilesValue(
          filterBodyTiles,
          this.props.kpiList,
          this.props.top_kpi_list_overview,
          this.props.allKpiLabelMapper,
          invKpiValue
        ),
        this.props.getHeatMapData(
          filterBody2,
          "l0_name",
          this.props.allKpiLabelMapper
        ),
        this.props.getKpiDataBarGraphRegion(filterBodyRegion),
        this.props.getKpiDataBarGraphCategory(filterBodyCategory),
      ]);
    } else if (this.props.realPage === "deepDive") {
      await this.props.getTilesValue(
        filterBodyTiles,
        this.props.kpiList,
        this.props.top_kpi_list_overview,
        this.props.allKpiLabelMapper,
        invKpiValue
      );
    }

    // this.props.getTilesValue(
    //   filterBodyTiles,
    //   this.props.kpiList,
    //   this.props.top_kpi_list_overview,
    //   this.props.allKpiLabelMapper,
    //   invKpiValue
    // );
    this.props.dispatch({
      type: "SET_APPLIED_COMPARE_YEAR",
      appliedCompareYear: dataC.data.data.date_fw_details.start_date,
    });
    this.props.dispatch({
      type: "SET_APPLIED_TIMELINE_YEAR",
      appliedTimelineYear: ytdStartDate,
    });
    const currentDate = new Date(ytdStartDate);
    var currYear = currentDate.getUTCFullYear();
    var compareYear = currYear -1
    this.props.dispatch({
      type: "SET_APPLIED_YEAR",
      payload:{
        currYear,
        compareYear,
      }
    });

    //     if(this.props.page == 'overView'){
    //  this.props.getHeatMapData(
    //       filterBody2,
    //       "l0_name",
    //       this.props.allKpiLabelMapper
    //     );
    //  }

    const filterReq = {
      filters: {
        timeline: {
          start_week_id: this.props.startFwWeekTimeline,
          end_week_id: this.props.endFwWeekTimeline,
          // start_week_id: ytdStartDateFw,
          // end_week_id: ytdEndDateFw,
          // selected_startweek_id: ytdStartDateFw,
          // selected_endweek_id: ytdStartDateFw,
          // "start_week_id": ytdStartDateFw,
          // "end_week_id": ytdEndDateFw
        },
        compare_timeline: {
          // start_week_id: dataC.data.data.date_fw_details.start_fw,
          // end_week_id: dataC.data.data.date_fw_details.end_fw,
          start_week_id: cD.data.data.date_fw_details.start_fw,
          end_week_id: cD.data.data.date_fw_details.end_fw,
        },
        channel: [...Object.keys(this.props.channel)],
        product_hierarchy,
        store_hierarchy,
      },
    };
    if (this.props.page == "deepDive") {
      const deepDiveMapRequest = {
        component: "MapView",
        ...filterReq,
        aggregation_levels: {
          product_hierarchy: [],
          store_hierarchy: [
            "region",
            "state",
            "district",
            "city",
            "store_code",
          ],
          timeline: "",
        },
        kpis: [...this.props.metrics_list_deepdive],
        flags: {
          is_map_view: true,
          // "is_fc_mapping": false,
          // "is_dc_mapping": false,
        },
      };
      this.props.getMapData(deepDiveMapRequest);
    }

    if (this.props.realPage == "inventory") {
      const invMapRequest = {
        component: "inv-map-view",
        ...filterReq,
        aggregation_levels: {
          product_hierarchy: [],
          store_hierarchy: [
            "region",
            "state",
            "district",
            "city",
            "store_code",
          ],
          timeline: "",
        },
        kpis: [...this.props.inv_list_deepdive],
        flags: {
          is_map_view: true,
          is_eop: true,
          // "is_fc_mapping": true,
          // "is_dc_mapping": (this.props.mapView=='DC'?true:false) // true
        },
      };
      await this.props.getMapData(invMapRequest);
    }

    // this.setState({
    //   isApiLoading: false,
    // });
    this.props.setIsApiLoading(false);
  };

  getStartEndTimeDetails = async () => {};

  getProductDetails = async () => {};

  getStoreDetails = async () => {};

  handleReset = async () => {
    this.props.dispatch({
      type: "SET_ON_LOAD_FILTERS",
      payload: {
        showTimeLineButtons: false,
        timeLine: "lw",
        dateOrWeek: "week",
        endDate: moment(),
        startDate: moment().subtract(7, "d"),
        weekStartDate: null,
        weekEndDate: null,
        channel: {},
        comparedToYear: moment().format("YYYY"),
        showCompareButtons: false,
        comparedTo: "LY",
        selectedProductfilters: {},
        selectedStorefilters: {},
        selectedFilters: {},
        storeData:[],
        filterChanged:false,
      },
    });
    // this.setState({
    //   isApiLoading: true,
    // });
    this.props.setCallHeatMapApply(true)
    this.props.setIsApiLoading(true);

    this.props.dispatch({
      type: "SET_DROPDOWN_DOWN_FILTERS_EMPTY",
      payload: {},
    });

    await this.getChannelDetails();
    await this.getChartDetails();

    const product_filters = await getFilters("product");
    this.props.setFilters("product", product_filters);

    const store_filters = await getFilters("store");
    this.props.setFilters("store", store_filters);
    const request_body_products = [];
    const request_body_stores = [];
    const labelMapper = {};
    product_filters.data.data.forEach((thisProd) => {
      labelMapper[thisProd.column_name] = thisProd.label;
      request_body_products.push({
        attribute_name: thisProd.column_name,
        filter_type: "cascaded",
        filters: [],
      });
    });

    store_filters.data.data.forEach((thisStore) => {
      labelMapper[thisStore.column_name] = thisStore.label;
      if (thisStore.column_name == "store_code") {
        request_body_stores.push({
          attribute_name: thisStore.column_name,
          filter_type: "cascaded",
          filters: [],
          meta: {
            range: [],
            sort: [],
            search: [],
            limit: {
              limit: null,
              page: null,
            },
          },
          headers: ["string"],
        });
      } else {
        request_body_stores.push({
          attribute_name: thisStore.column_name,
          filter_type: "cascaded",
          filters: [],
        });
      }
    });
    this.props.dispatch({
      type: "SET_LABEL_MAPPER",
      mapper: labelMapper,
    });

    request_body_products.forEach(async (thisBody) => {
      const response = await this.props.getDropdowns("product", thisBody);
      const dropDownList = this.getFormattedList(response);
      this.props.setDropdowns(dropDownList, thisBody.attribute_name);
    });

    request_body_stores.forEach(async (thisBody) => {
      const response = await this.props.getDropdowns("store", thisBody);
      const dropDownList = this.getFormattedList(response);
      this.props.setDropdowns(dropDownList, thisBody.attribute_name);
    });
    //this.handleApply()

    // this.setState({
    //   ...this.state,
    //   isApiLoading: false,
    // });
  };

  handleApply = async () => {
    // this.setState({
    //   isApiLoading: true,
    // });
    this.props.setCallHeatMapApply(true)
    this.props.setFilterChanged(false)
    this.props.setIsApiLoading(true);
    // this.props.dispatch({
    //   type:'RESET_DEEPDIVE',
    //   clicked:false
    // })
    const product_hierarchy = {};
    const store_hierarchy = {};
    //const dataYtd= await this.props.getStartEndTime('ytd')

    if (this.props.page == "deepDive" || this.props.realPage == "inventory") {
      var isNavigating = false;
      for (let key in this.props.selectedProductfilters) {
        const value = this.props.selectedFilters[`selected${key}`];
        if (this.props.selectedProductfilters[key] && value != "") {
          product_hierarchy[key] = [value];
        }
      }
      for (let key in this.props.selectedStorefilters) {
        const value = this.props.selectedFilters[`selected${key}`];
        if (this.props.selectedStorefilters[key] && value != "") {
          store_hierarchy[key] = [value];
        }
      }
      if (this.props.page == "deepDive") {
        isNavigating =
          this.props.history.location.state &&
          this.props.history.location.state.key &&
          this.props.history.location.state.key.X &&
          this.props.history.location.state.key.Y;
        if (isNavigating) {
          if (this.props.history.location.state.key.level == "Divisions") {
            product_hierarchy["l0_name"] = [
              this.props.history.location.state.key.X,
            ];
            store_hierarchy["region"] = [
              this.props.history.location.state.key.Y,
            ];
          }
          if (this.props.history.location.state.key.level == "Departments") {
            product_hierarchy["l0_name"] = [this.props.selectedHeatmapDivision];
            product_hierarchy["l1_name"] = [
              this.props.history.location.state.key.X,
            ];
            product_hierarchy["l2_name"] = undefined
            product_hierarchy["l3_name"] = undefined
            store_hierarchy["region"] = [
              this.props.history.location.state.key.Y,
            ];
            store_hierarchy["state"] = undefined
            store_hierarchy["district"] = undefined
            store_hierarchy["city"] = undefined
            store_hierarchy["store_code"] = undefined

            const reqBody = {
              attribute_name: "l1_name",
              filter_type: "cascaded",
              filters: [
                {
                  attribute_name: "l0_name",
                  operator: "in",
                  values: [this.props.selectedHeatmapDivision],
                  filter_type: "cascaded",
                },
              ],
            };
            const response = await this.props.getDropdowns("product", reqBody);
            const dropDownList = this.getFormattedList(response);
            this.props.setDropdowns(dropDownList, "l1_name");
          }
        }
      }
    }


    var t_start_week_id = this.props.ytdStartDateFw;
    var t_end_week_id = this.props.ytdEndDateFw;
    var t_selected_startweek_id = this.props.startFwWeekTimeline;
    var t_selected_endweek_id = this.props.endFwWeekTimeline;
    var c_start_week_id = this.props.startFwWeekLineCompared;
    var c_end_week_id = this.props.endFwWeekLineCompared;
    var c_start_date = this.props.startDateCompared;
    // var lastYear = Number(this.props.endDateTimeline.split("-")[0])
    var currYear = Number(t_start_week_id.toString().substr(0,4)) 
    var compareYear = Number(this.props.startFwWeekCompared.toString().substr(0,4)) 
    if (this.props.timeLine == "Custom") {
      // const sDate = moment(this.props.weekStartDate).format("YYYY-MM-DD")
      // const eDate = moment(this.props.weekEndDate).format("YYYY-MM-DD")
      // const data = await this.props.getTimelineDatesCustom(sDate,eDate)
      // this.props.dispatch({
      //   type:'SET_APPLIED_TIMELINE_YEAR',
      //   appliedTimelineYear:data.data.data.date_fw_details.start_date
      // })
      const customComp = await this.props.getCustomCompare(
        t_selected_startweek_id,
        t_selected_endweek_id
      );
      const cy = customComp.data.data.cy_ly_lly_year_date_fw.cy;
      const lly = customComp.data.data.cy_ly_lly_year_date_fw.lly;
      const ly = customComp.data.data.cy_ly_lly_year_date_fw.ly;
      t_start_week_id = cy.start_week_id;
      t_end_week_id = cy.end_week_id;
      var t_startDate = cy.start_date;
      currYear = Number(t_start_week_id.toString().substr(0,4)) 
      if (this.props.comparedTo === "LY") {
        c_start_week_id = ly.start_week_id;
        c_end_week_id = ly.end_week_id;
        c_start_date = ly.start_date;
      } else if (this.props.comparedTo === "LLY") {
        c_start_week_id = lly.start_week_id;
        c_end_week_id = lly.end_week_id;
        c_start_date = lly.start_date;
      }
      compareYear = Number(c_start_week_id.toString().substr(0,4)) 
    }
    if (this.props.realPage == "inventory") {
      this.props.dispatch({
        type: "SET_HANDLE_APPLY_INVENTORY",
        clicked: true,
      });
    }

    // if (this.props.page == "deepDive") {
    //   this.props.dispatch({
    //     type: "SET_HANDLE_APPLY_DEEPDIVE",
    //     clicked: true,
    //   });
    // }

    const filterBody = {
      component: "linechart",
      filters: {
        timeline: {
          start_week_id: t_start_week_id, // Need to get this from new api ---> cy timeline
          end_week_id: t_end_week_id,
          selected_startweek_id: t_selected_startweek_id, // We have this
          selected_endweek_id: t_selected_endweek_id,
          // "start_week_id": dataYtd.data.data.date_fw_details.start_fw,
          // "end_week_id": dataYtd.data.data.date_fw_details.end_fw
        },
        compare_timeline: {
          start_week_id: c_start_week_id,
          end_week_id: c_end_week_id,
          // "start_week_id": this.props.startFwWeekLineCompared,
          // "end_week_id": this.props.endFwWeekLineCompared
        },
        channel: [...Object.keys(this.props.channel)],
        product_hierarchy,
        store_hierarchy,
      },
      aggregation_levels: {
        product_hierarchy: [],
        store_hierarchy: [],
        timeline: "week_id",
      },
      kpis: [...this.props.kpiList],
    };

    const filterBodyTiles = {
      filters: {
        timeline: {
          // "start_week_id": this.props.ytdStartDateFw,
          // "end_week_id": this.props.ytdEndDateFw,
          // "selected_startweek_id":this.props.ytdStartDateFw,
          // "selected_endweek_id":this.props.ytdStartDateFw
          // "start_week_id": ytdStartDateFw,
          // "end_week_id": ytdEndDateFw
          start_week_id: this.props.startFwWeekTimeline,
          end_week_id: this.props.endFwWeekTimeline,
        },
        compare_timeline: {
          start_week_id: this.props.startFwWeekCompared,
          end_week_id: this.props.endFwWeekCompared,
          // "start_week_id": this.props.startFwWeekLineCompared,
          // "end_week_id": this.props.endFwWeekLineCompared
        },
        channel: [...Object.keys(this.props.channel)],
        product_hierarchy,
        store_hierarchy,
      },
      aggregation_levels: {},
      kpis: [...this.props.kpiList],
      component: "tilesValues",
    };
    // Call api to get the data
    const invReqBody = {
      component: "invKpiValue",
      filters: {
        timeline: {
          start_week_id: this.props.startFwWeekTimeline,
          end_week_id: this.props.endFwWeekTimeline,
          // start_week_id: t_start_week_id, // Need to get this from new api ---> cy timeline
          // end_week_id: t_end_week_id,
          // selected_startweek_id: t_selected_startweek_id, // We have this
          // selected_endweek_id: t_selected_endweek_id,
          // "start_week_id": dataYtd.data.data.date_fw_details.start_fw,
          // "end_week_id": dataYtd.data.data.date_fw_details.end_fw
        },
        compare_timeline: {
          // start_week_id: c_start_week_id,
          // end_week_id: c_end_week_id,
          start_week_id: this.props.startFwWeekCompared,
          end_week_id: this.props.endFwWeekCompared,
        },
        channel: [...Object.keys(this.props.channel)],
        product_hierarchy,
        store_hierarchy,
      },
      aggregation_levels: {},
      flags: {
        is_map_view: false,
        is_eop: true,
      },
      kpis: ["inv_eop"],
    };

    if (this.props.realPage !== "inventory") {
      const invKpiValue = await this.props.getInvKpiValue(invReqBody);
      this.props.dispatch({
        type: "SET_LINECHART_LOADER",
        value: true,
      });
      this.props.getTilesValue(
        filterBodyTiles,
        this.props.kpiList,
        this.props.top_kpi_list_overview,
        this.props.allKpiLabelMapper,
        invKpiValue
      );

      this.props.getKpiData(
        filterBody,
        this.props.kpiList,
        this.props.top_kpi_list_overview,
        this.props.allKpiLabelMapper,
        invKpiValue
      );
    }
    this.props.dispatch({
      type: "SET_APPLIED_COMPARE_YEAR",
      appliedCompareYear: c_start_date,
    });
    this.props.dispatch({
      type: "SET_APPLIED_TIMELINE_YEAR",
      appliedTimelineYear: t_startDate,
    });
    this.props.dispatch({
    type: "SET_APPLIED_YEAR",
     payload:{
      currYear,
      compareYear,
     }
    });
    const filterBody2 = {
      filters: {
        timeline: {
          // "start_week_id": this.props.ytdStartDateFw,
          // "end_week_id": this.props.ytdEndDateFw,
          // "selected_startweek_id":this.props.ytdStartDateFw,
          // "selected_endweek_id":this.props.ytdStartDateFw
          // "start_week_id": ytdStartDateFw,
          // "end_week_id": ytdEndDateFw
          start_week_id: this.props.startFwWeekTimeline,
          end_week_id: this.props.endFwWeekTimeline,
        },
        compare_timeline: {
          start_week_id: this.props.startFwWeekCompared,
          end_week_id: this.props.endFwWeekCompared,
          // "start_week_id": this.props.startFwWeekLineCompared,
          // "end_week_id": this.props.endFwWeekLineCompared
        },
        channel: [...Object.keys(this.props.channel)],
        product_hierarchy,
        store_hierarchy,
      },
      aggregation_levels: {
        product_hierarchy: ["l0_name"],
        store_hierarchy: ["region"],
        timeline: "",
      },
      kpis: [...this.props.metrics_list_overview],
      component: "heat-map",
    };

    // let selected_startweek_id = null
    // let selected_endweek_id = null
    // if(this.props.timeLine== 'LW'){
    // }

    const labelsData = this.props.labels;
    let labelsKeys = Object.keys(labelsData[1].metric);

    const filterReq = {
      filters: {
        timeline: {
          start_week_id: this.props.startFwWeekTimeline,
          end_week_id: this.props.endFwWeekTimeline,
        },
        compare_timeline: {
          start_week_id: this.props.startFwWeekCompared,
          end_week_id: this.props.endFwWeekCompared,
        },
        channel: [...Object.keys(this.props.channel)],
        product_hierarchy,
        store_hierarchy,
      },
    };

    const filterBodyRegion = {
      ...filterReq,
      aggregation_levels: {
        product_hierarchy: [],
        store_hierarchy: ["region"],
        timeline: "",
      },
      kpis: labelsKeys,
      component: "bar-chart-region",
    };

    const filterBodyCategory = {
      ...filterReq,
      aggregation_levels: {
        product_hierarchy: ["l0_name"],
        store_hierarchy: [],
        timeline: "",
      },
      kpis: labelsKeys,
      component: "bar-chart-category",
    };
    if (this.props.realPage == "overView") {
      this.props.getHeatMapData(
        filterBody2,
        "l0_name",
        this.props.allKpiLabelMapper
      );
      this.props.getKpiDataBarGraphRegion(filterBodyRegion);
      this.props.getKpiDataBarGraphCategory(filterBodyCategory);
    }

    if (this.props.page == "deepDive") {
      const deepDiveMapRequest = {
        component: "MapView",
        ...filterReq,
        aggregation_levels: {
          product_hierarchy: [],
          store_hierarchy: [
            "region",
            "state",
            "district",
            "city",
            "store_code",
          ],
          timeline: "",
        },
        kpis: [...this.props.metrics_list_deepdive],
        flags: {
          is_map_view: true,
          // "is_fc_mapping": false,
          // "is_dc_mapping": false,
        },
      };
      this.props.getMapData(deepDiveMapRequest);
    }

    if (this.props.realPage == "inventory") {
      const invMapRequest = {
        component: "inv-map-view",
        ...filterReq,
        aggregation_levels: {
          product_hierarchy: [],
          store_hierarchy: [
            "region",
            "state",
            "district",
            "city",
            "store_code",
          ],
          timeline: "",
        },
        kpis: [...this.props.inv_list_deepdive],
        flags: {
          is_map_view: true,
          is_eop: true,
          // "is_fc_mapping": true,
          // "is_dc_mapping": (this.props.mapView=='DC'?true:false) // true
        },
      };
       await this.props.getMapData(invMapRequest);
    }
    this.props.history.push({
      state: {
        key: null,
      },
    });

    // this.setState({
    //   isApiLoading: false,
    // });
    this.props.setIsApiLoading(false);
  };

  getButtons = (isDisabled) => {
    return (
      <Grid style={{ display: "flex", margin: "auto" }} xs={1} md={1} lg={1}>
        <div style={{ flexBasis: "40%" }}>
          <Button
            onClick={() => {
              this.handleApply();
              this.props.dispatch({
                type: "SET_HANDLE_APPLY_DEEPDIVE",
                clicked: true,
              });
            }}
            disabled={!this.props.filterChanged || isDisabled }
            variant="contained"
            sx={{
              marginLeft: "-30px",
              marginRight: "10px",
              backgroundColor: "#3D67DB",
            }}
          >
            Apply
          </Button>
        </div>
        <div style={{ flexBasis: "40%" }}>
          <Button
            onClick={() => {
              this.handleReset();
              this.props.dispatch({
                type: "RESET_DEEPDIVE",
                clicked: true,
              });
              this.props.dispatch({
                type: "RESET_INVENTORY",
                clicked: true,
              });
            }}
            variant="outlined"
          >
            Reset
          </Button>
        </div>
      </Grid>
    );
  };
  getFormattedList(obj) {
    const regionObject = obj.data.data.attribute;
    const list = [];
    regionObject.forEach((thisRegion) => {
      list.push(thisRegion["attribute"]);
    });
    return list;
  }

  async componentDidMount() {
    if (
      this.props.history.location.state &&
      this.props.history.location.state.key &&
      this.props.history.location.state.key.X &&
      this.props.history.location.state.key.Y
    ) {
      // When navigating from heatmap
      await this.handleApply();
    } 
    else if(this.props.startFwWeekCompared !== null ){
      // When moving through side bar after first load
      this.props.dispatch({
        type: "SET_ON_LOAD_FILTERS",
        payload: {
          storeData:[],
          filterChanged:false,
        },
      });
      await this.handleApply();
    }
    else {
      // For first load and for explicit reload
      await this.handleReset();
    }
    //this.handleApply()

    // this.setState({
    //   ...this.state,
    //   isApiLoading: false,
    // });
  }

  render() {
    const channels = Object.keys(this.props.channel);
    var isChannels = false;

    channels.forEach((thisC) => {
      if (this.props.channel[thisC].toString() == "true") {
        isChannels = true;
      }
    });

    var isWeekDate = true;
    if (this.props.timeLine == "Custom") {
      if (this.props.dateOrWeek == "week") {
        if (!(this.props.weekStartDate && this.props.weekEndDate)) {
          isWeekDate = false;
        }
      }
    }

    // const isDate = true
    // if(this.props.custom =='date'){
    //   if(this.props.weekStartDate==null && this.props.weekEndDate == null){
    //     isWeekDate = false
    //   }
    // }

    const isDisabled = !(isChannels && isWeekDate);
    return (
      <div
        style={{
          border: "0.5px solid lightgray",
          backgroundColor: "white",
          marginBottom: "10px",
          padding: "10px",
          borderRadius: "5px",
        }}
      >
        <LoadingOverlay active={this.props.isApiLoading} />
        <Grid container>
          <TimeLine page={this.props.page} />
          <Grid className="top-left-container" xs={5} md={5} lg={5}>
            <Compared page={this.props.page} />
          </Grid>

          <Channel />
          {!this.props.showSelectFilters && this.getButtons(isDisabled)}
          {this.props.showSelectFilters && (
            <Grid container xs={12} md={12} lg={12}>
              {
                <React.Fragment>
                  <CascadedSelectFilters
                    heading={"Merchandizing Filter"}
                    dropDowns={this.props.product_filters}
                    isApiLoading={this.props.isApiLoading}
                  />
                  <CascadedSelectFilters
                    heading={"Geographic Filter"}
                    dropDowns={this.props.store_filters}
                    isApiLoading={this.props.isApiLoading}
                  />
                </React.Fragment>
              }
              {this.getButtons(isDisabled)}
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

Filter.defaultProps = {
  showSelectFilters: false,
};

const mapStateToProps = (store) => {
  const { mondaySmartReducer } = store;
  return {
    endFwWeekCompared: mondaySmartReducer.endFwWeekCompared,
    startFwWeekCompared: mondaySmartReducer.startFwWeekCompared,
    startFwWeekTimeline: mondaySmartReducer.startFwWeekTimeline,
    endFwWeekTimeline: mondaySmartReducer.endFwWeekTimeline,
    selectedFilters: mondaySmartReducer.selectedFilters,
    selectedProductfilters: mondaySmartReducer.selectedProductfilters,
    selectedStorefilters: mondaySmartReducer.selectedStorefilters,
    channel: mondaySmartReducer.channel,
    kpiList: mondaySmartReducer.kpiList,
    kpiData: mondaySmartReducer.kpiData,
    weekEndDate: store.mondaySmartReducer.weekEndDate,
    weekStartDate: store.mondaySmartReducer.weekStartDate,
    endDateTimeline: store.mondaySmartReducer.endDateTimeline,
    endDateCompared: store.mondaySmartReducer.endDateCompared,
    ytdStartDateFw: store.mondaySmartReducer.ytdStartDateFw,
    ytdEndDateFw: store.mondaySmartReducer.ytdEndDateFw,
    ytdStartDate: store.mondaySmartReducer.ytdStartDate,
    ytdEndDate: store.mondaySmartReducer.ytdEndDate,

    startDateLineCompared: store.mondaySmartReducer.startDateLineCompared,
    endDateLineCompared: store.mondaySmartReducer.endDateLineCompared,
    startFwWeekLineCompared: store.mondaySmartReducer.startFwWeekLineCompared,
    endFwWeekLineCompared: store.mondaySmartReducer.endFwWeekLineCompared,
    labels: mondaySmartReducer.labels.overViewLabels,

    metricsOverview: store.mondaySmartReducer.metricsOverview,
    metricsDeepdive: store.mondaySmartReducer.metricsDeepdive,
    topKpiOverview: store.mondaySmartReducer.topKpiOverview,
    topKpiDeepdive: store.mondaySmartReducer.topKpiDeepdive,
    top_kpi_list_overview: store.mondaySmartReducer.top_kpi_list_overview,
    top_kpi_list_deepdive: store.mondaySmartReducer.top_kpi_list_deepdive,
    metrics_list_overview: store.mondaySmartReducer.metrics_list_overview,
    metrics_list_deepdive: store.mondaySmartReducer.metrics_list_deepdive,
    allKpiLabelMapper: store.mondaySmartReducer.allKpiLabelMapper,
    comparedTo: store.mondaySmartReducer.comparedTo,
    timeLine: store.mondaySmartReducer.timeLine,
    comparedToYear: store.mondaySmartReducer.comparedToYear,
    startDateCompared: store.mondaySmartReducer.startDateCompared,
    dateOrWeek: store.mondaySmartReducer.dateOrWeek,
    inv_list_deepdive: mondaySmartReducer.inv_list_deepdive,
    mapView: mondaySmartReducer.mapView,
    startDateTimeline: mondaySmartReducer.startDateTimeline,
    selectedHeatmapDivision: mondaySmartReducer.selectedHeatmapDivision,
    filterChanged: store.mondaySmartReducer.filterChanged,
  };
};
const mapDispatchToProps = (dispatch) => ({
  dispatch,
  setFilterChanged: (value) => dispatch(setFilterChanged(value)),
  setCallHeatMapApply: (value) => dispatch(setCallHeatMapApply(value)),
  getChannels: (key) => dispatch(getChannels(key)),
  setChannels: (channels) => dispatch(setChannels(channels)),
  getFilters: (type) => dispatch(getFilters(type)),
  setFilters: (type, filters) => dispatch(setFilters(type, filters)),
  resetFilters: (type, filters) => dispatch(resetFilters(type, filters)),
  getDropdowns: (type, payload) => dispatch(getDropdowns(type, payload)),
  setDropdowns: (response, item) => dispatch(setDropdowns(response, item)),
  getKpiData: (filterBody, allKpi, top_kpi, allKpiLabelMapper, invKpiValue) =>
    dispatch(
      getKpiData(filterBody, allKpi, top_kpi, allKpiLabelMapper, invKpiValue)
    ),
  getTilesValue: (
    filterBody,
    allKpi,
    top_kpi,
    allKpiLabelMapper,
    invKpiValue
  ) =>
    dispatch(
      getTilesValue(filterBody, allKpi, top_kpi, allKpiLabelMapper, invKpiValue)
    ),
  getHeatMapData: (filterBody, level, allKpiLabelMapper) =>
    dispatch(getHeatMapData(filterBody, level, allKpiLabelMapper)),
  getTimelineDates: (data) => dispatch(getTimelineDates(data)),
  getStartEndTime: (data) => dispatch(getStartEndTime(data)),
  getKpiList: (kpis) => dispatch(getKpiList(kpis)),
  getComapredToYears: (year, startfw, endfw) =>
    dispatch(getComapredToYears(year, startfw, endfw)),
  getLineComapredToYears: (year, startfw, endfw) =>
    dispatch(getLineComapredToYears(year, startfw, endfw)),
  getKpiDataBarGraphRegion: (filter) =>
    dispatch(getKpiDataBarGraphRegion(filter)),
  getKpiDataBarGraphCategory: (filter) =>
    dispatch(getKpiDataBarGraphCategory(filter)),
  getCustomCompare: (start_fw, end_fw) =>
    dispatch(getCustomCompare(start_fw, end_fw)),
  getMapData: (filterBody) => dispatch(getMapData(filterBody)),
  getInvKpiValue: (reqBody) => dispatch(getInvKpiValue(reqBody)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Filter));
