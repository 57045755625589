import React, { Component, Suspense, lazy } from "react";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import posthog from "posthog-js";
import { POSTHOG_API } from "./config/api";
import { POSTHOG_KEY } from "./config/constants";
import Login from "./commonComponents/auth";
import MobileLogin from "./commonComponents/mobileRoute";
import store from "./store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-dates/initialize";
import "font-awesome/css/font-awesome.min.css";
//Authentication Testing - Firebase Connectivity
import "firebaseui/dist/firebaseui.css";
import Home from "./commonComponents/home";  
import NotFound from "./commonComponents/notFound/NotFound";
import ConfirmPrompt from "./commonComponents/confirmPrompt";
import EmailLogin from "./commonComponents/auth/emaillogin";
import MetaDataComponent from "./hoc/metadata";
import ProtectedRoute from "./commonComponents/layout/ProtectedRoute";
import Snackbar from "./Utils/snackbar/components/snackbar";
import SnackbarProvider from "./Utils/snackbar";
import Skeleton from "@material-ui/lab/Skeleton";
import Filter from './modules/mondaysmart/pages-mondaysmart/Overview/monday-smart-filter'
import moment from "moment";
import { calendarConfig } from './config';

import {LicenseManager} from "@ag-grid-enterprise/core";
LicenseManager.setLicenseKey("CompanyName=Impact Analytics,LicensedGroup=31Jan22 Purchase,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-025014,ExpiryDate=31_January_2023_[v2]_MTY3NTEyMzIwMDAwMA==e4f58ef1fe10261cf66aa1e5a5cb2da6");

const CoreLayout = lazy(() => import("./commonComponents/core-layout"));

const PlanSmartRoute = lazy(() =>
  import("./modules/plansmart/routes-plansmart")
);
const AssortSmartLayout = lazy(() =>
  import("./modules/assortsmart/routes-assortsmart/routes")
);
const MondaySmartLayout = lazy(() =>
  import("./modules/mondaysmart/routes-mondaysmart")
);

const StoreSmartLayout = lazy(() =>
  import("./modules/storesmart/routes-storesmart")
);



const strtDtofYr = [3, -28, -56, -87, -117];
class App extends Component {
  constructor() {
    super();
    const { fstDayOfWk, fstMoOfYr } = calendarConfig;
    moment.updateLocale('en', {
      week: {
        dow: fstDayOfWk,
        doy: strtDtofYr[fstMoOfYr] + fstDayOfWk,
      },
    });

    posthog.init(POSTHOG_KEY, { api_host: POSTHOG_API });
    this.state = {
      app: "",
      confirm: false,
      confirmCallback: null,
    };
  }
  selectApp = (app) => {
    this.setState({ app });
  };

  // setConfirm, getConfirmation and ConfirmPrompt are used to show confirm prompt
  setConfirm = () => {
    this.setState({ confirm: false });
  };
  getConfirmation = (_, confirmCallback) => {
    this.setState({ confirm: true, confirmCallback });
  };
 

  render() {
    return (
      <>
        <Provider store={store}>
          <SnackbarProvider>
            <Router getUserConfirmation={this.getConfirmation}>
              {this.state.confirm && (
                <ConfirmPrompt
                  confirmCallback={this.state.confirmCallback}
                  setConfirm={this.setConfirm}
                />
              )}
              <Snackbar />
              <MetaDataComponent>
                <div className="App">
                  <Suspense
                    fallback={
                      <div>
                        {" "}
                        <Skeleton> </Skeleton>
                      </div>
                    }
                  >
                    <Switch>
                      <Route exact path="/login" component={Login} />
                      <Route exact path="/mobile" component={MobileLogin} />
                      <Route exact path="/emaillogin" component={EmailLogin} />
                      <ProtectedRoute exact path={`/home`} component={Home} />
                      <ProtectedRoute
                        path={`/plan-smart/`}
                        component={PlanSmartRoute}
                      />
                      <ProtectedRoute
                        path="/assort-smart"
                        component={AssortSmartLayout}
                      />
                      <ProtectedRoute
                        path={`/monday-smart/`}
                        component={MondaySmartLayout}
                      />
                      <ProtectedRoute
                        path={`/store-smart/`}
                        component={StoreSmartLayout}
                      />
                      <Redirect exact from="/" to="/login" />
                      <ProtectedRoute component={CoreLayout} />
                      <ProtectedRoute exact path="/*" component={NotFound} />
                    </Switch>

                    <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
                  </Suspense>
                </div>
              </MetaDataComponent>
            </Router>
          </SnackbarProvider>
        </Provider>
      </>
    );
  }
}

export default App;
