const initialState = {
    fiscalWeek: {},
    userRole: "",
    storeName: "",
    simlarStore: [],
    currentTask: {},
    showInfo: false,
    infoImagePath: null,
    config: [],
    level: '',
    hierarchy: null,
    product_images: false,
    storeStatus: '',
    task_cluster_level: '',
    timeLine: {
    }
}

const storeSmartReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_FISCAL_WEEK':
            return {
                ...state,
                ...{ fiscalWeek: action.payload }
            }
        case 'SET_USER_ROLE':
            return {
                ...state,
                ...{ userRole: action.payload }
            }
        case 'SET_STORE_NAME':
            return {
                ...state,
                ...{ storeName: action.payload }
            }
        case 'SET_SIMILAR_STORE':
            return {
                ...state,
                ...{ simlarStore: action.payload }
            }
        case 'SET_TASK_DETAILS':
            return {
                ...state,
                ...{ currentTask: action.payload }
            }
        case 'SET_SHOW_INFO':
            return {
                ...state,
                showInfo: !state.showInfo
            }
        case 'SET_INFO_IMAGE_PATH':
            return {
                ...state,
                infoImagePath: action.payload.path
            }
        case 'SET_STORE_STATUS':
            return {
                ...state,
                storeStatus: action.payload
            }
        case 'SET_TIMELINE':
            return {
                ...state,
                timeLine: action.payload
            }
        case 'SET_CONFIG_DATA':
            let attVal = ''
            let hLevel = ''
            let product_images = false
            let task_cluster_level = ''

            action.payload.forEach((thisConfig) => {
                if (thisConfig.name === 'config') {
                    attVal = thisConfig.attribute_value.task_level[0]
                    product_images = thisConfig.attribute_value.product_images
                    task_cluster_level = thisConfig.attribute_value.task_cluster_level
                    if (Object.keys(thisConfig.attribute_value).includes('HIERARCHY_MAPPING')) {
                        let noOfLevels = Object.keys(thisConfig.attribute_value.HIERARCHY_MAPPING).length
                        Object.values(thisConfig.attribute_value.HIERARCHY_MAPPING).forEach((level, index) => {
                            hLevel = hLevel + level
                            if (index !== noOfLevels - 1) {
                                hLevel += '$'
                            }
                        })
                    }
                }
            })
            return {
                ...state,
                config: action.payload,
                level: attVal,
                hierarchy: hLevel,
                product_images,
                task_cluster_level,
            }
        default:
            return state;
    }
}


export default storeSmartReducer;