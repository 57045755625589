import React from "react";
import Loader from "react-loading-overlay";
import "./loader.css";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const LoadingOverlay = ({ loader, error, color, text, children }) => {
  return (
    <Loader
      active={loader}
      spinner
      text={text || ""}
      styles={{
        overlay: (base) => ({
          ...base,
          // background: 'rgba(255, 255, 255, 0.89)',
          background: 'rgba(0,0,0,0.1)',
          zIndex: 10000,
          borderRadius: '0px',
        }),
        spinner: (base) => ({
          ...base,
          width: "50px",
          "& svg circle": {
            stroke: color ? color : "#44677b",
          },
        }),
        content: (base) => ({
          ...base,
          color: color ? color : "#44677b",
        }),
      }}
    >
      {error ?
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {error}
        </Alert>
        : children}
    </Loader>
  );
};

export default LoadingOverlay;
