import axios from "axios";
import { config } from "../../config";
import { logoutUser } from "../../actions/authActions";
import store from "../../store";
import { firebaseobj } from "../../commonComponents/auth/firebase";
const axiosInstance = axios.create({ baseURL: config.baseUrl });

const requestHandler = async (request) => {
  const user = firebaseobj.auth().currentUser;
  let token = null;
  if (user) {
    try {
      token = await user.getIdToken();
    } catch (error) {
      return;
    }
  }
  request.headers.common = { Authorization: `${token}` };
  return request;
};

const errorHandler = async (error) => {
  if (error.response && error.response.status === 401) {
    store.dispatch(logoutUser());
  }
  return Promise.reject({ ...error });
};

const successHandler = (response) => {
  return response;
};
axiosInstance.interceptors.request.use((request) => requestHandler(request));
axiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

export default axiosInstance;
