import {
  SET_PRODUCT_GROUPS,
  SET_PRODUCT_GROUP_DEFINITIONS,
  SET_SELECTED_PRODUCT_GROUP_TYPE,
  SET_PRODUCT_GROUP_FILTERS,
  SET_PRODUCT_GROUP_FILTERED_PRODUCTS,
  SET_PRODUCT_GROUP_FILTERED_COLUMNS,
  SET_SELECTED_MANUAL_FILTER_TYPE,
  SET_SELECTED_MANUAL_GROUP_DEFNS,
  PRODUCT_GROUP_LOADER,
  SET_MAPPED_DEFINITIONS,
  SET_SELECTED_PRODUCTS,
  SET_DATA_CHANGE_STATUS,
  RESET_PRODUCT_GROUP_FILTERED_PRODUCTS,
  SET_GROUPS_TABLE_COLUMNS,
  SET_SELECTED_GROUP_PRODUCTS,
  SET_MANUAL_SELECTED_GROUPS,
  DELETED_PRODUCTS_IN_EDIT_GROUP,
  EXISTING_EDIT_SELECTED_PRODUCTS,
  DELETED_PRODUCTS_IN_DEFINITION,
  ADD_PRODUCTS_IN_EDIT_GROUP,
  SET_VIEW_GROUP_FILTER_VALUES,
  ADD_GROUPS_IN_EDIT_GROUP,
  DELETED_GROUPS_IN_EDIT_GROUP,
  VIEW_DEFNS_TABLE_COLS,
  DEFN_MAP_TABLE_COLS,
  SET_STYLE_LVL_TABLE_COLS,
  SET_STYLE_LVL_TABLE_DATA,
  SET_MANUAL_SELECTED_STYLES,
  DELETED_STYLES_IN_DEFINITION,
  SET_SELECTED_OBJECTIVE_METRIC,
  SET_SELECTED_OBJECTIVE_TIME_FORMAT,
  SET_REQUEST_TABLE_COLUMNS,
  SET_PROD_INITIAL_METRIC_FILTERS,
  SET_METRIC_START_DATE,
  SET_METRIC_END_DATE,
  RESET_PRODUCT_FILTERS_TABLE,
  SET_SELECTED_CLUSTER,
} from "../actions/types";
import _ from "lodash";
const initialState = {
  productGrpDefinitions: [],
  productGrpDefinitionsCount: 0,
  productGroups: [],
  productGroupsCount: 0,
  selectedGroupType: "manual",
  selectedManualFilterType: "product_hierarchy",
  manualGroupDfnFilters: [],
  productGroupFilters: [],
  manualFilteredProducts: [],
  manualFilteredProductsCount: 0,
  manualFilteredProdsCols: [],
  isLoading: false,
  mappedDefns: [],
  selectedProducts: [],
  isDataChanged: false,
  dataChangeType: "",
  groupsTableCols: [],
  selectedGroupProds: [],
  selectedGroupProdsCount: 0,
  manualselectedGroups: [],
  deletedProdsInEdit: [],
  addedProdsInEdit: [],
  exisitingProdsInEdit: [],
  deleteProdsInDefn: [],
  isDefnBasedDisabled: false,
  newProdsInEdit: [],
  viewGrpFilterValues: [],
  newGrpsInEdit: [],
  deletedGrpsInEdit: [],
  viewDefnTableCols: [],
  defnMapTableCols: [],
  styleLevelTableCols: [],
  styleLevelTableData: [],
  styleLevelTableCount: 0,
  selectedStyles: [],
  deletedDefnStyles: [],
  selectedObjectiveMetric: "",
  selectedObjectiveTimeFormat: "",
  requestTableCols: [],
  initialProdMetricFilters: [],
  metricStartDate: null,
  metricEndDate: null,
  resetTable: false,
  selectedCluster: {},
};

const switch_one = (state, action) => {
  switch (action.type) {
    case SET_VIEW_GROUP_FILTER_VALUES:
      return {
        ...state,
        viewGrpFilterValues: action.payload,
      };
    case SET_PRODUCT_GROUP_DEFINITIONS:
      let count = action.count
        ? action.count
        : state.productGrpDefinitionsCount;
      if (action.status === "deleted") {
        count = count - 1;
      }
      return {
        ...state,
        productGrpDefinitions: action.payload,
        productGrpDefinitionsCount: count,
      };
    case SET_PRODUCT_GROUPS:
      return {
        ...state,
        productGroups: [...action.payload],
        productGroupsCount: action.count,
      };
    case SET_SELECTED_PRODUCT_GROUP_TYPE:
      return {
        ...state,
        selectedGroupType: action.payload,
      };
    case SET_PRODUCT_GROUP_FILTERS:
      return {
        ...state,
        productGroupFilters: [...action.payload],
      };
    case SET_PRODUCT_GROUP_FILTERED_PRODUCTS:
      return {
        ...state,
        manualFilteredProducts: [...action.payload],
        manualFilteredProductsCount: action.count,
      };
    case SET_PRODUCT_GROUP_FILTERED_COLUMNS:
      return {
        ...state,
        manualFilteredProdsCols: [...action.payload],
      };
    case SET_SELECTED_MANUAL_FILTER_TYPE:
      return {
        ...state,
        selectedManualFilterType: action.payload,
        isDefnBasedDisabled: action.isDisabled
          ? action.isDisabled
          : state.isDefnBasedDisabled,
      };
    case SET_SELECTED_MANUAL_GROUP_DEFNS:
      return {
        ...state,
        manualGroupDfnFilters: action.payload,
      };
    case PRODUCT_GROUP_LOADER:
      return {
        ...state,
        isLoading: action.payload.status,
      };
    case SET_MAPPED_DEFINITIONS:
      return {
        ...state,
        mappedDefns: action.payload,
      };
    case SET_SELECTED_PRODUCTS:
      return {
        ...state,
        selectedProducts: _.uniqBy(action.payload, "product_code"),
      };
    case SET_MANUAL_SELECTED_GROUPS:
      return {
        ...state,
        manualselectedGroups: _.uniqBy(action.payload, "pg_code"),
      };
    case SET_DATA_CHANGE_STATUS:
      return {
        ...state,
        isDataChanged: action.status,
        dataChangeType: action.dataType,
      };
    case RESET_PRODUCT_GROUP_FILTERED_PRODUCTS:
      return {
        ...state,
        manualFilteredProducts: [],
        manualFilteredProductsCount: 0,
        selectedProducts: [],
        selectedManualFilterType:
          action.payload === true
            ? state.selectedManualFilterType
            : "product_hierarchy",
        manualGroupDfnFilters: [],
        deleteProdsInDefn: [],
        deletedProdsInEdit: [],
        exisitingProdsInEdit: [],
        newProdsInEdit: [],
        newGrpsInEdit: [],
        deletedGrpsInEdit: [],
        selectedStyles: [],
        deletedDefnStyles: [],
        selectedObjectiveMetric: "",
        selectedObjectiveTimeFormat: "",
        initialProdMetricFilters: [],
        metricStartDate: null,
        metricEndDate: null,
        selectedCluster: {},
        styleLevelTableData: [],
        styleLevelTableCount: 0,
      };
    case SET_GROUPS_TABLE_COLUMNS:
      return {
        ...state,
        groupsTableCols: [...action.payload],
      };
    case SET_SELECTED_GROUP_PRODUCTS:
      return {
        ...state,
        selectedGroupProds: action.payload,
        selectedGroupProdsCount: action.count,
      };
    case DELETED_PRODUCTS_IN_DEFINITION:
      return {
        ...state,
        deleteProdsInDefn: _.uniqBy(action.payload, "product_code"),
      };
  }
};
const switch_two = (state, action) => {
  switch (action.type) {
    case DELETED_STYLES_IN_DEFINITION:
      return {
        ...state,
        deletedDefnStyles: _.uniqBy(action.payload, "style"),
      };
    case DELETED_PRODUCTS_IN_EDIT_GROUP:
      return {
        ...state,
        deletedProdsInEdit: _.uniqBy(action.payload, "product_code"),
      };
    case EXISTING_EDIT_SELECTED_PRODUCTS:
      return {
        ...state,
        exisitingProdsInEdit: _.uniqBy(action.payload, "product_code"),
      };
    case ADD_PRODUCTS_IN_EDIT_GROUP:
      return {
        ...state,
        newProdsInEdit: _.uniqBy(action.payload, "product_code"),
      };
    case ADD_GROUPS_IN_EDIT_GROUP:
      return {
        ...state,
        newGrpsInEdit: _.uniqBy(action.payload, "pg_code"),
      };
    case DELETED_GROUPS_IN_EDIT_GROUP:
      return {
        ...state,
        deletedGrpsInEdit: _.uniqBy(action.payload, "pg_code"),
      };
    case VIEW_DEFNS_TABLE_COLS:
      return {
        ...state,
        viewDefnTableCols: action.payload,
      };
    case DEFN_MAP_TABLE_COLS:
      return {
        ...state,
        defnMapTableCols: action.payload,
      };

    case SET_STYLE_LVL_TABLE_COLS:
      return {
        ...state,
        styleLevelTableCols: action.payload,
      };
    case SET_STYLE_LVL_TABLE_DATA:
      return {
        ...state,
        styleLevelTableData: action.payload,
        styleLevelTableCount: action.count,
      };
    case SET_MANUAL_SELECTED_STYLES:
      return {
        ...state,
        selectedStyles: _.uniqBy(action.payload, "style"),
      };
    case SET_SELECTED_OBJECTIVE_TIME_FORMAT:
      return {
        ...state,
        selectedObjectiveTimeFormat: action.payload,
      };
    case SET_SELECTED_OBJECTIVE_METRIC:
      return {
        ...state,
        selectedObjectiveMetric: action.payload,
      };
    case SET_REQUEST_TABLE_COLUMNS:
      return {
        ...state,
        requestTableCols: action.payload,
      };
    case SET_PROD_INITIAL_METRIC_FILTERS:
      return {
        ...state,
        initialProdMetricFilters: action.payload,
      };
    case SET_METRIC_START_DATE:
      return {
        ...state,
        metricStartDate: action.payload,
      };
    case SET_METRIC_END_DATE:
      return {
        ...state,
        metricEndDate: action.payload,
      };
    case RESET_PRODUCT_FILTERS_TABLE:
      return {
        ...state,
        resetTable: action.payload,
      };
    case SET_SELECTED_CLUSTER:
      return {
        ...state,
        selectedCluster: action.payload,
      };
    default:
      return state;
  }
};
export default function (state = initialState, action) {
  return switch_one(state, action) || switch_two(state, action);
}
