import {
  SET_STORE_GROUPS,
  SET_STORE_GROUP_FILTERS,
  SET_STORE_GROUP_FILTERED_STORES,
  SET_STORE_GROUP_FILTERED_COLUMNS,
  SET_SELECTED_MANUAL_FILTER_TYPE,
  RESET_STORE_GROUP_FILTERED_STORES,
  STORE_GROUP_LOADER,
  ADD_STORES_IN_EDIT_GROUP,
  SET_MANUAL_SELECTED_STORE_GROUPS,
  SET_STORE_GROUPS_TABLE_COLUMNS,
  DELETE_STORE_GROUP,
  SET_MAPPED_DEFINITIONS,
  DELETED_STORES_IN_EDIT_GROUP,
  SET_SELECTED_STORES,
  DELETED_STORE_GROUPS_IN_EDIT_GROUP,
  ADD_STORE_GROUPS_IN_EDIT_GROUP,
  EXISTING_EDIT_SELECTED_STORES,
  SET_STORE_VIEW_GROUP_FILTER_VALUES,
} from "../actions/types";
import _ from "lodash";
const initialState = {
  storeGroups: [],
  storeGroupsCount: 0,
  selectedGroupType: "manual",
  selectedManualFilterType: "product_hierarchy",
  storeGroupFilters: [],
  manualFilteredStores: [],
  manualFilteredStoresCount: 0,
  manualFilteredStoresCols: [],
  manualselectedGroups: [],
  isLoading: false,
  groupsTableCols: [],
  selectedstores: [],
  selectedStoreGroupid: 0,
  deletedStoresInEdit: [],
  newGrpsInEdit: [],
  deletedGrpsInEdit: [],
  exisitingStoresInEdit: [],
  newStoresInEdit: [],
  viewGrpFilterValues: [],
};
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_STORE_VIEW_GROUP_FILTER_VALUES:
      return {
        ...state,
        viewGrpFilterValues: action.payload,
      };
    case SET_STORE_GROUPS:
      return {
        ...state,
        storeGroups: [...action.payload],
        storeGroupsCount: action.count,
      };
    case SET_STORE_GROUP_FILTERS:
      return {
        ...state,
        storeGroupFilters: [...action.payload],
      };
    case SET_STORE_GROUP_FILTERED_COLUMNS:
      return {
        ...state,
        manualFilteredStoresCols: [...action.payload],
      };
    case SET_SELECTED_MANUAL_FILTER_TYPE:
      return {
        ...state,
        selectedManualFilterType: action.payload,
      };
    case STORE_GROUP_LOADER:
      return {
        ...state,
        isLoading: action.payload.status,
      };
    case SET_STORE_GROUP_FILTERED_STORES:
      return {
        ...state,
        manualFilteredStores: [...action.payload],
        manualFilteredStoresCount: action.count,
      };
    case SET_STORE_GROUPS_TABLE_COLUMNS:
      return {
        ...state,
        groupsTableCols: [...action.payload],
      };
    case SET_SELECTED_STORES:
      return {
        ...state,
        selectedstores: _.uniqBy(action.payload, "store_code"),
      };
    case DELETE_STORE_GROUP:
      return {
        ...state,
        selectedStoreGroupid: action.sgid,
      };
    case EXISTING_EDIT_SELECTED_STORES:
      return {
        ...state,
        exisitingStoresInEdit: _.uniqBy(action.payload, "store_code"),
      };
    case SET_MANUAL_SELECTED_STORE_GROUPS:
      return {
        ...state,
        manualselectedGroups: _.uniqBy(action.payload, "sg_code"),
      };
    case ADD_STORE_GROUPS_IN_EDIT_GROUP:
      return {
        ...state,
        newGrpsInEdit: _.uniqBy(action.payload, "sg_code"),
      };
    case DELETED_STORE_GROUPS_IN_EDIT_GROUP:
      return {
        ...state,
        deletedGrpsInEdit: _.uniqBy(action.payload, "sg_code"),
      };
    case DELETED_STORES_IN_EDIT_GROUP:
      return {
        ...state,
        deletedStoresInEdit: _.uniqBy(action.payload, "store_code"),
      };
    case ADD_STORES_IN_EDIT_GROUP:
      return {
        ...state,
        newStoresInEdit: _.uniqBy(action.payload, "store_code"),
      };
    case RESET_STORE_GROUP_FILTERED_STORES:
      return {
        ...state,
        manualFilteredStores: [],
        manualFilteredStoresCount: 0,
        selectedstores: [],
        selectedGroupType: "manual",
        deletedStoresInEdit: [],
        exisitingStoresInEdit: [],
        newStoresInEdit: [],
        newGrpsInEdit: [],
        deletedGrpsInEdit: [],
      };
    default:
      return state;
  }
}
