import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  paperRoot: {
    position: "absolute",
    zIndex: 1,
  },
  success: {
    color: "green",
  },
  failure: {
    color: "red",
  },
}));
//default
//1. MinUpper - 1
//2. MinLower - 1
//3. MinSpl - 0
//4. MinDigits - 0
//5. MinLength - 6
//6. isDigitsAllowed - true
//7. isSplChrsAllowed - false
const Validator = React.forwardRef(({ checklist }, ref) => {
  //Pass a reference of Input tag to this component
  //Using that reference, we dynamically change the UI
  //List of checklist Items ->
  const classes = useStyles();
  return (
    <>
      {document.activeElement === ref.current && (
        <Paper style={{ position: "absolute", zIndex: 1 }} elevation={3}>
          <List>
            {checklist.map((check) => {
              return (
                <>
                  <ListItem
                    classes={{
                      root: check.status ? classes.success : classes.failure,
                    }}
                  >
                    <ListItemIcon>
                      {check.status ? (
                        <DoneIcon className={classes.success} />
                      ) : (
                        <CloseIcon className={classes.failure} />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={check.msg} />
                  </ListItem>
                </>
              );
            })}
          </List>
        </Paper>
      )}
    </>
  );
});

export default Validator;
