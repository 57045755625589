import { combineReducers } from "redux";
import planDashboardReducer from "./Plan-Dashboard/plan-dashboard-service";
import planInitialReducer from "./Plan/Plan-Initial/planInitialService";
import finalizeClusterReducer from "./Clustering/Finalize-Cluster/finalize-cluster-service";

export const assortsmartReducer = combineReducers({
  planDashboardReducer,
  planInitialReducer,
  finalizeClusterReducer,
});
